import React from "react";
import "./View.css";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useTelemetry } from "@amx/web-components";
import { LinearProgress } from "@material-ui/core";
import { AdminUserContextProvider } from "../../providers";
import { useIsFetching } from "react-query";

export const View = (props: any) => {
  const {
    children,
    className,
    isLoading,
    useAdminUserProvider,
    ...rest
  } = props;
  const combinedClassName =
    className !== undefined ? `View ${className}` : "View";
  const isFetching = useIsFetching();
  const appInsights = useAppInsightsContext();
  const { handleClickTracking } = useTelemetry(appInsights);

  const view = (
    <>
      {isLoading ? (
        <div
          className={combinedClassName}
          style={{ padding: "unset" }}
          {...rest}
        >
          <LinearProgress />
        </div>
      ) : (
        <div
          className={combinedClassName}
          {...rest}
          onClick={handleClickTracking}
        >
          <LinearProgress
            style={{ visibility: isFetching ? "visible" : "hidden" }}
          />
          {children}
        </div>
      )}
    </>
  );

  if (useAdminUserProvider) {
    return <AdminUserContextProvider>{view}</AdminUserContextProvider>;
  } else {
    return view;
  }
};
