import { LinearProgress } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { DashboardWidget } from "../../../../common/types";
import { useSharedDashboardData } from "../../../../providers";
import { WidgetWrapper } from "../WidgetWrapper";

type MyFundsProps = DashboardWidget & {};

export const MyFunds = (props: MyFundsProps) => {
  const {
    dashboardsDrilldownData,
    dashboardsDrilldownLoading,
  } = useSharedDashboardData();
  const [total, setTotal] = useState<number>(0);

  const columns = [
    {
      name: "fundName",
      label: "Fund Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "navType",
      label: "NAV Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "valuationDate",
      label: "Valuation Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "navPS",
      label: "NAV p/s",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fundNav",
      label: "Fund NAV",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any) =>
          value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
      },
    },
    {
      name: "fundCcy",
      label: "CCY",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "classNav",
      label: "Class NAV",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any) =>
          value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
      },
    },
    {
      name: "classCcy",
      label: "Class CCY",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "aumInFundCcy",
      label: "% of Total",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any) =>
          ((value / total) * 100).toFixed(0) + "%",
      },
    },
  ];

  const options: any = {
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    responsive: "vertical",
    selectToolbarPlacement: "none",
    elevation: 0,
    pagination: false,
    selectableRows: "none",
    rowsPerPage: 5,
  };

  useEffect(() => {
    if (dashboardsDrilldownData) {
      setTotal(
        dashboardsDrilldownData.reduce((prev: any, curr: any) => {
          return prev + curr.aumInFundCcy;
        }, 0)
      );
    }
  }, [dashboardsDrilldownData]);

  return (
    <WidgetWrapper data={props} title="My Funds">
      {dashboardsDrilldownLoading ? <LinearProgress /> : null}
      {dashboardsDrilldownData ? (
        <MUIDataTable
          data={dashboardsDrilldownData}
          columns={columns}
          options={options}
        />
      ) : null}
    </WidgetWrapper>
  );
};
