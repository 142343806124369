import React from "react";
import { LinearProgress } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DashboardWidget } from "../../../common/types";
import { useSharedDashboardData } from "../../../providers";
import { WidgetWrapper } from "./WidgetWrapper";

type TotalMarketValueProps = DashboardWidget & {};

const formatFundSum = (sum: number | undefined) => {
  return `USD ${parseFloat(sum?.toFixed(0) || "0").toLocaleString()}`;
};

export const TotalMarketValue = (props: TotalMarketValueProps) => {
  const {
    dashboardsDrilldownData,
    dashboardsDrilldownLoading,
  } = useSharedDashboardData();

  return (
    <WidgetWrapper data={props} title="Total Market Value">
      {dashboardsDrilldownLoading ? <LinearProgress /> : null}
      {dashboardsDrilldownData ? (
        <React.Fragment>
          <Typography variant="h3" gutterBottom className="underline-title">
            {formatFundSum(
              dashboardsDrilldownData.reduce(
                (prev: any, curr: any) => prev + curr.aumInFundCcy,
                0
              )
            )}{" "}
            *
          </Typography>
          <p>
            <small>* Latest available Net Asset Value</small>
          </p>
        </React.Fragment>
      ) : null}
    </WidgetWrapper>
  );
};
