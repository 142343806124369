import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { View } from "../../components";
import { Route, useLocation } from "react-router";
import { MyDocuments } from "./components/my-documents/MyDocuments";
import MyReports from "./components/my-reports/MyReports";
import { Link, Switch } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

export const Explorer = () => {
  const location = useLocation();
  const classes = useStyles();

  return (
    <View>
      <div className={classes.root}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={location.pathname}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              value={
                location.pathname === "/explorer"
                  ? "/explorer"
                  : "/explorer/reports"
              }
              label="reports"
              component={Link}
              to="/explorer/reports"
            />
            <Tab
              value="/explorer/documents"
              label="Documents"
              component={Link}
              to="/explorer/documents"
            />
          </Tabs>
        </AppBar>
      </div>
      <Switch>
        <Route
          path={
            location.pathname === "/explorer"
              ? "/explorer"
              : "/explorer/reports"
          }
          render={() => <MyReports />}
        />
        <Route path="/explorer/documents" render={() => <MyDocuments />} />
      </Switch>
    </View>
  );
};
