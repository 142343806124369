import {
  Backdrop,
  CircularProgress,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useDocuments } from "../Documents";
import { DocumentMetadataType } from "../../../../../common/types";
import { useAuth } from "../../../../../providers";

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {},
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const CustomMuiToolbarSelect = (props: any) => {
  const { axiosWithAuth } = useAuth();
  const { updateDocuments, selectedDocType, docs } = useDocuments();
  const { selectedRows, displayData } = props;
  const [hideDocStatus, setHideDocStatus] = useState("idle");
  const classes = useStyles();

  const handleDocVisible = async (hideOption: string) => {
    if (axiosWithAuth === undefined) return;

    try {
      setHideDocStatus("pending");

      const documentSks = selectedRows.data.map((row: any) => {
        return displayData[row.dataIndex].data[3];
      });

      const filteredDocs = docs
        .filter((doc: DocumentMetadataType) => {
          return documentSks.includes(doc.documentSk);
        })
        .map((doc: DocumentMetadataType) => ({
          ...doc,
          visibleFlag: hideOption,
        }));

      for (const doc of filteredDocs) {
        const { documentSk } = doc;
        await axiosWithAuth({
          url: "/api/documents/update",
          method: "POST",
          data: {
            documentSk,
            visibleFlag: hideOption,
            customDocName: doc.customDocumentName,
          },
        });
      }
      updateDocuments(filteredDocs, selectedDocType);
      setHideDocStatus("resolved");
    } catch (error) {
      setHideDocStatus("rejected");
    }
  };

  return (
    <>
      <div className={classes.iconContainer}>
        <Tooltip title="Display Selected">
          <IconButton
            className={classes.iconButton}
            onClick={() => handleDocVisible("T")}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Hide Selected">
          <IconButton
            className={classes.iconButton}
            onClick={() => handleDocVisible("F")}
          >
            <VisibilityOffIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <Backdrop className={classes.backdrop} open={hideDocStatus === "pending"}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CustomMuiToolbarSelect;
