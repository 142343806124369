import React from "react";
import {
  makeStyles,
  Typography,
  Avatar,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
} from "@material-ui/core";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useDocuments } from "../Documents";
import OverflowTip from "./OverflowTooltip";

export const DocumentsSidebar = ({ docTypes }: { docTypes: string[] }) => {
  const { selectedDocType, updateSelectedDocType } = useDocuments();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: "250px",
      position: "fixed",
      height: "100vh",
      backgroundColor: "transparant",
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root} data-tour="documents-sidebar">
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{ backgroundColor: "transparent" }}
      >
        <ListSubheader inset disableSticky>
          <Typography variant="h6" color="primary" gutterBottom>
            My Documents
          </Typography>
        </ListSubheader>
        <ListItem
          button
          selected={selectedDocType === "Recent Documents"}
          onClick={() => updateSelectedDocType("Recent Documents")}
        >
          <ListItemAvatar>
            <Avatar
              style={{ width: "25px", height: "25px", fontSize: "0.8rem" }}
            >
              <DateRangeIcon fontSize="small" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Recent Documents"
            primaryTypographyProps={{ variant: "subtitle1" }}
          />
        </ListItem>
        <ListItem
          button
          selected={selectedDocType === "All Documents"}
          onClick={() => updateSelectedDocType("All Documents")}
        >
          <ListItemAvatar>
            <Avatar
              style={{ width: "25px", height: "25px", fontSize: "0.8rem" }}
            >
              <SelectAllIcon fontSize="small" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="All Documents"
            primaryTypographyProps={{ variant: "subtitle1" }}
          />
        </ListItem>
      </List>
      <Divider variant="middle" />
      <List
        component="nav"
        aria-label="secondary mailbox folder"
        style={{ backgroundColor: "transparent" }}
      >
        {docTypes.map((docType: string, index: number) => {
          var initials: string | RegExpMatchArray | null =
            docType !== undefined ? docType.match(/\b\w/g) : [];
          initials = (
            (initials?.shift() || "") + (initials?.pop() || "")
          ).toUpperCase();

          return (
            <ListItem
              key={docType}
              button
              selected={selectedDocType === docType}
              onClick={() => updateSelectedDocType(docType)}
            >
              <ListItemAvatar>
                <Avatar
                  style={{
                    width: "25px",
                    height: "25px",
                    fontSize: "0.8rem",
                    backgroundColor: index % 2 ? "#C410A0" : "#2DAADC ",
                  }}
                >
                  {initials}
                </Avatar>
              </ListItemAvatar>
              <ListItemText disableTypography>
                <OverflowTip title={docType}>
                  <Typography variant="subtitle1" noWrap={true}>
                    {docType}
                  </Typography>
                </OverflowTip>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
