import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  orderedList: {
    margin: "20px 0 0 0",
    padding: 0,
    listStyleType: "none",

    "& > li": {
      counterIncrement: "step-counter",
      marginBottom: "10px",
      fontSize: "0.8em",
      display: "inline-block",
      position: "relative",
      padding: "0.5em 1em 0.5em 3em",
      width: "100%",

      "&::before": {
        content: "counter(step-counter)",
        position: "absolute",
        left: 0,
        borderRadius: "20px",
        backgroundColor: "#fff",
        width: "20px",
        height: "20px",
        lineHeight: "20px",
        color: "#7B059F",
        textAlign: "center",
        border: "1px solid #7B059F",
        display: "inline-block",
      },
    },
  },

  bodyText: {
    margin: "1em 1em 4em",
    textAlign: "center",
  },

  gridWrap: {
    marginTop: "30px",
    marginBottom: "30px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
}));

const listData = [
  {
    id: "a",
    content:
      "We want to organize at scale and manage our investment relationships in one place",
  },
  {
    id: "b",
    content:
      "We want to use data standardization to create a trusted data source",
  },
  {
    id: "c",
    content:
      "We want data insights to help us make timely and informed decisions",
  },
  {
    id: "d",
    content: "We want to improve efficiency through online communication",
  },
  {
    id: "e",
    content: "We want technology that our team can easily adopt",
  },
  {
    id: "f",
    content: "We want to access innovation through a marketplace",
  },
  {
    id: "g",
    content:
      "We want to streamline our Management Company Operations and Deliverables",
  },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  console.log("result = ", result);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? "#fff" : null,
  ...draggableStyle,
});

const Step2 = () => {
  const classes = useStyles();
  const [list, updateList] = useState(listData);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = reorder(list, result.source.index, result.destination.index);
    updateList(items);
  };

  return (
    <Grid className={classes.gridWrap}>
      <Grid item xs={12}>
        <Typography component="p" variant="h6" align="center">
          To maximise your time how would you like us to order the demo based on
          topics you care about?
        </Typography>
        <Typography component="p" variant="body1">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="list">
              {(provided, snapshot) => (
                <ol
                  className={classes.orderedList}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {list.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {item.content}
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ol>
              )}
            </Droppable>
          </DragDropContext>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Step2;
