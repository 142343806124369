import { DashboardData } from "../../common/types";

export const exampleDashboardReport = {
  _id: "1234-example-report",
  selectedDataSource: "Fundamental Data",
  dataItemsKey: "InstrumentName",
  selectedDataPoints: [
    { label: "Instrument Name", name: "InstrumentName" },
    { label: "EPS 12 Month Trailing", name: "Trail12MEps" },
  ],
  selectedDataItems: ["AMAZON.COM", "MICROSOFT"],
  reportName: "Amazon/MS",
  reportOwner: "TODO",
  reportType: "On Demand",
  eventDate: "26-Apr-2021",
};

export const exampleDashboardEmpty: DashboardData = {
  _id: "1234-example-dashboard-d",
  name: "Example Dashboard",
  widgets: [
    {
      _id: "1234-example-dashboard-e",
      dashboardId: "1234-example-dashboard-d",
      name: "",
      size: 8,
    },
    {
      _id: "1234-example-dashboard-f",
      dashboardId: "1234-example-dashboard-d",
      name: "",
      size: 4,
    },
    {
      _id: "1234-example-dashboard-g",
      dashboardId: "1234-example-dashboard-d",
      name: "",
      size: 4,
    },
    {
      _id: "1234-example-dashboard-h",
      dashboardId: "1234-example-dashboard-d",
      name: "",
      size: 4,
    },
    {
      _id: "1234-example-dashboard-i",
      dashboardId: "1234-example-dashboard-d",
      name: "",
      size: 4,
    },
  ],
};

export const exampleDashboard: DashboardData = {
  _id: "1234-example-dashboard-d",
  name: "Example Dashboard",
  widgets: [
    {
      _id: "1234-example-dashboard-e",
      dashboardId: "1234-example-dashboard-d",
      name: "Reports",
      size: 8,
      props: exampleDashboardReport,
    },
    {
      _id: "1234-example-dashboard-f",
      dashboardId: "1234-example-dashboard-d",
      name: "",
      size: 4,
    },
    {
      _id: "1234-example-dashboard-g",
      dashboardId: "1234-example-dashboard-d",
      name: "",
      size: 4,
    },
    {
      _id: "1234-example-dashboard-h",
      dashboardId: "1234-example-dashboard-d",
      name: "",
      size: 4,
    },
    {
      _id: "1234-example-dashboard-i",
      dashboardId: "1234-example-dashboard-d",
      name: "",
      size: 4,
    },
  ],
};
