import React from "react";
import { Typography, Box, MenuItem, TextField } from "@material-ui/core";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  eachMonthOfInterval,
  getQuarter,
  getYear,
  startOfYear,
  subYears,
  format,
  startOfMonth,
} from "date-fns";
import { eachQuarterOfInterval, eachYearOfInterval } from "date-fns/esm";
import { useDocuments } from "../Documents";
import {
  DocumentMetadataType,
  UserBehaviour,
} from "../../../../../common/types";
import { CheckBox } from "../../../../../components/Checkboxes/CheckBox";

export const DocumentTypeFilter = ({
  selectedDocType,
  setSelectedFromDate,
  setSelectedToDate,
  selectedFromDate,
  selectedToDate,
  selectedMonths,
  setSelectedMonths,
  selectedQuarters,
  setSelectedQuarters,
  portfolioManagers,
  setPortfolioManagers,
  selectedYears,
  setSelectedYears,
  investors,
  setInvestors,
  userBehaviour,
}: {
  selectedDocType: string;
  setSelectedFromDate: any;
  setSelectedToDate: any;
  selectedFromDate: any;
  selectedToDate: any;
  selectedMonths: any;
  setSelectedMonths: any;
  selectedQuarters: any;
  setSelectedQuarters: any;
  portfolioManagers: any;
  setPortfolioManagers: any;
  selectedYears: any;
  setSelectedYears: any;
  investors: any;
  setInvestors: any;
  userBehaviour: UserBehaviour | undefined;
}) => {
  const { docs } = useDocuments();

  const months = eachMonthOfInterval({
    start: subYears(new Date(), 1),
    end: new Date(),
  });

  const quarters = eachQuarterOfInterval({
    start: startOfYear(subYears(new Date(), 1)),
    end: new Date(),
  }).sort((a, b) => new Date(b).valueOf() - new Date(a).valueOf());

  const uniqueYears: any = [
    ...new Set(
      docs.map((doc: DocumentMetadataType) =>
        getYear(new Date(doc.documentActiveFromDate))
      )
    ),
  ].sort((a: any, b: any) => new Date(b).valueOf() - new Date(a).valueOf());

  const yearsfilter =
    uniqueYears.length !== 0
      ? eachYearOfInterval({
          start: new Date(uniqueYears[uniqueYears.length - 1].toString()),
          end: new Date(uniqueYears[0].toString()),
        })
      : [];
  const monthFilter = months.reduce((newObject: any, month: Date) => {
    const currentYear = getYear(month).toString();

    return {
      ...newObject,
      [currentYear]: [...(newObject[currentYear] || []), month].sort(
        (a, b) => new Date(b).valueOf() - new Date(a).valueOf()
      ),
    };
  }, {});

  const sortedMonthFilter = Object.entries(monthFilter).sort((a, b) =>
    b[0].localeCompare(a[0])
  );

  const quarterFilter = quarters.reduce((newObject: any, quarter: Date) => {
    const currentYear = getYear(quarter).toString();

    return {
      ...newObject,
      [currentYear]: [...(newObject[currentYear] || []), quarter],
    };
  }, {});

  const sortedQuarterFilter = Object.entries(
    quarterFilter
  ).sort(([yearA], [yearB]) => yearB.localeCompare(yearA));

  const renderInvestorsFilter = () => (
    <Box px={3} py={2}>
      <TextField
        id="investor-select"
        select
        fullWidth
        color="primary"
        variant="outlined"
        label="Investor"
        value={investors.selection}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          setInvestors({
            ...investors,
            selection: event.target.value as string,
          });
        }}
        disabled={portfolioManagers.selection !== ""}
        helperText="Select Investor"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {investors.options.map((option: string) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );

  const renderPortfolioFilter = () => (
    <Box px={3} py={2}>
      <TextField
        id="portfolio-manager-select"
        select
        fullWidth
        color="primary"
        variant="outlined"
        label="Portfolio Manager"
        value={portfolioManagers.selection}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          setPortfolioManagers({
            ...portfolioManagers,
            selection: event.target.value as string,
          });
        }}
        helperText="Select Portfolio Manager"
        disabled={investors.selection !== ""}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {portfolioManagers.options.map((option: string) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );

  const renderMonthsFilter = (sortedMonthFilter: any) => (
    <Box px={3} py={2}>
      {sortedMonthFilter.map(([year, months]: [string, any]) => {
        return (
          <React.Fragment key={year}>
            <Typography>{year}</Typography>
            {months.map((month: any, index: number) => {
              return (
                <CheckBox
                  key={index}
                  label={format(month, "MMM")}
                  checked={selectedMonths.includes(month.toString())}
                  onClick={() => {
                    if (selectedMonths.includes(month.toString())) {
                      setSelectedMonths([
                        ...selectedMonths.filter(
                          (checkbox: any) => checkbox !== month.toString()
                        ),
                      ]);
                    } else {
                      setSelectedMonths([...selectedMonths, month.toString()]);
                    }
                  }}
                  style={{ width: "25%" }}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </Box>
  );

  switch (selectedDocType) {
    case "Investment Statement":
    case "Fund Distributions":
    case "Contract Notes":
      return (
        <>
          {userBehaviour === UserBehaviour.advisor
            ? renderInvestorsFilter()
            : null}
          {renderMonthsFilter(sortedMonthFilter)}
          <Box px={3} py={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <DatePicker
                  style={{ marginRight: "10px" }}
                  inputVariant="outlined"
                  views={["year", "month"]}
                  helperText="Select From Date"
                  disabled={
                    selectedQuarters.length !== 0 || selectedMonths.length !== 0
                  }
                  variant="inline"
                  label="From"
                  maxDate={new Date()}
                  value={startOfMonth(selectedFromDate)}
                  onChange={() => {}}
                  onMonthChange={(date) => setSelectedFromDate(date)}
                />
                <DatePicker
                  style={{ marginRight: "10px" }}
                  inputVariant="outlined"
                  views={["year", "month"]}
                  disabled={
                    selectedQuarters.length !== 0 || selectedMonths.length !== 0
                  }
                  helperText="Select To Date"
                  variant="inline"
                  minDate={selectedFromDate}
                  maxDate={new Date()}
                  label="To"
                  value={selectedToDate}
                  onChange={() => {}}
                  onMonthChange={(date) => setSelectedToDate(date)}
                />
              </div>
            </MuiPickersUtilsProvider>
          </Box>
        </>
      );
    case "Factsheet":
    case "Manager Reports":
      return (
        <>
          {userBehaviour === UserBehaviour.advisor
            ? renderInvestorsFilter()
            : null}
          {renderPortfolioFilter()}
          {renderMonthsFilter(sortedMonthFilter)}
          <Box px={3} py={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <DatePicker
                  style={{ marginRight: "10px" }}
                  inputVariant="outlined"
                  views={["year", "month"]}
                  helperText="Select From Date"
                  disabled={
                    selectedQuarters.length !== 0 || selectedMonths.length !== 0
                  }
                  variant="inline"
                  label="From"
                  maxDate={new Date()}
                  value={startOfMonth(selectedFromDate)}
                  onChange={() => {}}
                  onMonthChange={(date) => setSelectedFromDate(date)}
                />
                <DatePicker
                  style={{ marginRight: "10px" }}
                  inputVariant="outlined"
                  views={["year", "month"]}
                  disabled={
                    selectedQuarters.length !== 0 || selectedMonths.length !== 0
                  }
                  helperText="Select To Date"
                  variant="inline"
                  minDate={selectedFromDate}
                  maxDate={new Date()}
                  label="To"
                  value={selectedToDate}
                  onChange={() => {}}
                  onMonthChange={(date) => setSelectedToDate(date)}
                />
              </div>
            </MuiPickersUtilsProvider>
          </Box>
        </>
      );
    case "Quarterly Account Statement":
    case "QIR":
      return (
        <>
          {userBehaviour === UserBehaviour.advisor
            ? renderInvestorsFilter()
            : null}
          {renderPortfolioFilter()}
          <Box px={3} py={2}>
            {sortedQuarterFilter.map(([year, quarters]: [string, any]) => {
              return (
                <React.Fragment key={year}>
                  <Typography>{year}</Typography>
                  {quarters.map((quarter: any, index: number) => {
                    return (
                      <CheckBox
                        key={index}
                        label={`Q${getQuarter(quarter)}`}
                        checked={selectedQuarters.includes(quarter.toString())}
                        onClick={() => {
                          if (selectedQuarters.includes(quarter.toString())) {
                            setSelectedQuarters([
                              ...selectedQuarters.filter(
                                (checkbox: any) =>
                                  checkbox !== quarter.toString()
                              ),
                            ]);
                          } else {
                            setSelectedQuarters([
                              ...selectedQuarters,
                              quarter.toString(),
                            ]);
                          }
                        }}
                        style={{ width: "25%" }}
                      />
                    );
                  })}
                </React.Fragment>
              );
            })}
          </Box>
          <Box px={3} py={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <DatePicker
                  style={{ marginRight: "10px" }}
                  views={["year", "month"]}
                  helperText="Select From Date"
                  variant="inline"
                  inputVariant="outlined"
                  label="From"
                  maxDate={new Date()}
                  disabled={
                    selectedQuarters.length !== 0 || selectedMonths.length !== 0
                  }
                  value={startOfMonth(selectedFromDate)}
                  onMonthChange={(date) => setSelectedFromDate(date)}
                  onChange={() => {}}
                />
                <DatePicker
                  views={["year", "month"]}
                  helperText="Select To Date"
                  variant="inline"
                  inputVariant="outlined"
                  label="To"
                  minDate={selectedFromDate}
                  maxDate={new Date()}
                  disabled={
                    selectedQuarters.length !== 0 || selectedMonths.length !== 0
                  }
                  value={selectedToDate}
                  onChange={() => {}}
                  onMonthChange={(date) => setSelectedToDate(date)}
                />
              </div>
            </MuiPickersUtilsProvider>
          </Box>
        </>
      );
    case "Financial Statement":
      return (
        <>
          {userBehaviour === UserBehaviour.advisor
            ? renderInvestorsFilter()
            : null}
          {renderPortfolioFilter()}
          <Box px={3} py={2}>
            {yearsfilter.map((year: any, index: number) => {
              return (
                <CheckBox
                  key={index}
                  label={format(year, "y")}
                  checked={selectedYears.includes(year.toString())}
                  onClick={() => {
                    if (selectedYears.includes(year.toString())) {
                      setSelectedYears([
                        ...selectedYears.filter(
                          (checkbox: any) => checkbox !== year.toString()
                        ),
                      ]);
                    } else {
                      setSelectedYears([...selectedYears, year.toString()]);
                    }
                  }}
                  style={{ width: "25%" }}
                />
              );
            })}
          </Box>
          <Box px={3} py={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <DatePicker
                  style={{ marginRight: "10px" }}
                  views={["year"]}
                  inputVariant="outlined"
                  helperText="Select From Date"
                  variant="inline"
                  label="From"
                  maxDate={new Date()}
                  value={selectedFromDate}
                  onChange={(date) => setSelectedFromDate(date)}
                  disabled={selectedYears.length !== 0}
                />
                <DatePicker
                  views={["year"]}
                  inputVariant="outlined"
                  helperText="Select To Date"
                  variant="inline"
                  label="To"
                  minDate={selectedFromDate}
                  maxDate={new Date()}
                  disabled={selectedYears.length !== 0}
                  value={selectedToDate}
                  onChange={(date) => setSelectedToDate(date)}
                />
              </div>
            </MuiPickersUtilsProvider>
          </Box>
        </>
      );
    case "Fund Supplements":
      return (
        <>
          {userBehaviour === UserBehaviour.advisor
            ? renderInvestorsFilter()
            : null}
          {renderPortfolioFilter()}
        </>
      );
    default:
      return (
        <>
          {userBehaviour === UserBehaviour.advisor
            ? renderInvestorsFilter()
            : null}
          <Box px={3} py={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <DatePicker
                  style={{ marginRight: "10px" }}
                  inputVariant="outlined"
                  helperText="Select From Date"
                  variant="inline"
                  label="From"
                  disabled={
                    selectedQuarters.length !== 0 || selectedMonths.length !== 0
                  }
                  maxDate={new Date()}
                  value={selectedFromDate}
                  onChange={(date) => setSelectedFromDate(date)}
                />
                <DatePicker
                  inputVariant="outlined"
                  helperText="Select To Date"
                  variant="inline"
                  label="To"
                  disabled={
                    selectedQuarters.length !== 0 || selectedMonths.length !== 0
                  }
                  minDate={selectedFromDate}
                  maxDate={new Date()}
                  value={selectedToDate}
                  onChange={(date) => setSelectedToDate(date)}
                />
              </div>
            </MuiPickersUtilsProvider>
          </Box>
        </>
      );
  }
};
