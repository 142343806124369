import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const TickIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      id="noun_tick_2311216"
      xmlns="http://www.w3.org/2000/svg"
      width="23.354"
      height="23.354"
      viewBox="0 0 23.354 23.354"
    >
      <path
        id="Path_944"
        data-name="Path 944"
        d="M11.677,1.635A10.042,10.042,0,1,1,1.635,11.677,10.056,10.056,0,0,1,11.677,1.635m0-1.635A11.677,11.677,0,1,0,23.354,11.677,11.681,11.681,0,0,0,11.677,0Z"
        fill="#fff"
      />
      <path
        id="Path_945"
        data-name="Path 945"
        d="M35.77,31.355l-7.753,7.753a.827.827,0,0,1-.607.234.865.865,0,0,1-.607-.234l-2.849-2.849a.8.8,0,0,1,0-1.121.759.759,0,0,1,.56-.234.724.724,0,0,1,.56.234l2.335,2.335,7.24-7.24a.759.759,0,0,1,.56-.234.724.724,0,0,1,.56.234A.8.8,0,0,1,35.77,31.355Z"
        transform="translate(-18.184 -22.994)"
        fill="#fff"
      />
    </SvgIcon>
  );
};
