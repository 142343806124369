import React from "react";
import {
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";

import "./Funds.css";
import { useQuery } from "react-query";
import MUIDataTable from "mui-datatables";
import { useAuth } from "../../../../providers";
import { Link } from "react-router-dom";
import { AddCircleOutline, InfoOutlined } from "@material-ui/icons";
import { downloadFile } from "../../../../helpers";

const useStyles = makeStyles((theme) => ({
  liveAnnounce: {
    "& [class*=liveAnnounce]": {
      display: "none",
    },
    "& [aria-live=polite]": {
      display: "none",
    },
  },
  link: {
    cursor: "pointer",
    color: "#18567F",
    "&:hover": {
      textDecoration: "underline !important",
    },
  },
  rowStyle: {
    "&:hover": {
      background: "white !important",
    },
  },
  circleIcon: {
    width: "0.8em",
    height: "0.8em",
    marginTop: 4,
  },
}));

const Funds = (props: { showEsg: boolean }) => {
  const { axiosWithAuth } = useAuth();

  const { data, isLoading } = useQuery<any, Error>(
    "users",
    () => {
      return axiosWithAuth!({
        url: "/store/funds",
      }).then((data) => {
        const rows = {
          nav: data.nav.map((navItem: any, index: number) => ({
            ...navItem,
            id: index,
          })),
        };

        return rows.nav;
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const classes = useStyles();

  if (isLoading) return <LinearProgress />;

  const options: any = {
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    filterType: "multiselect",
    selectableRows: "none",
    elevation: 0,
    rowHover: false,
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      return {
        className: classes.rowStyle,
      };
    },
    pagination: true,
    draggableColumns: {
      enabled: true,
    },
    fixedHeader: true,
    fixedSelectColumn: true,
  };

  const getColumns = (useESG: boolean) => {
    const baseColumns = [
      {
        name: "fundName",
        label: "Fund Name",
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const fundName = data[dataIndex].fundName;
            const fundClassSk = data[dataIndex].fundClassSk;
            const loiFund = data[dataIndex].loiFund;
            const tourProp =
              dataIndex === 0
                ? {
                    "data-tour": "fund-detail-link",
                  }
                : {};
            return loiFund === undefined ? (
              <Link
                className={classes.link}
                to={`/store/funds/detail/${fundClassSk}`}
                style={{
                  minWidth: "250px",
                  cursor: "pointer",
                  color: "#18567F",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
                {...tourProp}
              >
                {fundName}
              </Link>
            ) : (
              <Typography
                style={{
                  fontSize: "1vw",
                  minWidth: "250px",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {fundName}
                <Tooltip title="This product is pending launch please contact client services (service@theamx.com) for more details">
                  <InfoOutlined
                    fontSize="small"
                    style={{ marginLeft: "0.260vw", fontSize: "0.700vw" }}
                  />
                </Tooltip>
              </Typography>
            );
          },
        },
      },
      {
        name: "classValue",
        label: "Class",
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const classValue = data[dataIndex].classValue;
            return (
              <Typography
                style={{
                  fontSize: "1vw",
                  minWidth: "250px",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {classValue}
              </Typography>
            );
          },
        },
      },
      {
        name: "fundStrategy",
        label: "Strategy",
      },
      {
        name: "classCurrency",
        label: "CCY",
      },
      {
        name: "fundValDate",
        label: "Valuation Date",
        options: { setCellProps: () => ({ style: { whiteSpace: "nowrap" } }) },
      },
      {
        name: "classNavPs",
        label: "NAV",
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const classNavPs = data[dataIndex].classNavPs;
            return classNavPs !== null
              ? new Intl.NumberFormat("en-EN", {
                  currency: "GBP",
                  style: "decimal",
                  minimumFractionDigits: 4,
                  maximumFractionDigits: 4,
                }).format(classNavPs)
              : "-";
          },
        },
      },
      {
        name: "classPriorNav",
        label: "Prev NAV",
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const classPriorNav = data[dataIndex].classPriorNav;
            if (classPriorNav === null) return "-";
            const formattedNumber = new Intl.NumberFormat("en-EN", {
              currency: "GBP",
              style: "decimal",
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            }).format(classPriorNav);

            const fundClassSk = data[dataIndex].fundClassSk;
            const fundSk = data[dataIndex].fundSk;
            const url = "/store/funds/fundDetail/download/history";
            const fileName = "nav_history.csv";
            return (
              <div
                role="button"
                tabIndex={0}
                className={classes.link}
                style={{ cursor: "pointer", textDecoration: "underlined" }}
                onClick={() => {
                  if (axiosWithAuth === undefined) return;
                  axiosWithAuth({
                    url,
                    method: "GET",
                    responseType: "blob",
                    params: {
                      fundSk,
                      fundClassSk,
                    },
                  }).then((data: any) => {
                    downloadFile(data, fileName);
                  });
                }}
              >
                {formattedNumber}
              </div>
            );
          },
        },
      },
      {
        name: "percentChange",
        label: "Change (%)",
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const percentChange = data[dataIndex].percentChange;

            return percentChange !== null
              ? new Intl.NumberFormat("en-EN", {
                  currency: "GBP",
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(percentChange)
              : "-";
          },
        },
      },
      {
        name: "priceFrequency",
        label: "Frequency",
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const priceFrequency = data[dataIndex].priceFrequency;
            if (priceFrequency === null) return "-";
            const fundSk = data[dataIndex].fundSk;
            const url = "/store/funds/fundDetail/download/calendar";
            const fileName = "calendar.csv";
            return (
              <div
                role="button"
                tabIndex={0}
                className={classes.link}
                style={{ cursor: "pointer", textDecoration: "underlined" }}
                onClick={() => {
                  if (axiosWithAuth === undefined) return;
                  axiosWithAuth({
                    url,
                    method: "GET",
                    responseType: "blob",
                    params: {
                      fundSk,
                    },
                  }).then((data: any) => {
                    downloadFile(data, fileName);
                  });
                }}
              >
                {priceFrequency}
              </div>
            );
          },
        },
      },
      // TODO: Getting a console warning for the options in the 3 items below
      {
        name: "umbrellaName",
        label: "Umbrella",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: "subFundCategory",
        label: "Sub-Fund Category",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: "priceType",
        label: "Price Type",
        options: {
          display: false,
        },
      },
      {
        name: "classMtdPl",
        label: "MTD%",
        options: {
          display: false,
          customBodyRenderLite: (dataIndex: number) => {
            const classMtdPl = data[dataIndex].classMtdPl;
            return classMtdPl !== null
              ? new Intl.NumberFormat("en-EN", {
                  currency: "GBP",
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(classMtdPl)
              : "-";
          },
          hint:
            "Shows the NAVPS change over the period to the current reported Valuation Date. Note this does not include any adjustments for distributions or other ad-hoc events that may impact performance.",
        },
      },
      {
        name: "classQtdPl",
        label: "QTD%",
        options: {
          display: false,
          customBodyRenderLite: (dataIndex: number) => {
            const classQtdPl = data[dataIndex].classQtdPl;
            return classQtdPl !== null
              ? new Intl.NumberFormat("en-EN", {
                  currency: "GBP",
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(classQtdPl)
              : "-";
          },
          hint:
            "Shows the NAVPS change over the period to the current reported Valuation Date. Note this does not include any adjustments for distributions or other ad-hoc events that may impact performance.",
        },
      },
      {
        name: "classYtdPl",
        label: "YTD%",
        options: {
          display: false,
          customBodyRenderLite: (dataIndex: number) => {
            const classYtdPl = data[dataIndex].classYtdPl;
            return classYtdPl !== null
              ? new Intl.NumberFormat("en-EN", {
                  currency: "GBP",
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(classYtdPl)
              : "-";
          },
          hint:
            "Shows the NAVPS change over the period to the current reported Valuation Date. Note this does not include any adjustments for distributions or other ad-hoc events that may impact performance.",
        },
      },
      {
        name: "classItdPl",
        minWidth: 70,
        label: "ITD%",
        options: {
          display: false,
          customBodyRenderLite: (dataIndex: number) => {
            const classYtdPl = data[dataIndex].classYtdPl;
            return classYtdPl !== null
              ? new Intl.NumberFormat("en-EN", {
                  currency: "GBP",
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(classYtdPl)
              : "-";
          },
          hint:
            "Shows the NAVPS change since the launch of the class to the NAVPS of the current reported Valuation Date. Note this does not include any adjustments for Distributions or other ad-hoc events that may impact the performance of a NAVPS",
        },
      },
    ];

    if (!useESG) {
      return baseColumns;
    }

    const customBodyRenderLite: any = (dataIndex: number) => {
      const { esgScore, fundName, fundClassSk } = data[dataIndex];
      const noData = esgScore === -1000;
      return noData ? (
        "-"
      ) : esgScore === -100 ? (
        <Tooltip
          title={`You can upgrade your package to view ESG Data for this fund.
                      Click the plus icon to go to the Store and choose "OWL Analytics".`}
        >
          <Link
            data-trackable="ESG Add"
            data-trackable-params-fund={fundName}
            to={encodeURI(
              `/store/apps-and-services?section=sustainable-finance&fund=${fundName}&fundId=${fundClassSk}&returnUrl=/store/funds`
            )}
          >
            <AddCircleOutline className={classes.circleIcon} />
          </Link>
        </Tooltip>
      ) : (
        <Tooltip title="ESG Data is available for this fund.">
          <Link to={encodeURI(`/store/funds/detail/${fundClassSk}/esg`)}>
            {esgScore}
          </Link>
        </Tooltip>
      );
    };

    baseColumns.splice(8, 0, {
      name: "esgScore",
      label: "ESG Score",
      options: {
        customBodyRenderLite,
      },
    });

    return baseColumns;
  };

  return (
    <Grid data-tour="store-funds" data-trackable-context="Funds">
      <Paper
        style={{
          padding: "30px",
        }}
        className={classes.liveAnnounce}
      >
        <MUIDataTable
          title=""
          data={data}
          columns={getColumns(props.showEsg)}
          options={options}
        />
      </Paper>
    </Grid>
  );
};

export default Funds;
