import React, { useMemo } from "react";
import MUIDataTable from "mui-datatables";
import { TabView } from "../../components";
import { Link } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

export const Messages = () => {
  const history = useHistory();

  const data = [
    {
      from: "Advisor",
      date: "18/03/2021",
      subject: "YE20 valuation reports",
      message: "YE20 valuation reports are available to review",
      link: "",
    },
    {
      from: "Advisor",
      date: "18/03/2021",
      subject: "Q420 valuation reports",
      message: "Q420 valuation reports are available to review",
      link: "",
    },
    // {
    //   from: "WTW_Legal",
    //   date: "17/03/2021",
    //   subject: "Pension Company - Contract Notes 12 Mar 2021",
    //   message:
    //     "Pension Company - Contract Notes 12 Mar 2021 are available to review",
    //   link: "",
    // },
  ];

  const columns = useMemo(
    () => [
      {
        name: "from",
        label: "From",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "date",
        label: "Date",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "subject",
        label: "Subject",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "message",
        label: "Message",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "message",
        label: "Message",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "link",
        label: "Link",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
            <Link
              onClick={() => {
                history.push("/explorer/documents");
              }}
              style={{ cursor: "pointer" }}
              data-tour="messages-link"
            />
          ),
        },
      },
    ],
    [history]
  );

  const options: any = useMemo(
    () => ({
      download: false,
      filter: false,
      print: false,
      search: false,
      viewColumns: false,
      responsive: "vertical",
      selectToolbarPlacement: "none",
      elevation: 0,
      pagination: false,
      selectableRows: "none",
      rowsPerPage: 5,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <TabView
      data-tour="connect-messages"
      title="Messages"
      data-tour="messages-table"
    >
      <MUIDataTable title="" data={data} columns={columns} options={options} />
    </TabView>
  );
};
