import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Options } from "highcharts/highstock";
import React, { Fragment } from "react";

const composeChart = (data: any): Options => {
  return {
    chart: {
      type: "column",
    },
    credits: { enabled: false },
    title: {
      text: "",
    },

    xAxis: {
      categories: data.map((item: any) => item.name),
      crosshair: true,
      labels: { format: "{value}" },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Metric",
      },
    },
    tooltip: {
      formatter: function (tooltip) {
        const value = this.points![0].y;
        const baseline = this.points![1].y;
        const description = this.points![0].key;
        const valueLabel = this.points![0].series.name;
        const baselineLabel = this.points![1].series.name;
        return `
            <div style="font-size:16px;padding-bottom:10px"><b>${this.x}</b></div>
            <div style="font-size:14px;padding-bottom:10px">${description}</div>
            <div style="font-size:14px;"><b>${valueLabel}:</b> ${value}</div>
            <div style="font-size:14px;"><b>${baselineLabel}:</b> ${baseline}</div>
            `;
      },
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Portfolio Average Score",
        type: "column",
        data: data.map((item: any) => ({
          y: item.value,
          name: item.description,
        })),
      },
      {
        name: "Benchmark Score (MSCI World)",
        type: "column",
        data: data.map((item: any) => ({
          y: item.benchmark,
        })),
      },
    ],
  };
};

type esgType = {
  name: string;
  description: string;
  benchmark: number;
  value: number;
};

const EsgTabContent = (props: { fund: any }) => {
  const { fund } = props;

  // Filter out Correlation data for each data item
  const esgSummaryData: esgType = fund.fundInfo.owl.summary[0];

  const environmentalData: esgType[] = fund.fundInfo.owl.environmental.filter(
    (item: { name: string }) => item.name.substr(1, 1) !== " "
  );
  const governanceData: esgType[] = fund.fundInfo.owl.governance.filter(
    (item: { name: string }) => item.name.substr(1, 1) !== " "
  );
  const socialData: esgType[] = fund.fundInfo.owl.social
    .filter(
      (item: { name: string }) =>
        item.name.substr(1, 1) !== " " && item.name.substr(3, 1) !== " "
    )
    .sort((a: { name: string }, b: { name: string }) => {
      // sort order: S, EMP, CIT
      if (a.name === "S") return -1;
      if (b.name === "S") return 1;
      if (a.name.substr(0, 1) === "E") {
        if (b.name.substr(0, 1) === "E") {
          return a.name > b.name;
        } else {
          return -1;
        }
      }
      return a.name > b.name;
    });

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} style={{ padding: 40 }}>
          <Table className="detail-header-table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="center">Portfolio</TableCell>
                <TableCell align="center" style={{ minWidth: 140 }}>
                  Benchmark (MSCI World)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: 500 }}>Coverage (%)</TableCell>
                <TableCell>
                  Percentage of Fund Portfolio that is Covered by OWL Analytics
                  Reports
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 500 }}>
                  {fund.fundInfo.owl.coveragePct}%
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 500 }}>
                  {fund.fundInfo.owl.indexCoveragePct}%
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 500 }}>ESG Score</TableCell>
                <TableCell>{esgSummaryData.description}</TableCell>
                <TableCell align="center" style={{ fontWeight: 500 }}>
                  {esgSummaryData.value}
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 500 }}>
                  {esgSummaryData.benchmark || "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            className="title-padding"
            align="center"
            gutterBottom
          >
            Environment
          </Typography>
          <HighchartsReact
            highcharts={Highcharts}
            options={composeChart(environmentalData)}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            variant="h6"
            className="title-padding"
            align="center"
            gutterBottom
          >
            Governance
          </Typography>
          <HighchartsReact
            highcharts={Highcharts}
            options={composeChart(governanceData)}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: 40 }}>
          <Typography
            variant="h6"
            className="title-padding"
            align="center"
            gutterBottom
          >
            Social
          </Typography>
          <HighchartsReact
            highcharts={Highcharts}
            options={composeChart(socialData)}
          />
        </Grid>

        <Grid item xs={12}>
          <Table className="detail-header-table">
            <TableHead>
              <TableRow>
                <TableCell>Fund/Index</TableCell>
                <TableCell align="center">Coverage</TableCell>
                <TableCell align="center">ESG Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{fund.fundName}</TableCell>
                <TableCell align="center">{esgSummaryData.value}</TableCell>
                <TableCell align="center">
                  {fund.fundInfo.owl.coveragePct}%
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>MSCI World</TableCell>
                <TableCell align="center">{esgSummaryData.benchmark}</TableCell>
                <TableCell align="center">
                  {fund.fundInfo.owl.indexCoveragePct}%
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12}>
          <Box mt={4} />
        </Grid>

        <Grid item xs={12}>
          <Table className="detail-header-table">
            <TableHead>
              <TableRow>
                <TableCell>Fund/Index</TableCell>
                {environmentalData.map((eScore) => (
                  <TableCell align="center">{eScore.name} Score</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{fund.fundName}</TableCell>
                {environmentalData.map((eScore) => (
                  <TableCell align="center">{eScore.value}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>MSCI World</TableCell>
                {environmentalData.map((eScore) => (
                  <TableCell align="center">{eScore.benchmark}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12}>
          <Box mt={4} />
        </Grid>

        <Grid item xs={12}>
          <Table className="detail-header-table">
            <TableHead>
              <TableRow>
                <TableCell>Fund/Index</TableCell>
                {governanceData.map((gScore) => (
                  <TableCell align="center">{gScore.name} Score</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{fund.fundName}</TableCell>
                {governanceData.map((gScore) => (
                  <TableCell align="center">{gScore.value}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>MSCI World</TableCell>
                {governanceData.map((gScore) => (
                  <TableCell align="center">{gScore.benchmark}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12}>
          <Box mt={4} />
        </Grid>

        <Grid item xs={12}>
          <Table className="detail-header-table">
            <TableHead>
              <TableRow>
                <TableCell>Fund/Index</TableCell>
                {socialData.map((sScore) => (
                  <TableCell align="center">{sScore.name} Score</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{fund.fundName}</TableCell>
                {socialData.map((sScore) => (
                  <TableCell align="center">{sScore.value}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>MSCI World</TableCell>
                {socialData.map((sScore) => (
                  <TableCell align="center">{sScore.benchmark}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EsgTabContent;
