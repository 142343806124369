import { UserAgentApplication } from "msal";
import { useCallback, useMemo } from "react";
import { setAuthenticatedUser } from "@amx/web-components";

export const useMsal = (config: any) => {
  const msalInstance: UserAgentApplication = useMemo(
    () =>
      new UserAgentApplication({
        auth: {
          clientId: config.AMX_PHOENIX_CLIENT_APP_ID,
          navigateToLoginRequestUrl: false,
          authority: `${config.AMX_PHOENIX_CLIENT_APP_AUTHORITY}/${config.AMX_PHOENIX_CLIENT_APP_USER_FLOW}`,
          redirectUri: config.AMX_PHOENIX_CLIENT_APP_REDIRECT,
          validateAuthority: false,
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
      }),
    [
      config.AMX_PHOENIX_CLIENT_APP_ID,
      config.AMX_PHOENIX_CLIENT_APP_AUTHORITY,
      config.AMX_PHOENIX_CLIENT_APP_USER_FLOW,
      config.AMX_PHOENIX_CLIENT_APP_REDIRECT,
    ]
  );

  msalInstance.handleRedirectCallback((error: any, response: any) => {
    if (error) {
      if (error.message.indexOf("AADB2C90118") > -1) {
        msalInstance.loginRedirect({
          scopes: [config.AMX_PHOENIX_CLIENT_APP_ID],
          authority: `${config.AMX_PHOENIX_CLIENT_APP_AUTHORITY}/B2C_1_PR`,
        });
      }
    } else {
      if (
        response.tokenType === "id_token" &&
        response.idToken.claims["tfp"] === config.passwordResetUserFlow
      ) {
        msalInstance.logout();
      }
    }
  });

  const login = useCallback(() => {
    msalInstance.loginRedirect({
      scopes: [config.AMX_PHOENIX_CLIENT_APP_ID],
      authority: `${config.AMX_PHOENIX_CLIENT_APP_AUTHORITY}/${config.AMX_PHOENIX_CLIENT_APP_USER_FLOW}`,
    });
  }, [config, msalInstance]);

  const getUserAccount = useCallback(() => {
    return msalInstance.getAccount();
  }, [msalInstance]);

  const getAccessToken = useCallback(async () => {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token

      let silentResult = await msalInstance.acquireTokenSilent({
        scopes: [config.AMX_PHOENIX_CLIENT_APP_ID],
        authority: `${config.AMX_PHOENIX_CLIENT_APP_AUTHORITY}/${config.AMX_PHOENIX_CLIENT_APP_USER_FLOW}`,
      });

      if (!silentResult.accessToken) {
        silentResult = await msalInstance.acquireTokenSilent({
          scopes: [config.AMX_PHOENIX_CLIENT_APP_ID],
          authority: `${config.AMX_PHOENIX_CLIENT_APP_AUTHORITY}/${config.AMX_PHOENIX_CLIENT_APP_USER_FLOW}`,
        });
      }

      setAuthenticatedUser(silentResult.account.idTokenClaims.oid);

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (isInteractionRequired(err)) {
        return msalInstance.acquireTokenRedirect({
          scopes: [config.AMX_PHOENIX_CLIENT_APP_ID],
        });
      } else {
        throw err;
      }
    }
  }, [config, msalInstance]);

  const isInteractionRequired = (error: any) => {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return (
      error.message.indexOf("consent_required") > -1 ||
      error.message.indexOf("interaction_required") > -1 ||
      error.message.indexOf("login_required") > -1
    );
  };

  const logout = useCallback(() => {
    msalInstance.logout();
  }, [msalInstance]);

  return { logout, login, getUserAccount, getAccessToken, msalInstance };
};
