import React, { useReducer, useEffect, useState } from "react";
import Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import {
  Typography,
  Grid,
  Paper,
  Tab,
  Tabs,
  ListItemText,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import BarChartIcon from "@material-ui/icons/BarChart";
import PieChartIcon from "@material-ui/icons/PieChart";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import BarChart from "./BarChart";
import Tabular from "./Tabular";
import { TabPanelProps } from "./types";
import { useAuth } from "../../../../../providers";
import { useQueryParams } from "../../../../../helpers/useQueryParams";
import { View } from "../../../../../components";

more(Highcharts);

const NavTab = (props: any) => {
  const { tab, ...rest } = props;

  const tabIcons: any = {
    tabular: <MenuIcon fontSize="small" />,
    bar: <BarChartIcon fontSize="small" />,
    pie: <PieChartIcon fontSize="small" />,
    polar: <TrackChangesIcon fontSize="small" />,
  };

  return <Tab icon={tabIcons[tab.reportType]} {...rest} />;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const initialState = {
  dataSources: [
    {
      name: "",
      dataSourceId: "",
    },
  ],
  dataSource: {
    tabs: [
      {
        reportType: "",
        data: [],
      },
    ],
  },
  selectedDataSource: null,
  selectedReportType: "",
};

const CreateReport = () => {
  const { axiosWithAuth } = useAuth();
  const query = useQueryParams();

  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reportLoading, setReportLoading] = useState<boolean>(true);
  const [reportLibraryState, setReportLibraryState] = useReducer(
    (state: any, newState: any) => ({
      ...state,
      ...newState,
    }),
    { ...initialState, selectedDataSource: query.get("dataSourceId") }
  );

  useEffect(() => {
    if (axiosWithAuth === undefined) return;

    const getDataSourcesData = async () => {
      try {
        const dataSources = await axiosWithAuth({
          url: `/data-library/`,
        });

        setReportLibraryState({
          dataSources: dataSources.map((source: { name: string }) => ({
            ...source,
            name: source.name,
          })),
          selectedDataSource:
            reportLibraryState.selectedDataSource || dataSources[0].reportId,
        });
      } catch (err) {
        console.error(err);
      }
    };

    getDataSourcesData();
  }, [axiosWithAuth, reportLibraryState.selectedDataSource]);

  useEffect(() => {
    if (axiosWithAuth === undefined) return;

    setReportLoading(true);
    const getDataSource = async () => {
      try {
        const dataSource = await axiosWithAuth({
          method: "GET",
          url: `/data-library/${reportLibraryState.selectedDataSource}`,
        });

        setReportLibraryState({ dataSource });
        setReportLoading(false);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    if (reportLibraryState.selectedDataSource) {
      getDataSource();
    }
  }, [axiosWithAuth, reportLibraryState.selectedDataSource]);

  return (
    <View isLoading={isLoading}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2">
            Create Report <Typography variant="caption">BETA</Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ minWidth: "100%", maxWidth: "100%" }}>
            <InputLabel id="data-sources-select-label">
              Select Data Source
            </InputLabel>
            <Select
              labelId="data-sources-select-label"
              id="data-sources-select"
              value={reportLibraryState.selectedDataSource}
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown;
                }>
              ) =>
                setReportLibraryState({
                  selectedDataSource: event.target.value,
                })
              }
            >
              {reportLibraryState.dataSources?.map(
                ({ reportId, name }: { reportId: number; name: string }) => (
                  <MenuItem
                    key={reportId !== undefined ? reportId : 0}
                    value={reportId}
                  >
                    <ListItemText primary={name} />
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ padding: "30px", position: "relative" }}>
            {reportLoading ? (
              <LinearProgress
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Tabs
                    value={selectedTab}
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      newValue: number
                    ) => setSelectedTab(newValue)}
                  >
                    {reportLibraryState.dataSource.tabs.map((tab: any) => (
                      <NavTab
                        key={tab.reportType}
                        tab={tab}
                        style={{ minWidth: "unset" }}
                      />
                    ))}
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  {reportLibraryState.dataSource.tabs.map(
                    (tab: any, index: number) => {
                      return (
                        <TabPanel
                          value={selectedTab}
                          index={index}
                          key={tab.reportType}
                        >
                          {tab.reportType === "tabular" ? (
                            <Tabular
                              selectedReport={
                                reportLibraryState.selectedDataSource
                              }
                              tab={tab}
                            />
                          ) : tab.reportType === "bar" ? (
                            <BarChart
                              selectedReport={
                                reportLibraryState.selectedDataSource
                              }
                              tabularData={reportLibraryState.dataSource.tabs.find(
                                (tab: any) => tab.reportType === "tabular"
                              )}
                              tab={tab}
                            />
                          ) : (
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={tab.data}
                            />
                          )}
                        </TabPanel>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </View>
  );
};

export default CreateReport;
