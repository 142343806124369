import React from "react";
import {
  Typography,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  Paper,
  Box,
} from "@material-ui/core";
import { Route, Switch, useParams } from "react-router-dom";

import "./FundDetail.css";
import FundInfoTabContent from "./FundInfoTabContent";
import FundHoldingsTabContent from "./FundHoldingsTabContent";
import FundPerformanceTabContent from "./FundPerformanceTabContent";
import FundExposureTabContent from "./FundExposureTabContent";
import axios from "axios";
import EsgTabContent from "./EsgTabContent";
import { Alert, AlertTitle } from "@material-ui/lab";
import NoFundInfoError from "./NoFundInfoError";
import { useAuth } from "../../../../../providers";
import { View } from "../../../../../components";
import { AmxTabs, AmxTab } from "../../../../../components/Tabs/AmxTabs";
import { useQuery } from "react-query";

const handleDownload = async (token: string, fundClassSk: string) => {
  const response = await axios({
    url: "/api/fundDetail/download/history",
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { fundClassSk },
  });

  const filename = "nav_history.csv";

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(new Blob([response.data]), filename);
  } else {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
};

const FundDetail = () => {
  const { fundId }: { fundId: string } = useParams();

  const {
    axiosWithAuth,
    authState,
    authState: { permissions },
  } = useAuth();
  const { accessToken } = authState;

  const showEsg = !(
    permissions.includes("factsheet-qir") || permissions.includes("third-party")
  );
  const esgReportStatus = showEsg ? "enabled" : "disabled";

  const isAMXUser = permissions.includes("demo-global-reader");

  const { data: info, isLoading, isError, error } = useQuery(
    ["fundDetail", fundId],
    () => {
      if (axiosWithAuth === undefined) return;
      return axiosWithAuth({
        url: "store/funds/fundDetail/info",
        params: {
          fundId,
        },
      }).then((data) => {
        return data;
      });
    }
  );

  return (
    <View
      isLoading={isLoading}
      style={{ backgroundColor: "rgba(99, 102, 106, 0.05)" }}
    >
      {isError && !info?.fundInfo ? (
        <Paper className="detail-paper-padding">
          <NoFundInfoError fundId={fundId} />
        </Paper>
      ) : null}
      {info?.fundInfo ? (
        <React.Fragment>
          <Typography variant="h5" gutterBottom>
            {info.fundName}
          </Typography>
          <Paper className="detail-paper-padding">
            {isError ? (
              <Alert severity="error">
                <AlertTitle>{error}</AlertTitle>
              </Alert>
            ) : null}
            <Box className="tab-content-area">
              <Table className="detail-header-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="branded-alt-bg">Fund Name</TableCell>
                    <TableCell className="branded-alt-bg">
                      Share Class
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontWeight: 500 }}>
                      {info.fundName}
                    </TableCell>
                    <TableCell>{info.shareClassCcy}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Paper>

          <Paper className="detail-paper-padding">
            {isAMXUser && (
              <AmxTabs>
                <AmxTab
                  data-tour="fund-detail-tab-information"
                  label="Information"
                  to={`/store/funds/detail/${fundId}`}
                  value={`/store/funds/detail/${fundId}`}
                />

                {esgReportStatus === "enabled" &&
                info?.fundInfo.owl &&
                info?.fundInfo.owl.coveragePct !== "0.00" ? (
                  <AmxTab
                    data-tour="fund-detail-tab-esg"
                    label="ESG"
                    to={`/store/funds/detail/${fundId}/esg`}
                    value={`/store/funds/detail/${fundId}/esg`}
                  />
                ) : null}

                {isAMXUser && info.fundInfo.performance !== undefined && (
                  <AmxTab
                    data-tour="fund-detail-tab-performance"
                    label="Performance"
                    to={`/store/funds/detail/${fundId}/performance`}
                    value={`/store/funds/detail/${fundId}/performance`}
                  />
                )}
                {isAMXUser && info.fundInfo.holdings !== undefined && (
                  <AmxTab
                    data-tour="fund-detail-tab-holdings"
                    label="Holdings"
                    to={`/store/funds/detail/${fundId}/holdings`}
                    value={`/store/funds/detail/${fundId}/holdings`}
                  />
                )}
                {isAMXUser &&
                  info.fundInfo.exposures !== undefined &&
                  (info.fundInfo.exposures.country.length > 0 ||
                    info.fundInfo.exposures.currency.length > 0 ||
                    info.fundInfo.exposures.sectorBreakdown.length > 0) && (
                    <AmxTab
                      data-tour="fund-detail-tab-exposure"
                      label="Exposure"
                      to={`/store/funds/detail/${fundId}/exposure`}
                      value={`/store/funds/detail/${fundId}/exposure`}
                    />
                  )}
              </AmxTabs>
            )}

            <Box className="tab-content-area">
              <Switch>
                <Route
                  path={`/store/funds/detail/${fundId}/esg`}
                  render={() => <EsgTabContent fund={info} />}
                />
                <Route
                  path={`/store/funds/detail/${fundId}/performance`}
                  render={() => <FundPerformanceTabContent fundInfo={info} />}
                />
                <Route
                  path={`/store/funds/detail/${fundId}/holdings`}
                  render={() => <FundHoldingsTabContent fundInfo={info} />}
                />
                <Route
                  path={`/store/funds/detail/${fundId}/exposure`}
                  render={() => <FundExposureTabContent fundInfo={info} />}
                />
                <Route
                  path={`/store/funds/detail/${fundId}`}
                  render={() => (
                    <FundInfoTabContent
                      fundInfo={info?.fundInfo}
                      showChart={
                        isAMXUser &&
                        info?.fundInfo.growthChartData !== undefined
                      }
                      historyCallback={async () => {
                        await handleDownload(accessToken, fundId);
                      }}
                    />
                  )}
                />
              </Switch>
            </Box>
          </Paper>
        </React.Fragment>
      ) : null}
    </View>
  );
};

export default FundDetail;
