import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const CollapseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.38173 0.00732422H10.8859L3.00597 10.9318L10.8347 21.9925H8.37778L0.550537 10.9235L8.38173 0.00732422Z"
        fill="#C410A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7167 0.00732422H21.2209L13.3409 10.9318L21.1697 21.9925H18.7127L10.8855 10.9235L18.7167 0.00732422Z"
        fill="#C410A0"
      />
    </SvgIcon>
  );
};
