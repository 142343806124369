import React from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { DashboardWidget } from "../../../common/types";
import { useSharedDashboardData } from "../../../providers";
import { WidgetWrapper } from "./WidgetWrapper";

type FundInspectorExposureProps = DashboardWidget & {};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const Row = (props: { row: any; bold?: boolean }) => {
  const { row, bold } = props;
  const classes = useRowStyles();

  if (row === undefined) return null;

  return (
    <>
      <TableRow
        className={classes.root}
        style={bold === true ? { borderTop: "1px solid #eaeaea" } : undefined}
      >
        <TableCell
          component="th"
          scope="row"
          style={bold === true ? { fontWeight: "bold" } : undefined}
        >
          {row.fundName}
        </TableCell>
        <TableCell align="right">{row.fundCcy}</TableCell>
        <TableCell
          align="right"
          style={bold === true ? { fontWeight: "bold" } : undefined}
        >
          {row.aumInUsd.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </TableCell>
        <TableCell align="right">
          {row.aumInFundCcy !== 0
            ? row.aumInFundCcy.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })
            : ""}
        </TableCell>
        <TableCell align="right">
          <strong>
            {bold === true
              ? row.inspectionValue === 0
                ? ""
                : `${row.inspectionValue.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })} (${row.percentTotal.toFixed(4)}%)`
              : row.inspectionValue === 0
              ? "-"
              : row.inspectionValue.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })}
          </strong>
        </TableCell>
        <TableCell align="right">
          <strong>
            {row.inspectionPercent === undefined
              ? ""
              : row.inspectionPercent === 0
              ? "-"
              : row.inspectionPercent.toFixed(4)}
          </strong>
        </TableCell>
      </TableRow>
    </>
  );
};

export const FundInspectorExposure = (props: FundInspectorExposureProps) => {
  const { inspectionData } = useSharedDashboardData();

  return (
    <WidgetWrapper data={props} title="Exposures">
      <Grid container>
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Investment</TableCell>
                  <TableCell align="right">Currency</TableCell>
                  <TableCell align="right">AUM (USD)</TableCell>
                  <TableCell align="right">AUM (Fund Currency)</TableCell>
                  <TableCell align="right">Exposure (USD)</TableCell>
                  <TableCell align="right">Exposure %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inspectionData &&
                  inspectionData.exposures &&
                  inspectionData.exposures.map((investment: any) => (
                    <Row
                      key={`${investment.fundName}-${investment.value}`}
                      row={investment}
                    />
                  ))}
                <Row
                  row={
                    inspectionData &&
                    inspectionData.exposures &&
                    inspectionData.exposures.reduce(
                      (summed: any, investment: any) => {
                        const inspectionValue =
                          summed.inspectionValue + investment.inspectionValue;

                        const aumInUsd = investment.aumInUsd + summed.aumInUsd;

                        return {
                          ...summed,
                          aumInUsd,
                          inspectionValue,
                          percentTotal: (inspectionValue / aumInUsd) * 100,
                        };
                      },
                      {
                        aumInUsd: 0,
                        aumInFundCcy: 0,
                        fundName: "Total",
                        percentTotal: 0,
                        inspectionValue: 0,
                      }
                    )
                  }
                  bold={true}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </Grid>
    </WidgetWrapper>
  );
};
