import React from "react";
import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { PreviewIcon } from "../../../../components/Icons/PreviewIcon";
import { DeleteIcon } from "../../../../components";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  })
);

const MyReportsTable = ({
  tableData,
  handleDeleteReport,
}: {
  tableData: any;
  handleDeleteReport: any;
}) => {
  const classes = useStyles();
  const history = useHistory();

  const columns = [
    {
      name: "reportName",
      label: "Name",
    },
    {
      name: "reportOwner",
      label: "Created By",
    },
    {
      name: "eventDate",
      label: "Last Updated",
    },
    {
      name: "popularity",
      label: "Popularity",
    },

    {
      name: "Status",
      options: {
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <Box
              width={60}
              borderRadius={5}
              color="white"
              style={{ backgroundColor: "#2C7E08" }}
              textAlign="center"
            >
              <Typography variant="subtitle1">NEW</Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "Action",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          const { _id } = tableData[dataIndex];
          return (
            <div className={classes.root}>
              <IconButton
                aria-label="visibility"
                onClick={() => {
                  history.push(`/reports/${_id}`);
                }}
                data-tour="view-report"
              >
                <PreviewIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleDeleteReport.mutate(_id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const options: any = {
    elevation: 0,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    pagination: false,
    search: false,
    selectableRows: "none",
    setRowProps: () => {
      return {
        style: {
          cursor: "pointer",
        },
      };
    },
    draggableColumns: {
      enabled: true,
    },
    fixedHeader: true,
    fixedSelectColumn: true,
  };

  return (
    <Box
      style={{
        borderTop: "1px solid #0000001F",
        borderRight: "1px solid #0000001F",
        borderLeft: "1px solid #0000001F",
      }}
    >
      <MUIDataTable
        title={""}
        data={tableData}
        columns={columns}
        options={options}
      />
    </Box>
  );
};

export default MyReportsTable;
