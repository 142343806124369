import React, { useState } from "react";
import { format, parseISO } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { green, grey, orange, red } from "@material-ui/core/colors";
import { Loop } from "@material-ui/icons";
import { PricingWorkflowComment, WorkflowUser } from "../../../../common/types";
import { useAuth } from "../../../../providers";
import { useMyTasks } from "../MyTasks";
import UserSelect from "./UserSelect";
import TeamSelect from "./TeamSelect";
import { euroFormat } from "../../../../helpers";

const useStyles = makeStyles({
  dateLabel: {
    background: "#fff",
    fontSize: "0.9vw",
    left: "9px",
    padding: "0 5px",
    position: "absolute",
    top: "-6px",
    zIndex: 1,
  },
  description: {
    margin: "10px 0",
  },
  formControl: {
    margin: "10px 0",
    width: "100%",
  },
  ragStatus: {
    borderRadius: "0.9vw",
    display: "inline-block",
    height: "0.9vw",
    marginRight: "2px",
    width: "0.9vw",
  },
  taskName: {
    fontSize: "1.2vw",
  },

  commentsContainer: {
    maxHeight: "Calc(100vh - 220px)",
    overflowY: "scroll",
  },
  comments: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    paddingRight: 16,
    overflowY: "scroll",
    height: 670,
  },
  commentContainer: {
    borderBottom: `1px solid ${grey[300]}`,
    marginBottom: "10px",

    "&:last-child": {
      borderBottom: 0,
    },
    "& header strong": {
      fontWeight: 500,
    },
  },
  comment: {
    margin: "0",
    paddingBottom: "10px",
  },
  commentText: {
    marginBottom: "5px",
    whiteSpace: "pre-line",
  },
  subtitle1: {
    padding: "5px",
  },
  body1: {
    padding: "5px",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(360deg)" },
    "100%": { transform: "rotate(0deg)" },
  },
  spinner: {
    animation: "$spin 1s linear infinite",
  },
  inactive: {
    backgroundColor: grey[500],
  },
  active: {
    backgroundColor: orange[500],
  },
  completed: {
    backgroundColor: green[500],
  },
  approved: {
    backgroundColor: green[500],
  },
  rejected: {
    backgroundColor: red[500],
  },
});

const TaskDetailsOptions = () => {
  const {
    selectedTask,
    internalTaskState,
    setInternalTaskState,
  } = useMyTasks();

  switch (selectedTask?.taskName) {
    case "Portfolio Management Fee":
    case "Operations Fee":
    case "AMX Fee":
    case "Tax Transparent Savings":
    case "Market Cpty Savings":
    case "Reference Fund":
      return (
        <FormControl
          style={{ paddingTop: "10px" }}
          fullWidth
          size="small"
          variant="outlined"
          title="Status"
        >
          <Select
            labelId="user-select-label"
            id="user-select"
            value={
              (internalTaskState.status === undefined
                ? selectedTask.status
                : internalTaskState.status) || ""
            }
            variant="outlined"
            onChange={(event: any) => {
              setInternalTaskState((prev: any) => ({
                ...prev,
                status: event.target.value,
              }));
            }}
            required={true}
          >
            <MenuItem value="Draft">Draft</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
          </Select>
        </FormControl>
      );
    case "O&IO Approval":
    case "Product Approval":
    case "Compliance Approval":
      return (
        <FormControl
          style={{ paddingTop: "10px" }}
          fullWidth
          size="small"
          variant="outlined"
          title="Status"
        >
          <Select
            labelId="user-select-label"
            id="user-select"
            value={
              (internalTaskState.status === undefined
                ? selectedTask.status
                : internalTaskState.status) || ""
            }
            variant="outlined"
            onChange={(event: any) => {
              setInternalTaskState((prev: any) => ({
                ...prev,
                status: event.target.value,
              }));
            }}
            required={true}
          >
            <MenuItem value="Draft">Draft</MenuItem>
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
          </Select>
        </FormControl>
      );

    default:
      return null;
  }
};

const TaskDialogDetails = () => {
  const {
    dateFormat,
    selectedTask,
    internalTaskState,
    setInternalTaskState,
    teams,
    users,
  } = useMyTasks();
  const [text, setText] = useState<string>("");
  const classes = useStyles();
  const { axiosWithAuth } = useAuth();

  const queryClient = useQueryClient();
  const createComment = useMutation(
    (text: string) => {
      return axiosWithAuth!({
        url: `pricing-workflow/${selectedTask.parentId}/${selectedTask._id}/comment`,
        method: "POST",
        data: { text },
      });
    },
    {
      onSuccess: () => {
        setText("");
        queryClient.invalidateQueries("task");
      },
    }
  );

  const passUsers = () => {
    const id =
      internalTaskState.assignedTeam?.id === undefined
        ? selectedTask.assignedTeam?.id
        : internalTaskState.assignedTeam?.id;

    return teams[id]?.members ?? users;
  };

  return (
    <Grid container spacing={4}>
      <React.Fragment>
        <Grid item md={6}>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            Comments
          </Typography>
          <Divider />
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              fullWidth
              label="Comment"
              value={text}
              variant="outlined"
              inputProps={{
                maxLength: 10000,
              }}
              multiline
              rows={3}
              rowsMax={15}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setText(event.target.value)
              }
              required={true}
            />
          </FormControl>

          <Button
            style={{ marginLeft: "auto", display: "block" }}
            disabled={createComment.isLoading || text === ""}
            onClick={async () => {
              createComment.mutate(text);
            }}
          >
            {createComment.isLoading ? (
              <Loop className={classes.spinner} />
            ) : (
              "Submit Comment"
            )}
          </Button>

          <Typography variant="subtitle1" className={classes.subtitle1}>
            Comments History
          </Typography>
          <Divider style={{ marginBottom: "10px" }} />
          {selectedTask?.comments && selectedTask.comments.length > 0 ? (
            <ul className={classes.comments}>
              {selectedTask.comments.map((comment: PricingWorkflowComment) => {
                const date =
                  comment.date instanceof Date
                    ? comment.date
                    : parseISO(comment.date);
                return (
                  <li className={classes.commentContainer} key={comment._id}>
                    <blockquote className={classes.comment}>
                      <header>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="body2" gutterBottom>
                            <strong>{comment.name}</strong>
                          </Typography>
                          <Typography variant="caption">
                            {format(date, `${dateFormat} HH:mm:ss`)}
                          </Typography>
                        </Box>
                      </header>
                      <Typography
                        className={classes.commentText}
                        variant="body1"
                      >
                        {comment.text}
                      </Typography>
                    </blockquote>
                  </li>
                );
              })}
            </ul>
          ) : (
            <Alert severity="info">
              <AlertTitle>No comments to display</AlertTitle>
            </Alert>
          )}
        </Grid>

        <Grid item md={6}>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            Parent Fund Name
          </Typography>
          <Divider />
          <Typography variant="body1" className={classes.body1}>
            {selectedTask?.parentDisplayName}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            Parent Workflow Item
          </Typography>
          <Divider />
          <Typography
            style={{
              borderBottom: "rgba(196, 16, 160, 1)",
              color: "rgba(196, 16, 160, 1)",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                `/utilities/pricing-workflow?id=${selectedTask?.parentId}`
              );
            }}
            variant="body1"
            className={classes.body1}
          >
            {selectedTask?.parentRef}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            Assigned Date
          </Typography>
          <Divider />
          <Typography variant="body1" className={classes.body1}>
            {euroFormat(selectedTask?.assignedDate)}
          </Typography>
          <Typography variant="subtitle1" className={classes.body1}>
            Assigned Team
          </Typography>
          <Divider />
          <TeamSelect
            className={classes.formControl}
            onTeamChange={(newAssignedTeam: any) => {
              setInternalTaskState((prev: any) => ({
                ...prev,
                assignedTeam: {
                  name: newAssignedTeam.name,
                  id: newAssignedTeam?.id,
                },
                assignee: { name: "", id: "" },
              }));
            }}
            value={
              (internalTaskState.assignedTeam?.id === undefined
                ? selectedTask.assignedTeam?.id
                : internalTaskState.assignedTeam?.id) || ""
            }
            variant="outlined"
          />
          <Typography variant="subtitle1" className={classes.body1}>
            Assigned User
          </Typography>
          <Divider />
          <UserSelect
            label="Assignee"
            className={classes.formControl}
            users={passUsers()}
            onUserChange={async (newAssignee: WorkflowUser) => {
              setInternalTaskState((prev: any) => ({
                ...prev,
                assignee: {
                  name: newAssignee?.name,
                  id: newAssignee?.id,
                },
              }));
            }}
            value={
              (internalTaskState.assignee?.id === undefined
                ? selectedTask.assignee?.id
                : internalTaskState.assignee?.id) || ""
            }
            variant="outlined"
          />

          <Typography variant="subtitle1" className={classes.body1}>
            Status
          </Typography>
          <Divider />
          <TaskDetailsOptions />
        </Grid>
      </React.Fragment>
    </Grid>
  );
};

export default TaskDialogDetails;
