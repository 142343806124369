import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";

export const AmxTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      width: "100%",
      backgroundColor: "rgba(196, 16, 160, 1)",
    },
  },
  root: {
    backgroundColor: "#f7f9fc",
  },
})((props: any) => (
  <Tabs
    variant="fullWidth"
    indicatorColor="secondary"
    textColor="secondary"
    value={window.location.pathname}
    data-tour="fund-detail-tabs"
    TabIndicatorProps={{ children: <div /> }}
    {...props}
  />
));

export const AmxTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: "rgba(196, 16, 160, 1)",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      opacity: 0.4,
      "&:focus": {
        opacity: 1,
      },
    },
    selected: {
      backgroundColor: "white",
    },
  })
)((props: any) => <Tab component={Link} {...props} />);
