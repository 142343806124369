import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useQueryParams } from "../../helpers/useQueryParams";
import { useAuth } from "../../providers";

const useStyles = makeStyles((theme) => ({
  summaryTitle: {
    fontSize: "0.9375rem",
    color: "#737373",
  },
  accordionDetail: {
    display: "block",
    border: "1px solid #b6b6b6",
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
  },
  serviceItem: {
    height: 160,
    width: 160,
    margin: 10,
    backgroundColor: "#f6f6f6",
  },
  card: {
    width: "100%",
    height: "100%",
    borderWidth: "2px",
  },
  cardSelected: {
    width: "100%",
    height: "100%",
    borderWidth: "2px",
    borderColor: "#c410a0",
    borderStyle: "solid",
  },
  cardDefault: {
    width: "100%",
    height: "100%",
    borderWidth: "2px",
    borderColor: "#9e9e9e",
    borderStyle: "dotted",
  },
  cardButton: {
    width: "100%",
    height: "100%",
  },
  image: {
    width: "100%",
  },
}));

const AppsAndServices = (props: {
  categories: any[];
  onServiceClick: Function;
}) => {
  const { categories, onServiceClick } = props;
  const { axiosWithAuth } = useAuth();

  const query = useQueryParams();
  const section = query.get("section");

  const classes = useStyles();

  const summaryStats = {
    inUse: categories.reduce((acc, category) => acc + category.using, 0),
    available: categories.reduce(
      (acc, category) => acc + category.available,
      0
    ),
    providers: 33,
  };

  useEffect(() => {
    if (section !== undefined) {
    }
  }, [section, categories]);

  return (
    <Paper data-tour="store-apps-and-services" data-trackable-context="Store">
      <Box p={2}>
        <Typography
          variant="h6"
          component="h3"
          className={classes.summaryTitle}
        >
          Services in Use <b>{summaryStats.inUse}</b>&nbsp; | &nbsp;Services
          Available <b>{summaryStats.available}</b>&nbsp; | &nbsp;Number of
          Service Providers <b>{summaryStats.providers}</b>
        </Typography>
      </Box>
      {categories.map((category, categoryIndex) => {
        const sectionName = category.serviceDomainName
          .split(" ")
          .join("-")
          .toLowerCase();

        return (
          <Accordion
            key={categoryIndex}
            defaultExpanded={section === sectionName}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={category.serviceDomainName}
              id={category.serviceDomainName}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h6" component="h3">
                    {category.serviceDomainName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    component="h3"
                    align="right"
                    className={classes.summaryTitle}
                  >
                    Using {category.using} of {category.available} services
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>

            <AccordionDetails className={classes.accordionDetail}>
              {category.subServiceDomains.map(
                (subServiceDomain: any, ssIndex: number) => (
                  <Grid
                    container
                    key={`${category.serviceDomainName}-${subServiceDomain.subServiceDomainName}-${ssIndex}`}
                  >
                    <Grid item xs={3}>
                      <Grid
                        container
                        justify="center"
                        direction="column"
                        className={classes.cardButton}
                      >
                        <Typography variant="h6" component="h3" align="center">
                          {subServiceDomain.subServiceDomainName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container>
                        {subServiceDomain.providers.map(
                          (item: any, providerIndex: number) => (
                            <Grid
                              item
                              key={`${category.serviceDomainName}-${subServiceDomain.subServiceDomainName}-${item.itemName}`}
                              className={classes.serviceItem}
                            >
                              <Card
                                className={
                                  item.default
                                    ? classes.cardDefault
                                    : item.selected
                                    ? classes.cardSelected
                                    : classes.card
                                }
                                data-trackable="Store Item"
                                data-trackable-params-item={item.itemName}
                                data-trackable-params-subServiceDomain={
                                  subServiceDomain.subServiceDomainName
                                }
                                data-trackable-params-category={
                                  category.serviceDomainName
                                }
                              >
                                <CardActionArea
                                  className={classes.cardButton}
                                  onClick={() =>
                                    onServiceClick(
                                      providerIndex,
                                      ssIndex,
                                      categoryIndex,
                                      item,
                                      category
                                    )
                                  }
                                >
                                  {item.image ? (
                                    <CardMedia
                                      component="img"
                                      image={item.image}
                                      title={item.itemName}
                                      className={classes.image}
                                    />
                                  ) : (
                                    <CardContent>
                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        align="center"
                                      >
                                        {item.itemName}
                                      </Typography>
                                    </CardContent>
                                  )}
                                </CardActionArea>
                              </Card>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}
            </AccordionDetails>
            <div></div>
          </Accordion>
        );
      })}
    </Paper>
  );
};

export default AppsAndServices;
