import React, { useEffect, useState } from "react";
import { DocumentsSidebar, DocumentsMain, Documents } from "./Documents";
import { DocumentMetadataType, UserBehaviour } from "../../../../common/types";
import { format } from "date-fns";
import { useAuth } from "../../../../providers";
import { Box, LinearProgress } from "@material-ui/core";

export const MyDocuments = (props: {
  userBehaviour: UserBehaviour | undefined;
  associatedInvestorId: string | number | undefined;
  fundIds: string | undefined;
  advisorIds: string | undefined;
  userGroups: string[];
}) => {
  const { axiosWithAuth } = useAuth();
  const {
    userBehaviour = undefined,
    fundIds,
    userGroups = ["documents-admin"],
  } = props;

  const userType = 1;

  const [status, setStatus] = useState<string>("idle");

  const [docTypes, setDocTypes] = useState<string[]>([]);
  const [docs, setDocs] = useState<DocumentMetadataType[]>([]);

  const investorId = "1011";

  useEffect(() => {
    const getDocuments = async () => {
      if (axiosWithAuth === undefined) return;

      setStatus("pending");

      const documents: DocumentMetadataType[] = await axiosWithAuth({
        method: "POST",
        url: "/documents",
        data: {
          userType,
          investorId: "1011",
          fundIds: undefined,
        },
      });

      // Filter unique doc types
      const filteredDocTypes = [
        ...new Set(
          documents.map((doc: DocumentMetadataType) => doc.documentTypeName)
        ),
      ];

      // The preferred order of the sidebar doctypes.
      // Check if doctypes exist in the unique filteredDoctypes above
      const docTypesOrder = [
        "Factsheet",
        "Contract Notes",
        "QIR",
        "Investment Statement",
        "Board Meeting Manager",
      ].filter((docType) => filteredDocTypes.includes(docType));

      const sortedFilteredDocTypes = [
        ...docTypesOrder,
        ...filteredDocTypes
          .filter((docType) => !docTypesOrder.includes(docType))
          .sort(),
      ];

      const filteredDocs = documents.map((doc: DocumentMetadataType) => ({
        ...doc,
        documentCreatedDatetime: format(
          new Date(doc.documentActiveFromDate),
          "dd-MMM-yyyy"
        ),
      }));

      setDocTypes(sortedFilteredDocTypes);
      setDocs(filteredDocs);

      setStatus("resolved");
    };
    getDocuments();
  }, [investorId, userBehaviour, axiosWithAuth, fundIds, userType]);

  if (status !== "resolved") return <LinearProgress />;

  return (
    <Box py={5} style={{ backgroundColor: "#f7f9fc" }}>
      <Documents docs={docs} setDocs={setDocs} userGroups={userGroups}>
        <DocumentsSidebar docTypes={docTypes} />
        <DocumentsMain
          investorId={investorId}
          userType={userType}
          userBehaviour={userBehaviour}
        />
      </Documents>
    </Box>
  );
};
