import React, { useState } from "react";

const images = [
  "/images/operations-oversight.png",
  "/images/sla-and-file-validation-1.png",
  "/images/sla-and-file-validation-2.png",
];

const DataOperations = () => {
  const [currentImage, setCurrentImage] = useState<number>(0);

  return (
    <div style={{ overflow: "scroll" }}>
      <img
        src={images[currentImage]}
        alt=""
        style={{
          cursor: "pointer",
          display: "block",
        }}
        onClick={() => setCurrentImage(currentImage < 2 ? currentImage + 1 : 0)}
      />
    </div>
  );
};

export default DataOperations;
