import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "typeface-roboto";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  AuthContextProvider,
  NotificationsProvider,
  ThemeProvider,
  ServiceContextProvider,
} from "./providers";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

axios("/config.json").then(({ data: config }) => {
  ReactDOM.render(
    <AuthContextProvider config={config}>
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider>
            <NotificationsProvider>
              <ServiceContextProvider>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </ServiceContextProvider>
            </NotificationsProvider>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </AuthContextProvider>,
    document.getElementById("root")
  );
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
