import React from "react";
import { SvgIcon } from "@material-ui/core";
import styled from "styled-components";

export const LogoInner = () => {
  return (
    <>
      <path
        d="M149.02 80.1498C149.02 72.0319 153.889 65.7344 162.106 65.7344C168.559 65.7344 172.461 69.4423 173.21 74.5462H169.812C169.154 71.104 166.409 68.7483 162.043 68.7483C155.975 68.7483 152.577 73.6539 152.577 80.1498C152.577 86.9113 156.324 91.4719 162.095 91.4719C167.313 91.4719 169.784 87.9028 170.133 83.8577H173.571C173.464 86.7697 172.28 89.5382 170.248 91.6266C168.317 93.5182 165.489 94.5216 161.98 94.5216C154.123 94.5255 149.02 88.5333 149.02 80.1498Z"
        fill="#34323A"
      />
      <path
        d="M176.419 80.1498C176.419 71.8773 181.598 65.7344 189.942 65.7344C198.286 65.7344 203.469 71.8773 203.469 80.1498C203.469 88.4223 198.286 94.5612 189.958 94.5612C181.63 94.5612 176.419 88.4183 176.419 80.1498ZM199.912 80.1498C199.912 73.6539 196.434 68.7483 189.942 68.7483C183.45 68.7483 179.972 73.6539 179.972 80.1498C179.972 86.6456 183.45 91.5076 189.942 91.5076C196.434 91.5076 199.912 86.6417 199.912 80.1498Z"
        fill="#34323A"
      />
      <path
        d="M207.602 66.3691H211.274L223.873 85.5751C224.797 87.0028 225.88 89.1443 225.88 89.1443H225.959V66.3691H229.219V93.9983H225.65L212.959 74.7368C212.071 73.3845 210.909 71.2985 210.909 71.2985H210.834V93.9825H207.586L207.602 66.3691Z"
        fill="#34323A"
      />
      <path
        d="M234.374 66.3691H238.042L250.633 85.5593C251.561 86.9869 252.644 89.1284 252.644 89.1284H252.719V66.3691H255.967V93.9983H252.398L239.708 74.7527C238.82 73.4004 237.662 71.3144 237.662 71.3144H237.582V93.9983H234.374V66.3691Z"
        fill="#34323A"
      />
      <path
        d="M261.159 66.3691H280.675V69.4227H264.637V78.1473H279.207V81.082H264.637V90.7821H281.139V93.9904H261.159V66.3691Z"
        fill="#34323A"
      />
      <path
        d="M283.573 80.1498C283.573 72.0319 288.439 65.7344 296.66 65.7344C303.112 65.7344 307.015 69.4423 307.764 74.5462H304.362C303.703 71.104 300.963 68.7483 296.593 68.7483C290.529 68.7483 287.119 73.6539 287.119 80.1498C287.119 86.9113 290.866 91.4719 296.636 91.4719C301.855 91.4719 304.326 87.9028 304.675 83.8577H308.113C308.006 86.7697 306.822 89.5382 304.79 91.6266C302.859 93.5182 300.031 94.5216 296.521 94.5216C288.673 94.5255 283.573 88.5333 283.573 80.1498Z"
        fill="#34323A"
      />
      <path
        d="M309.326 66.3691H331.177V69.4227H321.981V94.0102H318.503V69.4227H309.326V66.3691Z"
        fill="#34323A"
      />
      <path
        d="M112.71 93.9742L122.129 93.9822L140.962 66.2261H131.539L112.71 93.9742Z"
        fill="#B9B8BA"
      />
      <path
        d="M199.139 0.93457L189.709 0.958365L169.991 30.5149L189.614 59.6035L199.032 59.5956L179.43 30.5427L199.139 0.93457Z"
        fill="#C410A0"
      />
      <path
        d="M98.2825 42.6301C98.2825 42.6301 117.179 15.2388 117.27 15.0604C117.27 15.8535 117.27 15.9566 117.27 59.6589H125.17V1.01379H117.635L96.3155 31.8235L95.7881 33.2789L95.2408 31.7799L74.0083 1.00586H66.4219V59.6589H74.3137V14.993C74.3137 15.0644 93.3491 42.6301 93.3491 42.6301H98.2825Z"
        fill="#C410A0"
      />
      <path
        d="M26.8519 0.96582L0 59.6427H8.61752L13.0869 49.9068H36.1833L39.3955 42.6099H16.4221L28.815 15.4407L29.3107 13.8822L29.7866 15.4011L50.0038 59.6427H58.6055L31.7377 0.96582H26.8519Z"
        fill="#C410A0"
      />
      <path
        d="M136.116 0.93457L155.838 30.5427L136.235 59.5956L145.654 59.6035L165.276 30.5149L145.547 0.958365L136.116 0.93457Z"
        fill="#C410A0"
      />
    </>
  );
};

/**
 * @param {SvgIconProps} props
 */
export const LogoIcon = (props: any) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 300 97.2"
      width="131"
      height="31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <LogoInner />
    </SvgIcon>
  );
};

export const AmxLogo = styled(LogoIcon)`
  height: 37px;
  width: 140px;
  padding-left: 20px;
  .amx-logo {
    padding-left: 20px;
  }
`;

/**
 * @param {SvgIconProps} props
 */
const Logo = (props: any) => {
  return (
    <svg
      {...props}
      className="amx-logo"
      viewBox="0 0 300 97.2"
      width="131"
      height="31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <LogoInner />
    </svg>
  );
};

export default Logo;
