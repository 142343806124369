import React from "react";
import { FormControl, MenuItem, Select, InputLabel } from "@material-ui/core";
import { useMyTasks } from "../MyTasks";

const TeamSelect = (props: {
  className?: string;
  disabled?: boolean;
  label?: string;
  onTeamChange: Function;
  title?: string;
  value?: string;
  variant?: "filled" | "standard" | "outlined";
}) => {
  const {
    className,
    disabled,
    label,
    onTeamChange,
    title,
    value,
    variant,
  } = props;
  const { teams } = useMyTasks();

  return (
    <FormControl
      fullWidth
      size="small"
      className={className || ""}
      variant={variant}
      title={title}
    >
      <React.Fragment>
        {label ? <InputLabel id="user-select-label">{label}</InputLabel> : null}
        <Select
          disabled={disabled}
          label={label}
          labelId="user-select-label"
          id="user-select"
          value={value}
          variant={variant}
          onChange={(event: any) => {
            const value =
              event.target.value === ""
                ? { name: "", id: "" }
                : {
                    name: teams[event.target.value].name,
                    id: event.target.value,
                  };

            onTeamChange(value);
          }}
          required={true}
        >
          <MenuItem value="">
            <em>none</em>
          </MenuItem>
          {Object.entries(teams).map(([teamId, team]: [string, any]) => (
            <MenuItem key={teamId} value={teamId}>
              {team.name}
            </MenuItem>
          ))}
        </Select>
      </React.Fragment>
    </FormControl>
  );
};

export default TeamSelect;
