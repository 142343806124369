import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { View } from "../../components";
import { Redirect, Route, useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { Paper, Typography } from "@material-ui/core";
import Users from "./components/users/Users";
import { UsersIcon } from "../../components/Icons/UsersIcon";
import { AccountsIcon } from "../../components/Icons/AccountsIcon";
import Accounts from "./components/accounts/Accounts";

export const Settings = () => {
  const location = useLocation();

  return (
    <View>
      <Paper style={{ padding: 30 }}>
        <Typography variant="h3">Profile</Typography>
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          style={{ width: "30%", marginTop: 15 }}
        >
          <Tabs
            value={location.pathname}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              value={
                location.pathname === "/settings"
                  ? "/settings"
                  : "/settings/users"
              }
              label="Users"
              icon={<UsersIcon />}
              component={Link}
              to="/settings/users"
            />
            <Tab
              value="/settings/accounts"
              label="Accounts"
              icon={<AccountsIcon />}
              component={Link}
              to="/settings/accounts"
            />
          </Tabs>
        </AppBar>
        <Switch>
          <Route path="/settings/users" render={() => <Users />} />
          <Route path="/settings/accounts" render={() => <Accounts />} />
          <Redirect from="/settings" to="/settings/users" />
        </Switch>
      </Paper>
    </View>
  );
};
