import {
  Paper,
  Box,
  TextField,
  Button,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../providers";

export const CreateReportModal = ({
  openModal,
  setOpenModal,
  selectedReport,
  selectedOptions,
  reportType,
}: {
  openModal: boolean;
  selectedOptions: {
    selectedFunds: string | string[] | number | number[];
    selectedDatapoints: string | string[] | number | number[];
  };
  selectedReport: string;
  setOpenModal: Function;
  reportType: string;
}) => {
  const [reportNameValue, setReportNameValue] = useState("");
  const [submitStatus, setSubmitStatus] = useState<string>("idle");
  const { axiosWithAuth } = useAuth();
  const history = useHistory();

  const handleSaveSubmit = async () => {
    if (axiosWithAuth === undefined) return;

    setSubmitStatus("loading");

    try {
      await axiosWithAuth({
        method: "PUT",
        url: `/data-library/${selectedReport}-${reportType}/favourite`,
        data: {
          reportName: reportNameValue,
          ...selectedOptions,
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitStatus("idle");
      setOpenModal(false);
      history.push("/explorer/reports");
    }
  };

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="report-name-modal"
      aria-describedby="report-name-modal"
    >
      <Paper
        style={{
          padding: "30px",
          textAlign: "center",
          minWidth: "30%",
        }}
      >
        <h2 id="simple-modal-title">Report Name</h2>
        <p id="simple-modal-description">
          Please provide a name for your report
        </p>
        <form noValidate autoComplete="off">
          <Box mb={5}>
            <TextField
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setReportNameValue(event.target.value)
              }
              id="report-name-basic"
              label="Report Name"
            />
          </Box>
          <Box>
            <Button
              disabled={!reportNameValue || submitStatus === "loading"}
              variant="contained"
              style={{ marginRight: "10px" }}
              onClick={handleSaveSubmit}
            >
              Submit
              {submitStatus === "loading" ? (
                <CircularProgress size={15} style={{ marginLeft: "10px" }} />
              ) : null}
            </Button>

            <Button
              onClick={() => {
                setReportNameValue("");
                setOpenModal(false);
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Paper>
    </Modal>
  );
};
