import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Typography, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * AMX Connect AppPanel Component
 * @component
 */

export const StorePanelBasic = (props: any) => {
  const {
    type = "INVESTMENT OPERATIONS",
    company = "See All",
    // url = "#", not yet needed
  } = props;

  const useStyles = makeStyles((theme) => ({
    card: {
      boxShadow: "0 2px 2px -2px #555",
      position: "relative",
      padding: "0.8em 0.8em 3em",
      display: "flex",
      flexDirection: "column",

      "&:hover": {
        background: "#f5f5f5",
        cursor: "pointer",
      },

      textDecoration: "none",
    },

    type: {
      fontWeight: "bold",
      marginBottom: "0.2em",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    company: {
      color: "#919191",
    },

    arrow: {
      color: "#8b8e94",
      position: "absolute",
      bottom: "0.5em",
      right: "0.5em",
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card
        className={classes.card}
        variant="outlined"
        // component={Link}   Required for final version
        // to={"/store/" + url}
      >
        <Typography component="h4" variant="caption" className={classes.type}>
          {type}
        </Typography>

        {/* <Typography    // removed for a demo
          component="div"
          variant="caption"
          className={classes.typeStatus}
        >
          {company}
        </Typography> */}
        <ArrowForwardIosIcon className={classes.arrow} />
      </Card>
    </React.Fragment>
  );
};
