import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Badge,
  Box,
  Button,
  Grid,
  Popover,
  Typography,
} from "@material-ui/core";
import { UserMenu } from "./UserMenu";
import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
import {
  NotificationIcon,
  ReportIcon,
  SearchIcon,
  SettingsIcon,
} from "../Icons";
import { CustomLogo } from "../CustomLogo";
import { theme } from "../../providers";
import { useHistory } from "react-router-dom";
import { GettingStartedTour } from "../GettingStartedTour";
import { AccountsIcon } from "../Icons/AccountsIcon";
import { UsersIcon } from "../Icons/UsersIcon";

const settingsData = [
  {
    title: "Users",
    description: "Team Members",
    path: "/settings/users",
    icon: <UsersIcon style={{ color: "#2020eb" }} />,
  },
  {
    title: "Accounts",
    description: "Organisation Structure",
    path: "/settings/accounts",
    icon: <AccountsIcon style={{ color: "#2020eb" }} />,
  },
];

const HeaderContainer = styled(Grid)`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  background: white;
  z-index: 1;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  .search-box {
    text-align: right;
  }
`;

const PopoverMenu = styled(Popover)`
  width: 300px;
`;

const useStyles = makeStyles((theme) => ({
  quickStartButton: {
    backgroundColor: "#bcbcbc",
    textTransform: "capitalize",
    padding: "0.5em 1em",
    fontSize: "0.35em",
    borderRadius: "20px",
    color: "#fff",
    "&:hover": {
      background: "#555",
      color: "#fff",
    },
  },
}));
/**
 * AMX Connect Header Component
 * @component
 */
export const Header = (props: any) => {
  const history = useHistory();
  const { onUserMenuSelect, initials } = props;

  const [
    openNotification,
    setOpenNotification,
  ] = React.useState<null | HTMLElement>(null);

  const handleClickNotification = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setOpenNotification(openNotification ? null : event.currentTarget);
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const [openSettings, setOpenSettings] = React.useState<null | HTMLElement>(
    null
  );
  const handleClickSettings = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setOpenSettings(openSettings ? null : event.currentTarget);
  };
  const handleCloseSettings = () => {
    setOpenSettings(null);
  };

  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showWelcome, setShowWelcome] = useState<boolean>(
    !localStorage.getItem("seenWelcome")
  );

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const iconSpacing = {
    margin: `0 ${theme.spacing(1)}`,
  };

  return (
    <HeaderContainer container>
      <Grid
        item
        xs={4}
        style={{ paddingLeft: theme.spacing(2), textAlign: "left" }}
      >
        <CustomLogo maxHeight="37px" />
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" alignItems="center" justifyContent="center">
          {props.children}
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleClickOpen();
            }}
            className={classes.quickStartButton}
          >
            Quick Start Guide
          </Button>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box alignItems="center" display="flex" justifyContent="flex-end">
          <SearchIcon style={iconSpacing} />
          <Badge
            // badgeContent={2}
            color="primary"
            onClick={handleClickNotification}
            style={{ position: "relative" }}
          >
            <NotificationIcon
              style={iconSpacing}
              data-tour="header-notifications"
            />
            <PopoverMenu
              style={{ minWidth: "260ch" }}
              open={Boolean(openNotification)}
              anchorEl={openNotification}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              disableRestoreFocus
            >
              <Typography
                variant="h6"
                style={{
                  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                }}
              >
                <strong>Notifications</strong>
              </Typography>
              {["RLR YE20 valuation reports", "Q420 valuation report"].map(
                (title, i) => (
                  <Grid
                    container
                    key={`header-item-${i}`}
                    style={{
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      cursor: "pointer",
                      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                    }}
                    onClick={() => {
                      history.push("/connect/messages");
                    }}
                  >
                    <Grid item xs={2}>
                      <Box alignItems="center" display="flex">
                        <ReportIcon style={{ height: "32px", width: "32px" }} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      style={{ paddingLeft: theme.spacing(1) }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          width: "300px",
                        }}
                      >
                        <strong>{title}</strong>
                        <br />
                        Please review and complete
                      </Typography>
                    </Grid>
                  </Grid>
                )
              )}
              <Button
                variant="text"
                disableElevation
                style={{
                  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                  textTransform: "none",
                  width: "100%",
                }}
              >
                Mark all as read
              </Button>
            </PopoverMenu>
          </Badge>
          <Badge
            // badgeContent={2}
            color="primary"
            onClick={handleClickSettings}
            style={{ position: "relative" }}
          >
            <SettingsIcon
              data-tour="header-settings"
              style={{ ...iconSpacing, marginRight: theme.spacing(2) }}
            />
            <PopoverMenu
              style={{ minWidth: "260ch" }}
              open={Boolean(openSettings)}
              anchorEl={openSettings}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              disableRestoreFocus
            >
              <Typography
                variant="h6"
                style={{
                  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                }}
              >
                <strong>Settings</strong>
              </Typography>
              {settingsData.map((item, i) => (
                <Grid
                  container
                  key={`header-item-${i}`}
                  style={{
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    cursor: "pointer",
                    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                  }}
                  onClick={() => {
                    history.push(item.path);
                  }}
                >
                  <Grid item xs={2}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="center"
                      height="100%"
                    >
                      {item.icon}
                      {/* <ReportIcon style={{ height: "32px", width: "32px" }} /> */}
                    </Box>
                  </Grid>
                  <Grid item xs={10} style={{ paddingLeft: theme.spacing(1) }}>
                    <Typography
                      variant="h6"
                      style={{
                        width: "300px",
                      }}
                    >
                      <strong>{item.title}</strong>
                      <br />
                      {item.description}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
              <Box height={30} />
            </PopoverMenu>
          </Badge>

          {initials && onUserMenuSelect && (
            <UserMenu
              data-tour="header-user-menu"
              onSelect={onUserMenuSelect}
              initials={initials}
            />
          )}
        </Box>
      </Grid>

      <GettingStartedTour
        openDialog={openDialog}
        handleClose={handleClose}
        showWelcome={showWelcome}
      />
    </HeaderContainer>
  );
};

Header.propTypes = {
  /**
   * Content that will go between the logo and the user menu.
   */
  children: PropTypes.element,
  /**
   * Callback function that gets called when the user makes a selection on the user menu.
   * @param {number} selectedIndex
   */
  onUserMenuSelect: PropTypes.func,
  /**
   * The user's initials that will be displayed on the RHS.
   */
  initials: PropTypes.string,
};
