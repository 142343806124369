import React, { useState } from "react";
import PropTypes from "prop-types";
import { MenuItem, ListItemText } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { StyledMenu } from "./StyledMenu";

const useStyles = makeStyles(() =>
  createStyles({
    menuButton: {
      paddingRight: "20px",
    },
    circleButton: {
      width: "36px",
      height: "36px",
      borderRadius: "50%",
      padding: "10px 0",
      "-ms-flex": "0 0 auto",
      flex: "0 0 auto",
      display: "inline-flex",
      justifyContent: "center",
      verticalAlign: "middle",
      border: "0",
      cursor: "pointer",
      position: "relative",
      outline: "0",
      boxShadow: "0px 2px 5px -1px rgba(0, 0, 0, 0.15)",
    },
    initials: {
      height: "20px",
      fontSize: "16px",
      color: "hsla(317, 51%, 8%, 1)",
    },
  })
);

export const UserMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState();
  const initials = props.initials || "";
  const classes = useStyles();

  /** @param {number} selectedIndex */
  const handleSelect = (selectedIndex) => {
    setAnchorEl(null);
    props.onSelect(selectedIndex);
  };

  /** @param {React.MouseEvent} event */
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.menuButton}>
      <button
        color="primary"
        onClick={handleOpen}
        className={classes.circleButton}
      >
        <div className={classes.initials}>{initials}</div>
      </button>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelect(0)}>
          <ListItemText primary="Profile" />
        </MenuItem>
        <MenuItem onClick={() => handleSelect(1)}>
          <ListItemText primary="Account" />
        </MenuItem>
        <MenuItem onClick={() => handleSelect(2)}>
          <ListItemText primary="Logout" />
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

UserMenu.propTypes = {
  /**
   * Function that will be called when a menu item is selected
   * @param {number} selectedIndex
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * User Initials that will be displayed in the button
   */
  initials: PropTypes.string.isRequired,
};
