import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const PlusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15.854"
      height="15.854"
      viewBox="0 0 15.854 15.854"
    >
      <path
        id="Path_943"
        d="M31.06,22H28.795v6.795H22V31.06h6.795v6.795H31.06V31.06h6.795V28.795H31.06Z"
        transform="translate(-22 -22)"
        fill="#fff"
      />
    </SvgIcon>
  );
};
