import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const UsersIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="35.198"
      height="19.72"
      viewBox="0 0 35.198 19.72"
      {...props}
    >
      <g id="noun_users_943303" transform="translate(-5 -24.789)">
        <g
          id="Group_7494"
          data-name="Group 7494"
          transform="translate(5 24.789)"
        >
          <path
            id="Path_1023"
            data-name="Path 1023"
            d="M15.747,44.118a4.381,4.381,0,1,0-4.381-4.381A4.385,4.385,0,0,0,15.747,44.118Zm0-6.918a2.537,2.537,0,1,1-2.537,2.537A2.54,2.54,0,0,1,15.747,37.2Z"
            transform="translate(-8.876 -31.223)"
            // fill={props.fill || "#2020eb"}
          />
          <path
            id="Path_1024"
            data-name="Path 1024"
            d="M6.844,66.059a3.417,3.417,0,0,1,3.413-3.413h3.227q.145,0,.287.012a.954.954,0,0,0,.726-.245.9.9,0,0,0,.293-.67.923.923,0,0,0-.82-.917c-.16-.015-.323-.023-.487-.023H10.257A5.262,5.262,0,0,0,5,66.059v.379H6.844v-.379Z"
            transform="translate(-5 -46.718)"
            // fill={props.fill || "#2020eb"}
          />
          <path
            id="Path_1025"
            data-name="Path 1025"
            d="M30.87,62.8h1.844V60.53a3.863,3.863,0,0,1,3.859-3.858h3.558A3.863,3.863,0,0,1,43.99,60.53V62.8h1.844V60.53a5.715,5.715,0,0,0-5.7-5.7H36.573a5.72,5.72,0,0,0-5.7,5.7V62.8Z"
            transform="translate(-20.753 -43.08)"
            // fill={props.fill || "#2020eb"}
          />
          <path
            id="Path_1026"
            data-name="Path 1026"
            d="M74.735,60.8H71.508c-.09,0-.18,0-.269.007a.924.924,0,0,0-.6,1.595.907.907,0,0,0,.679.247c.062,0,.125-.005.188-.005h3.227a3.417,3.417,0,0,1,3.413,3.413v.379h1.844v-.379A5.263,5.263,0,0,0,74.735,60.8Z"
            transform="translate(-44.794 -46.717)"
            // fill={props.fill || "#2020eb"}
          />
          <path
            id="Path_1027"
            data-name="Path 1027"
            d="M70.611,44.118a4.381,4.381,0,1,0-4.381-4.381A4.386,4.386,0,0,0,70.611,44.118Zm0-6.918a2.537,2.537,0,1,1-2.537,2.537A2.54,2.54,0,0,1,70.611,37.2Z"
            transform="translate(-42.284 -31.223)"
            // fill={props.fill || "#2020eb"}
          />
          <path
            id="Path_1028"
            data-name="Path 1028"
            d="M41.42,35.811A5.511,5.511,0,1,0,35.909,30.3,5.517,5.517,0,0,0,41.42,35.811Zm0-9.178A3.667,3.667,0,1,1,37.752,30.3,3.672,3.672,0,0,1,41.42,26.632Z"
            transform="translate(-23.821 -24.789)"
            // fill={props.fill || "#2020eb"}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
