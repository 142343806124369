import React from "react";
import { Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";

const AVAILABLE = "#237db5";
const PURCHASED = "#20954D";

export const BuyButton = (props: any) => {
  const { purchased, rowId, onBuyClick } = props;

  const useStyles = makeStyles((theme) => ({
    addButton: {
      backgroundColor: (props) => (purchased ? PURCHASED : AVAILABLE),
      borderRadius: 20,
      width: "2em",
      padding: "0.6em 0",
      color: "#fff",
      "&:hover": {
        background: "#30305b",
        color: "#fff",
      },
    },
  }));
  const classes = useStyles();

  return (
    <Button
      className={classes.addButton}
      onClick={() => onBuyClick(rowId, purchased)}
    >
      {purchased ? <CheckIcon /> : <span>buy</span>}
    </Button>
  );
};
