import React, { useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StepConnector,
  StepIconProps,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { useAuth } from "../../../../providers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TickIcon } from "../../../../components/Icons/TickIcon";
import { CreateReportIcon } from "../../../../components/Icons/CreateReportIcon";
import { Autocomplete } from "@material-ui/lab";
import { format as dateFormat } from "date-fns";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: 20,
    },
    customButton: {
      backgroundColor: "#7B059F",
      color: "white",
    },
  })
);

const useCustomIconStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#BABABA",
      width: 60,
      height: 60,
      borderRadius: "50%",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      backgroundColor: "#7B059F",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      backgroundColor: "#7B059F",
    },
  })
);

const disabledOptions = [
  "Coverage (Portfolio)",
  "Coverage (Index)",
  "ESG Score (Fund)",
  "ESG Score (Index)",
  "Environment Aggregate Score - E (Fund)",
  "Environmental Transparency -  E2 (Fund)",
  "Resource Efficiency - E3 (Fund)",
  "Governance Aggregate Score - G (Fund)",
  "Board Effectiveness -  G1 (Fund)",
  "Management Ethics - G2 (Fund)",
  "Disclosure & Accountability - G3 (Fund)",
  "Social Aggregate Score - S (Fund)",
  "Employment Aggregate Score -  EMP (Fund)",
  "Compensation & Satisfaction - EMP 1 (Fund)",
  "Education & Work Conditions - EMP 3 (Fund)",
  "Community Aggregate Score - CIT (Fund)",
  "Community & Charity - CIT 1 (Fund)",
  "Human Rights - CIT 2 (Fund)",
  "Sustainability Integration - CIT 3 (Fund)",
  "ESG Score (Index)",
  "ESG Score (Index)",
  "Environment Aggregate Score - E (Index)",
  "Pollution Prevention - E1 (Index)",
  "Environmental Transparency -  E2 (Index)",
  "Resource Efficiency - E3 (Index)",
  "Governance Aggregate Score - G (Index)",
  "Board Effectiveness -  G1 (Index)",
  "Management Ethics - G2 (Index)",
  "Disclosure & Accountability - G3 (Index)",
  "Social Aggregate Score - S (Index)",
  "Employment Aggregate Score -  EMP (Index)",
  "Compensation & Satisfaction - EMP 1 (Index)",
  "Diversity & Rights - EMP 2 (Index)",
  "Education & Work Conditions - EMP 3 (Index)",
  "Community Aggregate Score - CIT (Index)",
  "Community & Charity - CIT 1 (Index)",
  "Human Rights - CIT 2 (Index)",
  "Sustainability Integration - CIT 3 (Index)",
  "Market Capitalization",
  "P/E 12 Month Trailing",
  "Dividend Yield",
  "Price to Cashflow",
  "Five Year Average Cashflow Price",
  "5Year EPS Growth",
];

function DashboardStep(props: StepIconProps, index: number) {
  const classes = useCustomIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {index === 2 ? <TickIcon /> : <CreateReportIcon />}
    </div>
  );
}

const ColorlibConnector = withStyles({
  vertical: {
    padding: 0,
    marginLeft: 0,
  },
  alternativeLabel: {},
  line: {
    marginLeft: 30,
    width: 1,
    height: 50,
    border: 0,
    backgroundColor: "gray",
    borderRadius: 1,
  },
})(StepConnector);

const CreateDashboard = ({
  openDialog,
  setOpenDialog,
  widgetData,
}: {
  openDialog: boolean;
  setOpenDialog: Function;
  widgetData: any;
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDataSource, setSelectedDataSource] = useState<any>();
  const [selectedDataItems, setSelectedDataItems] = useState<any>([]);
  const [selectedDataPoints, setSelectedDataPoints] = useState<
    {
      name: string;
      label: string;
    }[]
  >([]);
  const [reportName, setReportName] = useState("");
  const steps = ["Create Report", "Report Details", "Report Title"];
  const { axiosWithAuth } = useAuth();

  const { data: reports, isLoading: preferencesIsLoading } = useQuery(
    "reports",
    () => {
      return axiosWithAuth!({
        url: "/preferences",
        method: "GET",
      }).then((data) => data.reports);
    }
  );

  const { data: createDashboardData, isLoading } = useQuery(
    "create-dashboard-data",
    async () => {
      const { exposures: esgData } = await axiosWithAuth!({
        url: "/dashboard/inspector/detail/",
        params: {
          filters: { country: [], sector: [], currency: [] },
        },
      });
      const { holdings: fundamentalData } = await axiosWithAuth!({
        url: "/dashboard/inspector/detail/",
        params: {
          options: [{ type: "Country", match: "United States" }],
          filters: { country: [], sector: [], currency: ["USD"] },
        },
      });

      const decoratedData = [
        {
          dataSource: "ESG Data",
          dataPoints: [
            {
              label: "Fund Name",
              name: "fundName",
            },
            {
              label: "Coverage (Portfolio)",
              name: "Coverage (Portfolio)",
            },
            {
              label: "Coverage (Index)",
              name: "Coverage (Index)",
            },
            {
              label: "ESG Score (Fund)",
              name: "ESG Score (Fund)",
            },
            {
              label: "ESG Score (Index)",
              name: "ESG Score (Index)",
            },
            {
              label: "Environment Aggregate Score - E (Fund)",
              name: "Environment Aggregate Score - E (Fund)",
            },
            {
              label: "Pollution Prevention - E1 (Fund)",
              name: "environmentalData",
            },
            {
              label: "Environmental Transparency -  E2 (Fund)",
              name: "Environmental Transparency -  E2 (Fund)",
            },
            {
              label: "Resource Efficiency - E3 (Fund)",
              name: "Resource Efficiency - E3 (Fund)",
            },
            {
              label: "Governance Aggregate Score - G (Fund)",
              name: "Governance Aggregate Score - G (Fund)",
            },
            {
              label: "Board Effectiveness -  G1 (Fund)",
              name: "Board Effectiveness -  G1 (Fund)",
            },
            {
              label: "Management Ethics - G2 (Fund)",
              name: "Management Ethics - G2 (Fund)",
            },
            {
              label: "Disclosure & Accountability - G3 (Fund)",
              name: "Disclosure & Accountability - G3 (Fund)",
            },
            {
              label: "Social Aggregate Score - S (Fund)",
              name: "Social Aggregate Score - S (Fund)",
            },
            {
              label: "Employment Aggregate Score -  EMP (Fund)",
              name: "Employment Aggregate Score -  EMP (Fund)",
            },
            {
              label: "Compensation & Satisfaction - EMP 1 (Fund)",
              name: "Compensation & Satisfaction - EMP 1 (Fund)",
            },
            {
              label: "Diversity & Rights - EMP 2 (Fund)",
              name: "socialData",
            },
            {
              label: "Education & Work Conditions - EMP 3 (Fund)",
              name: "Education & Work Conditions - EMP 3 (Fund)",
            },
            {
              label: "Community Aggregate Score - CIT (Fund)",
              name: "Community Aggregate Score - CIT (Fund)",
            },
            {
              label: "Community & Charity - CIT 1 (Fund)",
              name: "Community & Charity - CIT 1 (Fund)",
            },
            {
              label: "Human Rights - CIT 2 (Fund)",
              name: "Human Rights - CIT 2 (Fund)",
            },
            {
              label: "Sustainability Integration - CIT 3 (Fund)",
              name: "Sustainability Integration - CIT 3 (Fund)",
            },
            {
              label: "ESG Score (Index)",
              name: "ESG Score (Index)",
            },
            {
              label: "ESG Score (Index)",
              name: "ESG Score (Index)",
            },
            {
              label: "Environment Aggregate Score - E (Index)",
              name: "Environment Aggregate Score - E (Index)",
            },
            {
              label: "Pollution Prevention - E1 (Index)",
              name: "Pollution Prevention - E1 (Index)",
            },
            {
              label: "Environmental Transparency -  E2 (Index)",
              name: "Environmental Transparency -  E2 (Index)",
            },
            {
              label: "Resource Efficiency - E3 (Index)",
              name: "Resource Efficiency - E3 (Index)",
            },
            {
              label: "Governance Aggregate Score - G (Index)",
              name: "Governance Aggregate Score - G (Index)",
            },
            {
              label: "Board Effectiveness -  G1 (Index)",
              name: "Board Effectiveness -  G1 (Index)",
            },
            {
              label: "Management Ethics - G2 (Index)",
              name: "Management Ethics - G2 (Index)",
            },
            {
              label: "Disclosure & Accountability - G3 (Index)",
              name: "Disclosure & Accountability - G3 (Index)",
            },
            {
              label: "Social Aggregate Score - S (Index)",
              name: "Social Aggregate Score - S (Index)",
            },
            {
              label: "Employment Aggregate Score -  EMP (Index)",
              name: "Employment Aggregate Score -  EMP (Index)",
            },
            {
              label: "Compensation & Satisfaction - EMP 1 (Index)",
              name: "Compensation & Satisfaction - EMP 1 (Index)",
            },
            {
              label: "Diversity & Rights - EMP 2 (Index)",
              name: "Diversity & Rights - EMP 2 (Index)",
            },
            {
              label: "Education & Work Conditions - EMP 3 (Index)",
              name: "Education & Work Conditions - EMP 3 (Index)",
            },
            {
              label: "Community Aggregate Score - CIT (Index)",
              name: "Community Aggregate Score - CIT (Index)",
            },
            {
              label: "Community & Charity - CIT 1 (Index)",
              name: "Community & Charity - CIT 1 (Index)",
            },
            {
              label: "Human Rights - CIT 2 (Index)",
              name: "Human Rights - CIT 2 (Index)",
            },
            {
              label: "Sustainability Integration - CIT 3 (Index)",
              name: "Sustainability Integration - CIT 3 (Index)",
            },
            {
              label: "Market Capitalization",
              name: "Market Capitalization",
            },
          ],
          dataItemsType: "Fund",
          dataItemsKey: "fundName",
          dataItems: esgData.map((item) => ({
            ...item,
            environmentalData: item.esg.EnvironmentalData[0].Fundvalue,
            socialData: item.esg.SocialData[0].Fundvalue,
          })),
        },
        {
          dataSource: "Fundamental Data",

          dataPoints: [
            {
              label: "Instrument Name",
              name: "InstrumentName",
            },
            {
              label: "PE Ratio",
              name: "PERatio",
            },
            { label: "Market Capitalization", name: "Market Capitalization" },
            { label: "P/E 12 Month Trailing", name: "P/E 12 Month Trailing" },
            { label: "EPS 12 Month Trailing", name: "Trail12MEps" },
            { label: "Dividend Yield", name: "Dividend Yield" },
            { label: "Price to Cashflow", name: "Price to Cashflow" },
            {
              label: "Five Year Average Cashflow Price",
              name: "Five Year Average Cashflow Price",
            },
            { label: "5Year EPS Growth", name: "5Year EPS Growth" },
          ],
          dataItemsKey: "InstrumentName",
          dataItemsType: "Instrument",
          dataItems: fundamentalData,
        },
      ];

      return decoratedData;
    }
  );

  const queryClient = useQueryClient();

  const updateDashboardPreferences = useMutation(
    async (data: any) => {
      return await axiosWithAuth!({
        url: `/preferences`,
        method: "PUT",
        data: {
          dashboards: data,
          reports: [
            ...reports,
            {
              selectedDataSource: selectedDataSource.dataSource,
              dataItemsKey: selectedDataSource.dataItemsKey,
              selectedDataPoints,
              selectedDataItems,
              reportName,
              eventDate: dateFormat(new Date(), "dd-MMM-y"),
            },
          ],
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("dashboards");
      },
    }
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGenerate = () => {
    const currentDashboards: any = queryClient.getQueryData("dashboards");

    const widgetConfig = {
      name: "Reports",
      props: {
        selectedDataSource: selectedDataSource.dataSource,
        dataItemsKey: selectedDataSource.dataItemsKey,
        selectedDataPoints,
        selectedDataItems,
        reportName,
      },
    };

    const updateDashboards = currentDashboards.map((dashboardItem: any) => {
      if (dashboardItem._id === widgetData.dashboardId) {
        dashboardItem.widgets = dashboardItem.widgets.map((widget: any) => {
          if (widget._id === widgetData._id) {
            return {
              ...widget,
              ...widgetConfig,
            };
          }
          return widget;
        });
      }
      return dashboardItem;
    });

    updateDashboardPreferences.mutate(updateDashboards);
  };

  return (
    <Dialog
      open={openDialog}
      maxWidth="lg"
      fullWidth
      onClose={() => setOpenDialog(false)}
    >
      <Box p={5}>
        <Grid container>
          <Grid item xs={3}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={<ColorlibConnector />}
              style={{
                padding: 0,
                height: "fit-content",
              }}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={(props) => DashboardStep(props, index)}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid
            item
            xs={9}
            container
            spacing={4}
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Typography variant="h5">Please select on option</Typography>
            </Grid>
            {activeStep >= 0 && (
              <Grid item xs={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="data-source-select-label">
                    Data Source
                  </InputLabel>
                  <Select
                    labelId="data-source-select-label"
                    id="data-source-select"
                    value={selectedDataSource?.dataSource}
                    disabled={activeStep !== 0}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      const dataSource = createDashboardData?.find(
                        (item) => item.dataSource === event.target.value
                      );
                      setSelectedDataItems([]);
                      setSelectedDataPoints([]);
                      setSelectedDataSource(dataSource);
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      createDashboardData
                        ?.map((item) => item.dataSource)
                        .map((dataSource) => (
                          <MenuItem key={dataSource} value={dataSource}>
                            {dataSource}
                          </MenuItem>
                        ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={6}></Grid>
            {activeStep >= 1 &&
              (isLoading ? (
                <CircularProgress />
              ) : (
                <React.Fragment>
                  <Grid item xs={6}>
                    <Autocomplete
                      multiple
                      disabled={activeStep !== 1}
                      id="data-items-autocomplete"
                      options={selectedDataSource.dataItems.map(
                        (item: any) => item[selectedDataSource.dataItemsKey]
                      )}
                      value={selectedDataItems}
                      getOptionLabel={(option: string) => option}
                      onChange={(event, newValue) => {
                        setSelectedDataItems([...newValue]);
                      }}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={`${selectedDataSource.dataItemsType} Names`}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      multiple
                      disabled={activeStep !== 1}
                      id="data-points-autocomplete"
                      options={selectedDataSource.dataPoints}
                      value={selectedDataPoints}
                      getOptionLabel={(option: any) => option.label}
                      getOptionDisabled={(option) =>
                        disabledOptions.includes(option.name)
                      }
                      onChange={(event, newValue) =>
                        setSelectedDataPoints([...newValue])
                      }
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Data Points"
                        />
                      )}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            {activeStep === 2 && (
              <React.Fragment>
                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                  <Typography style={{ fontWeight: 400 }}>
                    New Report
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                  <TextField
                    fullWidth
                    id="report-name"
                    value={reportName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setReportName(event.target.value)
                    }
                    label="Please provide a report name"
                  />
                </Grid>
                <Grid item xs={6} />
              </React.Fragment>
            )}
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={handleBack}
                style={{
                  visibility: activeStep !== 0 ? "visible" : "hidden",
                }}
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                disableElevation
                className={classes.customButton}
                disabled={
                  activeStep === 0 &&
                  !!selectedDataSource &&
                  !preferencesIsLoading
                    ? false
                    : activeStep === 1 &&
                      selectedDataItems.length > 0 &&
                      selectedDataPoints.length > 0
                    ? false
                    : activeStep === 2 && reportName
                    ? false
                    : true
                }
                onClick={activeStep === 2 ? handleGenerate : handleNext}
                style={{ marginLeft: "auto", display: "block" }}
              >
                {updateDashboardPreferences.isLoading ? (
                  <CircularProgress />
                ) : activeStep === 2 ? (
                  "Generate"
                ) : (
                  "Next"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default CreateDashboard;
