import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Modal,
  Grid,
  Fade,
  Backdrop,
  Paper,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 200,
    minHeight: 200,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 20,
    backgroundColor: "#F7F9FC",
    maxWidth: "70%",
    height: "628px%",
    paddingLeft: 40,
    paddingRight: 40,
  },
  header: {
    paddingTop: 30,
    paddingBottom: 24,
  },
  list: {
    margin: "12px 0",
    paddingLeft: "1.2vw"
  },
  title: {
    color: "#C410A0",
    marginBottom: 24
  },
  copyContainer: {
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 20,
    backgroundColor: "white",
    maxHeight: "400px",
    overflow: "auto",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
  },
  buttonContainer: {
    marginTop: "20px",
    position: "relative",
    textAlign: "center"
  },
  button: {
    margin: "0 auto"
  },
  optOut: {
    position: "absolute",
    right: 0,
  }
}));

export const NewFeatures = ({ disabled, onClose }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const [optedOut, setOptedOut] = useState(false);

  if (disabled) return null;

  const handleOnAccept = () => {
    setIsOpen(false);
    onClose(optedOut);
  };

  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      closeAfterTransition
      onBackdropClick={handleOnAccept}
      onClose={handleOnAccept}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.header} variant="h2">
                New Features
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.copyContainer}>
              <Typography variant="h3" className={classes.title}>What's New in Version {NewFeatures.currentVersion}?</Typography>
              <Typography variant="body1">
                Welcome to the latest release of AMXConnect. We have a number of new features that are now available to some of our users:
              </Typography>
              <Typography className={classes.list} variant="body1" component="ul">
                <li>ESG Data - Owl Analytics trial available, so you can enhance your fund data metrics</li>
                <li>AMX Store - Browse the full list of AMX services by category and optionally subscribe to the OWL Analytics trial service</li>
                <li>MYAMX - See at a glance which AMX services you are using</li>
              </Typography>
              <Typography variant="body1">
                If you have any questions, please contact us using the service@theamx.com email.
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" className={classes.buttonContainer}>
            <Button className={classes.button} onClick={handleOnAccept}>
              OK
            </Button>
            <FormControlLabel
              className={classes.optOut}
              control={<Checkbox checked={optedOut} onChange={(event: any) => setOptedOut(event.target.checked)} name="opted-out" />}
              label="Don't show me again"
            />
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

NewFeatures.currentVersion = "2.2.2";

NewFeatures.propTypes = {
  /** Disabled prop controlling the modal */
  disabled: PropTypes.bool,
  /**
   * Callback that gets fired when the modal is closed.
   * @param {boolean} optedOut - whether the user opted to not be notified of new features in the future.
   * */
  onClose: PropTypes.func,
};
