import {
  DashboardContextType,
  PropsInterface,
  SearchResponse,
} from "../common/types";
import React, { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from ".";
import { SelectItem } from "../components/ChipSelector/ChipSelector";

export const DashboardContext = createContext<DashboardContextType>({});

/**
 * Usage:
 *  const { sharedDashboardState: [sharedDashboardData, setSharedDashboardData], } = useSharedDashboardData();
 */
export const DashboardContextProvider = (props: PropsInterface) => {
  const { axiosWithAuth } = useAuth();
  const sharedDashboardState = useState<any>([]);

  const [drilldownFilters, setDrilldownFilters] = useState<{
    [filterName: string]: SelectItem[];
  }>({});

  const [metricSelector, setMetricSelector] = useState("country");
  const [inspectionData, setInspectionData] = useState<SearchResponse[]>([]);

  const {
    data: dashboardsDrilldownData,
    isLoading: dashboardsDrilldownLoading,
  } = useQuery(
    "dashboards-drilldown",
    async () => {
      if (axiosWithAuth === undefined) return;

      const data = await axiosWithAuth({
        url: "/dashboard/drilldown",
      });

      return data?.tableData.reduce(
        (prev: any, curr: any) => [
          ...prev,
          { ...curr, ...curr.investments[0] },
        ],
        []
      );
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000 * 60,
    }
  );

  const {
    data: dashboardsDrilldownBaselineData,
    isLoading: dashboardsDrilldownBaselineLoading,
  } = useQuery(
    ["dashboards-drilldown-baseline", metricSelector, drilldownFilters],
    async () => {
      if (axiosWithAuth === undefined) return;

      const filtersInitialised = Object.keys(drilldownFilters).length !== 0;

      const selectors = filtersInitialised
        ? Object.keys(drilldownFilters).reduce(
            (
              filterDict: { [filterName: string]: string },
              filterName: string
            ) => ({
              ...filterDict,
              [`${filterName}Selectors`]: drilldownFilters[filterName]
                .reduce(
                  (selectedList: string[], item: SelectItem) =>
                    item.selected
                      ? selectedList.concat(item.value)
                      : selectedList,
                  []
                )
                .join(","),
            }),
            {}
          )
        : {
            schemeSelectors: "",
            strategySelectors: "",
            fundSelectors: "",
            assetClassSelectors: "",
          };

      const data = await axiosWithAuth({
        url: "/dashboard/drilldown",
        params: {
          ...selectors,
          metricSelector,
        },
      });

      return data;
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000 * 60,
    }
  );

  return (
    <DashboardContext.Provider
      value={{
        sharedDashboardState,
        dashboardsDrilldownData,
        dashboardsDrilldownLoading,
        dashboardsDrilldownBaselineData,
        dashboardsDrilldownBaselineLoading,
        inspectionData,
        setInspectionData,
        drilldownFilters,
        setDrilldownFilters,
        metricSelector,
        setMetricSelector,
      }}
      {...props}
    />
  );
};

export const useSharedDashboardData = () =>
  useContext<DashboardContextType>(DashboardContext);
