import React from "react";
import MUIDataTable from "mui-datatables";
import { Typography } from "@material-ui/core";
import { BuyButton } from "./BuyButton";
import { makeStyles } from "@material-ui/core/styles";

import { useService } from "../../../../providers";

const useStyles = makeStyles((theme) => ({
  dataTable: {
    border: "1px solid #e0dfe0",
  },
  tableHeading: {
    color: "#555",
    marginBottom: "1em",
  },
  addButton: {
    backgroundColor: "#237db5",
    borderRadius: 20,
    color: "#fff",
    "&:hover": {
      background: "#30305b",
      color: "#fff",
    },
  },
}));

export const NewServicesTable = () => {
  const classes = useStyles();

  const { serviceData, setServiceData } = useService();

  const handleBuyClick = (row: number, isPurchased: boolean) => {
    const updateRow = row + 1;
    const newList = serviceData.map((item) => {
      if (parseInt(item.id, 10) === updateRow) {
        return { ...item, purchased: !isPurchased };
      }
      return item;
    });

    setServiceData(newList);
  };

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "serviceName",
      label: "Service",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "type",
      label: "Service Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "logo",
      label: "Service Provider",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: logo) => (
          <img alt="logo" src={value} width="120px" />
        ),
      },
    },
    {
      name: "serviceProvider",
      label: "AMX Validation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "purchased",
      label: "Action",
      options: {
        filter: true,
        sort: false,

        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <BuyButton
              rowId={tableMeta.rowIndex}
              onBuyClick={handleBuyClick}
              purchased={value}
            />
          );
        },
      },
    },
  ];

  const options: any = {
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    responsive: "vertical",
    selectToolbarPlacement: "none",
    elevation: 0,
    pagination: false,
    selectableRows: "none",
    rowsPerPage: 5,
  };

  return (
    <div>
      <Typography
        gutterBottom
        component="h2"
        variant="h5"
        className={classes.tableHeading}
      >
        New Services
      </Typography>
      <div data-tour="new-services-table">
        <MUIDataTable
          className={classes.dataTable}
          data={serviceData}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};
