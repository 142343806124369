import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ConnectIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.952,13.6a4.512,4.512,0,1,0-4.512-4.512A4.512,4.512,0,0,0,17.952,13.6Zm0,1.346a5.857,5.857,0,1,0-5.857-5.857A5.857,5.857,0,0,0,17.952,14.949Z"
        transform="translate(-2.48 -1.24)"
        fill="#272730"
        fillRule="evenodd"
      />
      <path
        d="M9.76,1.9a4.236,4.236,0,1,0,1.371,8.245l.356,1.04a5.336,5.336,0,1,1,3.092-7.34l-.993.473A4.236,4.236,0,0,0,9.76,1.9Z"
        transform="translate(-1.091 -0.799)"
        fill="#272730"
        fillRule="evenodd"
      />
      <path
        d="M24.009,17.888a.673.673,0,0,1,.622-.215,6.1,6.1,0,0,1,4.787,5.566c.089.665.2,1.693.258,2.638.028.475.041.942.028,1.337a5.067,5.067,0,0,1-.046.558,1.842,1.842,0,0,1-.139.5,4.6,4.6,0,0,1-1.211,1.563,3.8,3.8,0,0,1-2.452.933H19.1a.673.673,0,0,1,0-1.346h6.758a2.454,2.454,0,0,0,1.586-.618,3.255,3.255,0,0,0,.848-1.084l.01-.021,0,0a.91.91,0,0,0,.027-.133,3.742,3.742,0,0,0,.032-.407c.012-.336,0-.756-.026-1.212-.053-.909-.164-1.91-.25-2.551,0-.009,0-.019,0-.028a4.788,4.788,0,0,0-3.344-4.3A7.9,7.9,0,0,1,19.1,21.437a.673.673,0,0,1,0-1.346A6.555,6.555,0,0,0,24.009,17.888Z"
        transform="translate(-3.626 -3.853)"
        fill="#272730"
        fillRule="evenodd"
      />
      <path
        d="M11.99,17.888a.673.673,0,0,0-.622-.215,6.1,6.1,0,0,0-4.787,5.566c-.089.665-.2,1.693-.258,2.638-.028.475-.041.942-.028,1.337a5.072,5.072,0,0,0,.046.558,1.844,1.844,0,0,0,.139.5,4.6,4.6,0,0,0,1.211,1.563,3.8,3.8,0,0,0,2.452.933H16.9a.673.673,0,1,0,0-1.346H10.142a2.454,2.454,0,0,1-1.586-.618,3.254,3.254,0,0,1-.848-1.084L7.7,27.7l0,0a.911.911,0,0,1-.027-.133,3.762,3.762,0,0,1-.032-.407c-.012-.336,0-.756.026-1.212.053-.909.164-1.91.25-2.551,0-.009,0-.019,0-.028a4.788,4.788,0,0,1,3.344-4.3A7.9,7.9,0,0,0,16.9,21.437a.673.673,0,1,0,0-1.346A6.555,6.555,0,0,1,11.99,17.888Z"
        transform="translate(-1.428 -3.853)"
        fill="#272730"
        fillRule="evenodd"
      />
      <path
        d="M14.241,15a.55.55,0,0,1-.2.751,6.442,6.442,0,0,1-3.221.858.55.55,0,0,1,0-1.1,5.343,5.343,0,0,0,2.673-.711A.55.55,0,0,1,14.241,15Z"
        transform="translate(-2.149 -3.321)"
        fill="#272730"
        fillRule="evenodd"
      />
      <path
        d="M5.01,13.442a.55.55,0,0,0-.508-.175A4.979,4.979,0,0,0,.591,17.814C.518,18.357.425,19.2.38,19.969c-.023.388-.034.77-.023,1.092a4.145,4.145,0,0,0,.038.456,1.507,1.507,0,0,0,.113.412A3.753,3.753,0,0,0,1.5,23.206a3.1,3.1,0,0,0,2,.762h2.15a.55.55,0,0,0,0-1.1H3.5a2.005,2.005,0,0,1-1.3-.505,2.659,2.659,0,0,1-.693-.886L1.5,21.461l0,0a.74.74,0,0,1-.022-.108,3.076,3.076,0,0,1-.026-.332c-.01-.274,0-.618.021-.99.043-.743.134-1.56.2-2.084,0-.008,0-.015,0-.023a3.912,3.912,0,0,1,2.732-3.51,6.451,6.451,0,0,0,4.6,1.925.55.55,0,1,0,0-1.1A5.355,5.355,0,0,1,5.01,13.442Z"
        transform="translate(-0.353 -3.055)"
        fill="#272730"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};
