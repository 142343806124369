export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const padZero = (unPaddedNumber: number) => {
  return unPaddedNumber < 10 ? "0" + unPaddedNumber : unPaddedNumber;
};

export const euroFormat = (dateIn: Date | string): string => {
  const date = typeof dateIn === "string" ? new Date(dateIn) : dateIn;
  const month = months[date.getMonth()];
  return `${padZero(date.getDate())}-${month}-${date.getFullYear()}`;
};

export const numberToDate = (numericalDate: Number): Date => {
  const dateString = `${numericalDate}`;

  if (dateString.length !== 8) return new Date();

  const year = dateString.substr(0, 4);
  const month = dateString.substr(4, 2);
  const day = dateString.substr(6, 2);

  return new Date(Date.parse(`${year}-${month}-${day}`));
};

export const toDate = (dateStr: string): Date => {
  let date = new Date("01 Jan 1970 00:00:00 UTC");
  date.setDate(parseInt(dateStr.substr(0, 2)));
  date.setMonth(parseInt(dateStr.substr(3, 2)) - 1);
  date.setFullYear(parseInt(dateStr.substr(6, 4)));
  return date;
};

export const euroFormatDateTime = (dateIn: Date | string): string => {
  const date = typeof dateIn === "string" ? new Date(dateIn) : dateIn;
  const month = months[date.getMonth()];
  return `${padZero(date.getDate())}-${month}-${date.getFullYear()} ${padZero(
    date.getHours()
  )}:${padZero(date.getMinutes())}`;
};
