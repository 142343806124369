import React, { useState } from "react";
import PropTypes from "prop-types";
import { CloudUpload } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

/**
 * AMX Connect CustomLogo Component
 * @component
 */
export const CustomLogo = (props: any) => {
  const { onFileProvided, maxHeight, src } = props;
  const history = useHistory();
  //? TODO: This is temporary until we're doing it for real.
  const [companyLogo, setCompanyLogo] = useState(
    localStorage.getItem("companyLogo") || false
  );

  const uploadLogo = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function () {
      localStorage.setItem("companyLogo", reader.result as string);
      setCompanyLogo(reader.result as string);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  //? TODO: replace the src in the image below with the provided one once uploading is implemented.
  return src || companyLogo ? (
    <img
      alt="Logo"
      src={localStorage.companyLogo}
      onClick={() => history.push("/")}
      style={{ cursor: "pointer", maxHeight: maxHeight || "auto" }}
    />
  ) : (
    <React.Fragment>
      <label htmlFor="custom-logo-upload">
        <input
          style={{ display: "none" }}
          id="custom-logo-upload"
          type="file"
          onChange={(event: any) => {
            uploadLogo(event);
          }}
        />
        <Button
          variant="text"
          color="default"
          startIcon={<CloudUpload />}
          component="span"
        >
          Upload Logo
        </Button>
      </label>
    </React.Fragment>
  );
};

CustomLogo.propTypes = {
  /**
   * The maximum height for the image.
   */
  maxHeight: PropTypes.string,
  /**
   * Callback function that gets called when the user selects a file to upload.
   * @param {number} selectedIndex
   */
  onFileProvided: PropTypes.func,
  /**
   * The src to the image if available
   */
  src: PropTypes.string,
};
