import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { PlusIcon } from "../../../components";
import CreateDashboard from "../components/create-dashboard/CreateDashboard";
import { WidgetWrapper } from "./WidgetWrapper";

export const DefaultCreate = (props: any) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <React.Fragment>
      <WidgetWrapper data={props} title="">
        <Box
          width="100%"
          minHeight={400}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <div
            style={{
              backgroundColor: "lightgray",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 50,
              height: 50,
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onClick={() => setOpenDialog(true)}
          >
            <PlusIcon />
          </div>
        </Box>
      </WidgetWrapper>
      <CreateDashboard
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        widgetData={props}
      />
    </React.Fragment>
  );
};
