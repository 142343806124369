import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const RocketIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="17"
      height="30"
      viewBox="0 0 17 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.624,29.328a.589.589,0,0,0,.247.054.62.62,0,0,0,.307-.084L21.093,27h6.758l3.915,2.3a.62.62,0,0,0,.307.084.607.607,0,0,0,.59-.482A8.181,8.181,0,0,0,29.5,20.517c.108-1.409.169-2.891.169-4.439a16.375,16.375,0,0,0-.952-5.7,10.42,10.42,0,0,0-3.939-5.288.61.61,0,0,0-.608,0c-.012.006-.06.036-.139.09s-.187.133-.319.235a11.171,11.171,0,0,0-3.469,4.963h0a16.516,16.516,0,0,0-.952,5.7q0,2.331.163,4.439A8.192,8.192,0,0,0,16.281,28.9.589.589,0,0,0,16.624,29.328Zm7.842-16.936a6.751,6.751,0,0,0,3.337-.879,15.685,15.685,0,0,1,.65,4.565,45.768,45.768,0,0,1-.921,9.715H21.406a45.842,45.842,0,0,1-.921-9.715,15.685,15.685,0,0,1,.65-4.565A6.633,6.633,0,0,0,24.466,12.392ZM31.609,27.81l-2.915-1.717a38.14,38.14,0,0,0,.663-4.138A6.9,6.9,0,0,1,31.609,27.81ZM24.731,6.562c.03.03.072.066.1.1.072.066.145.126.223.2.048.042.1.1.145.145.078.078.151.151.229.241.054.06.114.126.169.193.078.09.157.181.241.283.06.078.12.163.187.241.078.1.157.211.241.325.066.09.126.193.193.289.078.12.157.241.235.373.066.108.126.223.187.337.072.139.151.277.223.428.06.126.12.253.181.385.036.084.072.175.108.265a5.673,5.673,0,0,1-5.86,0c.036-.09.072-.181.114-.265.06-.126.114-.253.175-.379.072-.151.151-.3.229-.434.06-.114.12-.223.187-.331.078-.133.157-.259.241-.385.06-.1.12-.193.187-.283.084-.12.163-.229.247-.331.06-.078.12-.163.181-.235.084-.1.163-.193.241-.289.054-.06.108-.133.163-.187.084-.09.157-.169.235-.247.048-.048.1-.1.139-.145l.229-.211c.03-.03.072-.066.1-.09.1-.084.193-.163.271-.217C24.544,6.4,24.635,6.472,24.731,6.562ZM19.576,21.95a36.357,36.357,0,0,0,.669,4.144L17.323,27.81A6.8,6.8,0,0,1,19.576,21.95Z"
        transform="translate(-16.086 -5.005)"
        fill="#272730"
      />
      <path
        d="M33.64,44.082v2.975a.6.6,0,1,0,1.2,0V44.082a.6.6,0,0,0-1.2,0Z"
        transform="translate(-23.067 -20.307)"
        fill="#272730"
      />
      <path
        d="M29,48.662v2.975a.6.6,0,1,0,1.2,0V48.662a.6.6,0,0,0-1.2,0Z"
        transform="translate(-21.222 -22.129)"
        fill="#272730"
      />
      <path
        id="Path_881"
        data-name="Path 881"
        d="M24.36,44.082v2.975a.6.6,0,1,0,1.2,0V44.082a.6.6,0,0,0-1.2,0Z"
        transform="translate(-19.377 -20.307)"
        fill="#272730"
      />
      <path
        d="M28.393,26.456a2.433,2.433,0,1,0-2.433-2.433A2.434,2.434,0,0,0,28.393,26.456Zm0-3.668a1.229,1.229,0,1,1-1.229,1.229A1.23,1.23,0,0,1,28.393,22.789Z"
        transform="translate(-20.013 -11.601)"
        fill="#272730"
      />
    </SvgIcon>
  );
};
