import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CreateReportModal } from "./CreateReportModal";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import GenericTable from "../../../../../components/Table/GenericTable";
import { exportFile } from "../../../../../helpers/download";
const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: "100%",
      maxWidth: "100%",
    },
  })
);

const exportToCsv = (fileName: string, columnData: any, tableData: any) => {
  exportFile(
    `${fileName}.csv`,
    `${columnData
      .map((column: any) => column.label)
      .join(",")}\n${tableData
      .map((row: any) =>
        columnData.map((column: any) => row[column.id]).join(",")
      )
      .join("\n")}`
  );
};

const Tabular = (props: any) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedFunds, setSelectedFunds] = useState<string[]>(
    props.tab.selected
  );
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
  const classes = useStyles();
  const {
    tab,
    tab: {
      reportType,
      selector,
      selected,
      visibleHeaders,
      data: {
        dataTable: { data, metaData },
      },
    },
    selectedReport,
  } = props;

  useEffect(() => {
    setSelectedFunds(selected);
    setSelectedHeaders(visibleHeaders);
  }, [tab, selected, visibleHeaders]);

  const filteredData = data.filter((datum: any) => {
    return selectedFunds.indexOf(datum[datum.key]) > -1;
  });

  const key = data.length > 0 ? data[0].key : "";
  const keyMeta = metaData.find((meta: any) => meta.id === key);
  const filteredMetaData = metaData.filter(
    ({ label }: { label: string }) => selectedHeaders.indexOf(label) > -1
  );

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label">
              {keyMeta === undefined ? "Key" : keyMeta.label}
            </InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={selectedFunds}
              label={keyMeta === undefined ? "Key" : keyMeta.label}
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown;
                }>
              ) => setSelectedFunds(event.target.value as string[])}
              renderValue={(selected: any) => (selected as string[]).join(", ")}
            >
              {selector?.map((fundName: string) => (
                <MenuItem key={fundName} value={fundName}>
                  <Checkbox checked={selectedFunds.indexOf(fundName) > -1} />
                  <ListItemText primary={fundName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label">
              Data Points
            </InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              label="Data Points"
              value={selectedHeaders}
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown;
                }>
              ) => setSelectedHeaders(event.target.value as string[])}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {metaData.map((meta: any) => (
                <MenuItem key={meta.id} value={meta.id}>
                  <Checkbox checked={selectedHeaders.indexOf(meta.id) > -1} />
                  <ListItemText primary={meta.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} container justify="flex-end" alignItems="flex-end">
          <Button
            onClick={() => setOpenModal(true)}
            variant="contained"
            size="small"
            disabled={
              selectedFunds.length === 0 || selectedHeaders.length === 0
            }
          >
            Add to My Reports
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" mb={1}>
            <IconButton
              aria-label="delete"
              onClick={() =>
                exportToCsv("ExportedData", filteredMetaData, filteredData)
              }
            >
              <SystemUpdateAltIcon />
            </IconButton>
          </Box>
          <GenericTable columns={filteredMetaData} tableData={filteredData} />
        </Grid>
      </Grid>
      <CreateReportModal
        selectedOptions={{
          selectedFunds: selectedFunds,
          selectedDatapoints: selectedHeaders,
        }}
        selectedReport={selectedReport}
        openModal={openModal}
        setOpenModal={(option: boolean) => setOpenModal(option)}
        reportType={reportType}
      />
    </>
  );
};
export default Tabular;
