import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="25"
      height="23"
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-1861 -27)">
        <line
          x2="7"
          y2="6"
          transform="translate(1878.5 43.5)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <g
          transform="translate(1861 27)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
        >
          <circle cx="10.5" cy="10.5" r="10.5" stroke="none" />
          <circle cx="10.5" cy="10.5" r="10" fill="none" />
        </g>
      </g>
    </SvgIcon>
  );
};
