import React from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  formRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%    ",
  },
  formControl: {
    minWidth: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //   width: 350,
    },
  },
};

export type SelectItem = { name: string; value: string; selected: boolean };

export default function ChipSelector(props: {
  label: string;
  items: SelectItem[];
  setSelectedCheckboxes: Function;
}) {
  const classes = useStyles();
  const { label, items, setSelectedCheckboxes } = props;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="mutiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="mutiple-checkbox-label"
        id="mutiple-checkbox"
        multiple={true}
        autoWidth
        value={items}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          const changes: any = event.target.value;
          const selected = changes[changes.length - 1];

          setSelectedCheckboxes(
            items.map((item) =>
              item.value === selected
                ? { ...item, selected: !item.selected }
                : { ...item }
            )
          );
        }}
        input={<Input />}
        renderValue={(selected) => {
          return (
            <div className={classes.chips}>
              {(selected as string[]).map((value: any) => {
                if (value.selected) {
                  return (
                    <Chip
                      key={value.value}
                      label={value.name}
                      className={classes.chip}
                    />
                  );
                }
                return null;
              })}
            </div>
          );
        }}
        MenuProps={MenuProps}
      >
        {items.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={item.selected} />
              <ListItemText primary={`${item.name}`} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
