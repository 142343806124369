import React, { createContext, useContext, useState } from "react";
import { differenceInDays } from "date-fns";
import { useQuery } from "react-query";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { PricingWorkflowTask } from "../../../common/types";
import { TabView } from "../../../components";
import { useAuth } from "../../../providers";
import TaskDialog from "./components/TaskDialog";
import TaskTable from "./components/TaskTable";

const useStyles = makeStyles(() => ({
  paper: {
    padding: "30px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MyTasksContext = createContext<any>({});
MyTasksContext.displayName = "MyTasksContext";

const MyTasks = () => {
  const classes = useStyles();
  const { axiosWithAuth } = useAuth();
  const [internalTaskState, setInternalTaskState] = useState<any>({});
  const [taskId, setTaskId] = useState<
    { parentId: string; _id: string } | undefined
  >(undefined);
  const today = new Date();
  const dateFormat = "dd/MM/yyyy";
  const [radioValue, setRadioValue] = useState("assigned-to-me");

  const { data: users } = useQuery(
    "users",
    () => {
      if (axiosWithAuth === undefined) return;
      return axiosWithAuth({
        url: "pricing-workflow/product-owners",
      }).then((data) => data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: teams } = useQuery(
    "teams",
    () => {
      if (axiosWithAuth === undefined) return;
      return axiosWithAuth({
        url: "pricing-workflow/teams",
      }).then((data) => data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: selectedTask,
    isLoading: selectedTaskIsLoading,
    isIdle: selectedTaskIsIdle,
  } = useQuery(
    ["task", taskId],
    () => {
      if (axiosWithAuth === undefined) return;
      return axiosWithAuth({
        url: `/pricing-workflow/${taskId?.parentId}/${taskId?._id}`,
      }).then((data) => ({
        ...data,
        assignedDate: data.assignedDate ?? new Date(),
      }));
    },
    {
      enabled: !!taskId,
      refetchOnWindowFocus: false,
    }
  );

  const { data: tasks, isLoading } = useQuery(
    "tasks",
    () => {
      if (axiosWithAuth === undefined) return;
      return axiosWithAuth({
        url: "/tasks-for-user",
      }).then((data) => {
        const { pricingWorkflowTasks } = data;

        const updatedPricingWorkflowTasks = pricingWorkflowTasks.map(
          (task: PricingWorkflowTask) => {
            return {
              ...task,
              age: differenceInDays(
                new Date(),
                new Date(task.assignedDate ?? new Date())
              ),
              assignedDate: task.assignedDate ?? new Date(),
            };
          }
        );

        return updatedPricingWorkflowTasks;
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  let filteredTasksArray: PricingWorkflowTask[][] = [];
  const {
    authState: { user },
  } = useAuth();

  if (!isLoading) {
    if (radioValue === "assigned-to-me") {
      filteredTasksArray.push(
        tasks.filter(
          (task: PricingWorkflowTask) =>
            task.assignee.id === user.accountIdentifier &&
            task.status !== "Completed" &&
            task.status !== "Approved"
        )
      );
    } else if (radioValue === "closed-tasks") {
      filteredTasksArray.push(
        tasks.filter(
          (task: PricingWorkflowTask) =>
            task.status === "Completed" || task.status === "Approved"
        )
      );
    } else {
      filteredTasksArray.push(
        tasks.filter(
          (task: PricingWorkflowTask) =>
            !!task.assignedTeam?.id &&
            task.status !== "Completed" &&
            task.status !== "Approved"
        )
      );
    }
  }
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };
  const value: any = {
    dateFormat,
    users,
    teams,
    today,
    tasks,
    taskId,
    setTaskId,
    selectedTask,
    selectedTaskIsLoading,
    selectedTaskIsIdle,
    internalTaskState,
    setInternalTaskState,
  };

  return (
    <MyTasksContext.Provider value={value}>
      <TabView
        data-tour="connect-my-tasks"
        isLoading={isLoading}
        title="My Tasks"
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <Typography variant="h5" align="center">
                  Total Number
                </Typography>
              </Box>
              <Typography color="primary" variant="h4" align="center">
                {
                  tasks?.filter(
                    (task: PricingWorkflowTask) =>
                      task.status !== "Completed" && task.status !== "Approved"
                  ).length
                }
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <ChevronRight fontSize="large" color="secondary" />
                <Typography variant="h5" align="center">
                  1 Days
                </Typography>
              </Box>
              <Typography color="primary" variant="h4" align="center">
                {
                  tasks?.filter(
                    (task: PricingWorkflowTask) =>
                      task.age > 1 &&
                      task.status !== "Completed" &&
                      task.status !== "Approved"
                  ).length
                }
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <ChevronRight fontSize="large" color="secondary" />
                <Typography variant="h5" align="center">
                  5 Days
                </Typography>
              </Box>
              <Typography color="primary" variant="h4" align="center">
                {
                  tasks?.filter(
                    (task: PricingWorkflowTask) =>
                      task.age > 5 &&
                      task.status !== "Completed" &&
                      task.status !== "Approved"
                  ).length
                }
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Tasks Filter</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="assigned-to-me"
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="assigned-to-me"
                  control={<Radio color="primary" />}
                  label="Assigned to Me"
                />
                <FormControlLabel
                  value="closed-tasks"
                  control={<Radio color="primary" />}
                  label="Closed Tasks"
                />
                <FormControlLabel
                  value="team"
                  control={<Radio color="primary" />}
                  label="Team Tasks"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container item xs={12}>
            {filteredTasksArray.map(
              (filteredTasks: PricingWorkflowTask[], index: number) => (
                <Grid key={index} item xs={12}>
                  <TaskTable filteredTasks={filteredTasks} />
                </Grid>
              )
            )}
            <TaskDialog />
          </Grid>
        </Grid>
      </TabView>
    </MyTasksContext.Provider>
  );
};

const useMyTasks = () => useContext<any>(MyTasksContext);

export { MyTasks, useMyTasks };
