import React from "react";
import { LinearProgress, MenuItem, Select } from "@material-ui/core";
import { DashboardWidget } from "../../../common/types";
import { useSharedDashboardData } from "../../../providers";
import { WidgetWrapper } from "./WidgetWrapper";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Options } from "highcharts/highstock";

type FundsDrillDownPieProps = DashboardWidget & {};

//! TODO: Consolidate all these PieChart widgets into one and make the `valueProperty` a prop stored against the widget in User Preferences -> Dashboards.
export const FundsDrillDownPie = (props: FundsDrillDownPieProps) => {
  const {
    dashboardsDrilldownBaselineData,
    dashboardsDrilldownBaselineLoading,
    metricSelector,
    setMetricSelector,
  } = useSharedDashboardData();

  const options: Options =
    dashboardsDrilldownBaselineData !== undefined
      ? {
          credits: { enabled: false },
          chart: {
            type: "pie",
          },
          title: {
            text: "",
          },
          tooltip: {
            pointFormat: "<b>{point.y}</b> ({point.percentage:.1f}%)",
          },
          plotOptions: {
            pie: {
              // allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              },
            },
          },
          series: [
            {
              type: "pie",
              name: "holdings",
              colorByPoint: true,
              data: dashboardsDrilldownBaselineData.chartData,
            },
          ],
        }
      : {};

  return (
    <WidgetWrapper
      data={props}
      title={`Breakdown of Funds by ${metricSelector}`}
    >
      {dashboardsDrilldownBaselineLoading ? <LinearProgress /> : null}
      {dashboardsDrilldownBaselineData ? (
        <>
          <Select
            value={metricSelector}
            onChange={(event: any) => setMetricSelector(event.target.value)}
            label="Metric"
          >
            <MenuItem value="country">Country</MenuItem>
            <MenuItem value="currency">Currency</MenuItem>
            <MenuItem value="sector">Sector</MenuItem>
            <MenuItem value="holdings">Holdings</MenuItem>
          </Select>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { overflow: "visible" } }}
          />
        </>
      ) : null}
    </WidgetWrapper>
  );
};
