import React from "react";
import { Link } from "react-router-dom";
import { View } from "../components";

export const Error404 = () => {
  return (
    <View>
      <div className="error-page">
        <h1>Page Error</h1>
        <p>We're sorry you ended up here!</p>
        <p>
          This page is either missing, or you do not currently have permission
          to view it. Please contact support if you continue to experience this
          error.
        </p>
        <br />
        <p>
          Click
          <em>
            <Link to="/"> here </Link>
          </em>
          to go back to the dashboard
        </p>
      </div>
    </View>
  );
};
