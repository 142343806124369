import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const CreateReportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="27.901"
      height="33.883"
      viewBox="0 0 27.901 33.883"
    >
      <g id="noun_report_2120939" transform="translate(0.5 0.5)">
        <g id="Group_80" data-name="Group 80" transform="translate(0 0)">
          <path
            id="Path_518"
            data-name="Path 518"
            d="M36.341,2.5H16.771a1.66,1.66,0,0,0-1.66,1.66V6.546H12.76a1.66,1.66,0,0,0-1.66,1.66V33.723a1.66,1.66,0,0,0,1.66,1.66H32.3a1.66,1.66,0,0,0,1.66-1.66V31.372h2.386A1.66,1.66,0,0,0,38,29.712V4.16A1.683,1.683,0,0,0,36.341,2.5ZM32.918,33.689a.583.583,0,0,1-.588.588H12.76a.583.583,0,0,1-.588-.588V8.171a.583.583,0,0,1,.588-.588h2.351V29.712a1.66,1.66,0,0,0,1.66,1.66H32.884v2.317Zm4.011-4.011a.62.62,0,0,1-.622.622H16.771a.62.62,0,0,1-.622-.622V4.16a.62.62,0,0,1,.622-.622H36.307a.62.62,0,0,1,.622.622Z"
            transform="translate(-11.1 -2.5)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
          />
          <path
            id="Path_519"
            data-name="Path 519"
            d="M45.332,26.575l2.7-2.7,1.383,1.383a.5.5,0,0,0,.726,0l2.87-2.87.519.519a.241.241,0,0,0,.415-.138l.277-2.005a.229.229,0,0,0-.277-.277l-2.005.277a.244.244,0,0,0-.138.415l.519.519-2.49,2.49-1.383-1.383a.5.5,0,0,0-.726,0l-3.112,3.043a.513.513,0,0,0,.726.726Z"
            transform="translate(-32.919 -14.266)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
          />
          <path
            id="Path_520"
            data-name="Path 520"
            d="M49.637,55.7H38.019a.519.519,0,1,0,0,1.037H49.671a.532.532,0,0,0,.519-.519A.539.539,0,0,0,49.637,55.7Z"
            transform="translate(-28.372 -37.305)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
          />
          <path
            id="Path_521"
            data-name="Path 521"
            d="M49.637,69.3H38.019a.519.519,0,1,0,0,1.037H49.671a.532.532,0,0,0,.519-.519A.539.539,0,0,0,49.637,69.3Z"
            transform="translate(-28.372 -46.202)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
          />
          <path
            id="Path_522"
            data-name="Path 522"
            d="M49.637,25.744h-11.1V17.619a.519.519,0,1,0-1.037,0v8.644a.532.532,0,0,0,.519.519H49.671a.532.532,0,0,0,.519-.519A.539.539,0,0,0,49.637,25.744Z"
            transform="translate(-28.372 -12.052)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
