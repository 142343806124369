import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  makeStyles,
  Typography,
  Box,
  Paper,
  IconButton,
  ButtonGroup,
  ClickAwayListener,
  TextField,
} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DescriptionIcon from "@material-ui/icons/Description";
import LoopIcon from "@material-ui/icons/Loop";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import { DocumentMetadataType } from "../../../../../common/types";
import { format } from "date-fns";
import { useDocuments } from "../Documents";
import OverflowTip from "./OverflowTooltip";
import { useAuth } from "../../../../../providers";

const useStyles = makeStyles((theme) => ({
  "@keyframes spin": {
    "0%": { transform: "rotate(360deg)" },
    "100%": { transform: "rotate(0deg)" },
  },
  spinner: {
    animation: "$spin 1s linear infinite",
  },

  docContainer: {
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "0.729vw",

    "& svg": {
      color: "#707070",
    },

    "&:active": {
      border: "1px solid rgba(63, 160, 205, 0.2)",
      boxShadow: "unset",
    },

    "&:hover": {
      backgroundColor: "#fafafa",
    },
    "&:hover svg": {
      color: "#C410A0",
    },
  },
  docContainerDisabled: {},
}));

const DocumentTile = ({
  doc,
  handleOnClick,
  filterHide,
  isDownloading,
}: {
  doc: DocumentMetadataType;
  handleOnClick: any;
  filterHide: any;
  isDownloading: string | null;
}) => {
  const classes = useStyles();
  const { axiosWithAuth } = useAuth();
  const { selectedDocType, updateDocument, userGroups } = useDocuments();
  const initialDocName =
    doc.customDocumentName !== ""
      ? doc.customDocumentName
      : selectedDocType === "All Documents" ||
        selectedDocType === "Recent Documents" ||
        selectedDocType === "Contract Notes" ||
        selectedDocType === "Investment Statement"
      ? doc.documentName
      : doc.documentNameUi;

  let fakeName = "";
  if (doc.documentName !== "") {
    let textTitle = doc.documentName;
    fakeName = textTitle.replace("TCCC", "pension company");
  }

  const [isDocEdit, setIsDocEdit] = useState(false);
  const [docName, setDocName] = useState(initialDocName);
  const isPdf = /\.pdf$/i.test(doc.documentName);

  const handleDocVisible = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (axiosWithAuth === undefined) return;

    const { documentSk, visibleFlag } = doc;

    const updateVisibleFlag = visibleFlag === "T" ? "F" : "T";

    updateDocument({ ...doc, visibleFlag: updateVisibleFlag }, selectedDocType);

    await axiosWithAuth({
      url: "/documents/update",
      method: "POST",
      data: {
        documentSk,
        visibleFlag: updateVisibleFlag,
        customDocName: doc.customDocumentName,
      },
    });
  };

  const handleEditDocConfirm = async (event: any, confirm: boolean) => {
    event.stopPropagation();

    if (confirm) {
      if (axiosWithAuth === undefined) return;
      const { documentSk, visibleFlag } = doc;
      await axiosWithAuth({
        url: "/documents/update",
        method: "POST",
        data: {
          documentSk,
          visibleFlag,
          customDocName: docName,
        },
      });

      updateDocument({ ...doc, customDocumentName: docName }, selectedDocType);

      setIsDocEdit(false);
    } else {
      setDocName(initialDocName);
      setIsDocEdit(false);
    }
  };

  return (
    <Grid
      key={doc.documentSk}
      item
      xs={12}
      md={6}
      lg={4}
      xl={filterHide ? 4 : 3}
      style={{
        transition: "all ease-in-out 0.5s",
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setDocName(initialDocName);
          setIsDocEdit(false);
        }}
      >
        <Paper
          onClick={() => {
            const fileExtension = doc.customDocumentName.includes(".")
              ? ""
              : `.${doc.documentName.split(".")[1]}`;

            const documentName = doc.customDocumentName
              ? `${doc.customDocumentName}${fileExtension}`
              : doc.documentName;
            !isDocEdit && handleOnClick(documentName, doc.documentSk, doc._id);
          }}
          className={
            doc.visibleFlag !== "T"
              ? classes.docContainerDisabled
              : classes.docContainer
          }
          elevation={1}
          style={{
            display: "flex",
            padding: "20px 20px",
            height: "150px",
            backgroundColor: doc.visibleFlag !== "T" ? "#fafafa" : "",
          }}
        >
          {isDownloading === doc.documentName ? (
            <LoopIcon className={classes.spinner} />
          ) : isPdf ? (
            <PictureAsPdfIcon />
          ) : (
            <DescriptionIcon />
          )}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
          >
            {isDocEdit ? (
              <TextField
                required
                multiline
                autoFocus
                size="small"
                rowsMax={2}
                InputProps={{
                  style: {
                    marginLeft: "10px",
                    marginBottom: "5px",
                    fontSize: "1rem",
                  },
                }}
                value={docName}
                onChange={(event) => {
                  setDocName(event.target.value);
                }}
              />
            ) : (
              <OverflowTip title={doc.documentName}>
                <Typography
                  variant="subtitle1"
                  style={{
                    marginLeft: "10px",
                    marginBottom: "5px",
                    wordBreak: "break-word",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                  }}
                >
                  {fakeName !== ""
                    ? fakeName
                    : doc.customDocumentName !== ""
                    ? doc.customDocumentName
                    : selectedDocType === "All Documents" ||
                      selectedDocType === "Recent Documents" ||
                      selectedDocType === "Contract Notes" ||
                      selectedDocType === "Investment Statement"
                    ? doc.documentName
                    : doc.documentNameUi}
                </Typography>
              </OverflowTip>
            )}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Typography
                variant="subtitle1"
                style={{ marginLeft: "10px", color: "#C1C2C3" }}
              >
                {format(new Date(doc.documentActiveFromDate), "d-MMM-y")}
              </Typography>
              {userGroups.includes("documents-admin") ? (
                isDocEdit ? (
                  <ButtonGroup size="small">
                    <IconButton
                      size="small"
                      onClick={(event) => handleEditDocConfirm(event, true)}
                      disabled={docName ? false : true}
                    >
                      <DoneIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(event) => handleEditDocConfirm(event, false)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </ButtonGroup>
                ) : (
                  <ButtonGroup size="small">
                    <IconButton size="small" onClick={handleDocVisible}>
                      {doc.visibleFlag !== "T" ? (
                        <VisibilityOffIcon fontSize="small" />
                      ) : (
                        <VisibilityIcon fontSize="small" />
                      )}
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();

                        setIsDocEdit((prevState) => !prevState);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </ButtonGroup>
                )
              ) : null}
            </Box>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Grid>
  );
};

export default DocumentTile;
