import React from "react";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-query";
import { DashboardWidget } from "../../../common/types";
import { useAuth } from "../../../providers";
import { WidgetWrapper } from "./WidgetWrapper";

type ReportProps = DashboardWidget & {
  selectedDataSource: any;
  selectedDataPoints: any;
  selectedDataItems: any;
  dataItemsKey: string;
  reportName: string;
};

const useStyles = makeStyles((theme) => ({
  liveAnnounce: {
    "& [class*=liveAnnounce]": {
      display: "none",
    },
    "& [aria-live=polite]": {
      display: "none",
    },
  },
}));

export const Reports = (props: ReportProps) => {
  const classes = useStyles();
  const {
    selectedDataSource,
    selectedDataPoints,
    selectedDataItems,
    dataItemsKey,
    reportName,
  } = props;

  const { axiosWithAuth } = useAuth();

  const { data: tabularData, isLoading } = useQuery(
    ["reportwidget", selectedDataSource],
    async () => {
      const { exposures: esgData } = await axiosWithAuth!({
        url: "/dashboard/inspector/detail/",
        params: {
          filters: { country: [], sector: [], currency: [] },
        },
      });
      const { holdings: fundamentalData } = await axiosWithAuth!({
        url: "/dashboard/inspector/detail/",
        params: {
          options: [{ type: "Country", match: "United States" }],
          filters: { country: [], sector: [], currency: ["USD"] },
        },
      });

      if (selectedDataSource === "Fundamental Data") return fundamentalData;
      if (selectedDataSource === "ESG Data")
        return esgData.map((item: any) => ({
          ...item,
          environmentalData: item.esg.EnvironmentalData[0].Fundvalue,
          socialData: item.esg.SocialData[0].Fundvalue,
        }));
    }
  );

  const filteredData = tabularData?.filter((row: any) =>
    selectedDataItems?.includes(row[dataItemsKey])
  );

  const columns = [
    ...selectedDataPoints.map(
      (selectedDataPoint: { name: string; id: string; label: string }) => ({
        name: selectedDataPoint.name || selectedDataPoint.id,
        label: selectedDataPoint.label,
      })
    ),
  ];

  const options: any = {
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    responsive: "simple",
    selectToolbarPlacement: "none",
    elevation: 0,
    pagination: true,
    selectableRows: "none",
    rowsPerPage: 10,
  };

  return (
    <WidgetWrapper data={props} title={reportName}>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={400}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className={classes.liveAnnounce}>
          <MUIDataTable
            title=""
            options={options}
            data={filteredData}
            columns={columns}
          />
        </div>
      )}
    </WidgetWrapper>
  );
};
