import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
  makeStyles,
  Theme,
  createStyles,
  Paper,
  InputBase,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import MyReportsTable from "./MyReportsTable";
import { useAuth } from "../../../../providers";
import { PlusIcon, TabView } from "../../../../components";
import { Search } from "@material-ui/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { exampleDashboardReport } from "../../../../components/GettingStartedTour/example-data";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
      marginLeft: "auto",
      padding: "2vw",
    },
    filterContainer: {
      margin: "20px 0",
    },
    filterItem: {
      display: "flex",
      alignItems: "center",
      padding: 15,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    selectsContainer: {
      display: "flex",
      alignItems: "center",
      padding: 15,
      "& > *": {
        marginRight: theme.spacing(2),
      },
    },
    formControl: {
      minWidth: 180,
    },
  })
);

const MyReports = () => {
  const { axiosWithAuth } = useAuth();
  const {
    authState: { user },
  } = useAuth();
  const classes = useStyles();
  const location = useLocation();

  const queryClient = useQueryClient();
  const [showExampleReport, setShowExampleReport] = useState<boolean>(false);

  const handleDeleteReport = useMutation(
    (reportId: string) => {
      if (axiosWithAuth === undefined) return;

      return axiosWithAuth({
        method: "DELETE",
        url: `/preferences/report/${reportId}`,
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries("reports"),
    }
  );

  const { data: myReports, isLoading } = useQuery("reports", () => {
    if (axiosWithAuth === undefined) return;
    return axiosWithAuth({
      url: `/my-reports`,
    }).then(({ data }) => {
      return data.map((item: any) => ({
        ...item,
        reportOwner: user.name,
      }));
    });
  });

  useEffect(() => {
    setShowExampleReport(location.search.indexOf("exampleReport") !== -1);
    queryClient.invalidateQueries("reports");
  }, [location, myReports]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.filterContainer}>
            <div className={classes.filterItem}>
              <Search />
              <InputBase
                className={classes.input}
                placeholder="SEARCH HERE"
                inputProps={{ "aria-label": "SEARCH HERE" }}
              />
            </div>
            <Divider />

            <div className={classes.selectsContainer}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-controlled-open-select-label">
                  Fund Names
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-controlled-open-select-label">
                  Data Points
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Paper>
        </Grid>
        <TabView
          title="All Reports"
          isLoading={isLoading}
          buttonGroup={
            <div className={classes.root}>
              <Button
                disableElevation
                style={{ padding: 12 }}
                variant="outlined"
                component={Link}
                to={`/store/analytics`}
              >
                View Analytics
              </Button>
              <Button
                color="primary"
                disableElevation
                style={{ padding: 12 }}
                variant="contained"
                startIcon={<PlusIcon />}
                component={Link}
                to={`/my-reports/create-report`}
              >
                Create Report
              </Button>
            </div>
          }
        >
          {showExampleReport || myReports?.length > 0 ? (
            <Box p={10}>
              <MyReportsTable
                handleDeleteReport={handleDeleteReport}
                tableData={
                  showExampleReport ? [exampleDashboardReport] : myReports
                }
              />
            </Box>
          ) : (
            <Typography variant="body1">
              You currently have no reports. To add a report go to{" "}
              <Typography
                component={Link}
                to="/data-library"
                style={{ color: "#3f51b5", fontWeight: "bold" }}
              >
                Data Library
              </Typography>
            </Typography>
          )}
        </TabView>
      </Grid>
    </React.Fragment>
  );
};

export default MyReports;
