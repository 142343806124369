import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const PdfIconInactive = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="25"
      height="30"
      viewBox="0 0 25 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.42066 23.368V24.9473H7.49707V20.4648H9.24573C9.58232 20.4648 9.87787 20.5264 10.1324 20.6496C10.3889 20.7727 10.586 20.9482 10.7235 21.176C10.861 21.4018 10.9297 21.6593 10.9297 21.9487C10.9297 22.388 10.7789 22.7348 10.4772 22.9893C10.1775 23.2418 9.76191 23.368 9.23033 23.368H8.42066ZM8.42066 22.6199H9.24573C9.48996 22.6199 9.67571 22.5624 9.80296 22.4475C9.93226 22.3325 9.99691 22.1683 9.99691 21.9549C9.99691 21.7353 9.93226 21.5578 9.80296 21.4223C9.67366 21.2868 9.4951 21.2171 9.26728 21.2129H8.42066V22.6199Z"
        fill="#2B2D36"
      />
      <path
        d="M11.567 24.9473V20.4648H12.9462C13.3403 20.4648 13.6923 20.5541 14.0022 20.7327C14.3142 20.9092 14.5574 21.1616 14.7318 21.49C14.9063 21.8164 14.9935 22.1878 14.9935 22.6045V22.8108C14.9935 23.2274 14.9073 23.5979 14.7349 23.9221C14.5646 24.2464 14.3234 24.4978 14.0114 24.6764C13.6995 24.855 13.3475 24.9453 12.9555 24.9473H11.567ZM12.4906 21.2129V24.2054H12.937C13.2982 24.2054 13.5743 24.0874 13.7651 23.8513C13.956 23.6153 14.0535 23.2777 14.0576 22.8385V22.6014C14.0576 22.1458 13.9632 21.801 13.7744 21.567C13.5856 21.331 13.3095 21.2129 12.9462 21.2129H12.4906Z"
        fill="#2B2D36"
      />
      <path
        d="M18.3646 23.1155H16.5913V24.9473H15.6677V20.4648H18.5863V21.2129H16.5913V22.3705H18.3646V23.1155Z"
        fill="#2B2D36"
      />
      <path
        d="M16.009 12.4073C15.4461 11.9642 14.911 11.5088 14.5547 11.1525C14.0915 10.6893 13.6788 10.2403 13.3201 9.81276C13.8796 8.08406 14.1248 7.19271 14.1248 6.71759C14.1248 4.6991 13.3955 4.28516 12.3005 4.28516C11.4685 4.28516 10.4762 4.71746 10.4762 6.7758C10.4762 7.68322 10.9733 8.78482 11.9584 10.0651C11.7174 10.8009 11.4341 11.6496 11.1158 12.6062C10.9625 13.0653 10.7963 13.4905 10.6205 13.8836C10.4774 13.9472 10.3384 14.0119 10.2042 14.079C9.72082 14.3207 9.26177 14.538 8.83597 14.7399C6.89405 15.6592 5.61133 16.2673 5.61133 17.4681C5.61133 18.3399 6.55854 18.8796 7.43564 18.8796C8.56631 18.8796 10.2736 17.3695 11.5207 14.8254C12.8153 14.3147 14.4247 13.9364 15.6949 13.6995C16.7128 14.4821 17.8369 15.231 18.3815 15.231C19.8893 15.231 20.2058 14.3592 20.2058 13.6282C20.2058 12.1905 18.5632 12.1905 17.7734 12.1905C17.5281 12.1905 16.8702 12.263 16.009 12.4073ZM7.43564 17.6635C7.08822 17.6635 6.85306 17.4995 6.82752 17.4681C6.82752 17.0369 8.1132 16.4277 9.35675 15.8386C9.43571 15.8012 9.51592 15.7637 9.59728 15.7251C8.68392 17.0495 7.78066 17.6635 7.43564 17.6635ZM11.6924 6.7758C11.6924 5.5014 12.0879 5.5014 12.3005 5.5014C12.7305 5.5014 12.9086 5.5014 12.9086 6.71759C12.9086 6.97413 12.7376 7.61549 12.4246 8.61675C11.9471 7.88156 11.6924 7.25149 11.6924 6.7758ZM12.1585 13.3117C12.1966 13.206 12.2334 13.0991 12.269 12.9911C12.4947 12.3141 12.6978 11.7059 12.8789 11.1584C13.1313 11.4363 13.4033 11.7208 13.6949 12.0124C13.8089 12.1264 14.0916 12.3829 14.4681 12.7042C13.7186 12.8675 12.9211 13.07 12.1585 13.3117ZM18.9896 13.6282C18.9896 13.9014 18.9896 14.0148 18.4255 14.0184C18.2598 13.9828 17.8767 13.7571 17.404 13.4353C17.5756 13.4163 17.7021 13.4068 17.7734 13.4068C18.6719 13.4068 18.9267 13.4946 18.9896 13.6282Z"
        fill="#2B2D36"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.870117 1.41016C0.870117 0.995943 1.2059 0.660156 1.62012 0.660156H19.5351C19.734 0.660156 19.9248 0.739174 20.0655 0.879826L24.7284 5.54273C24.869 5.68339 24.948 5.87415 24.948 6.07306V28.8965C24.948 29.3107 24.6123 29.6465 24.198 29.6465H1.62012C1.2059 29.6465 0.870117 29.3107 0.870117 28.8965V1.41016ZM2.37012 2.16016V28.1465H23.448V6.38372L19.2245 2.16016H2.37012Z"
        fill="#2B2D36"
      />
    </SvgIcon>
  );
};

/**
 * @param {SvgIconProps} props
 */
export const PdfIconActive = (props) => {
  return (
    <SvgIcon
      {...props}
      width="25"
      height="30"
      viewBox="0 0 25 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.37573 23.368V24.9473H7.45215V20.4648H9.2008C9.5374 20.4648 9.83295 20.5264 10.0874 20.6496C10.344 20.7727 10.541 20.9482 10.6785 21.176C10.8161 21.4018 10.8848 21.6593 10.8848 21.9487C10.8848 22.388 10.734 22.7348 10.4323 22.9893C10.1326 23.2418 9.71699 23.368 9.18541 23.368H8.37573ZM8.37573 22.6199H9.2008C9.44504 22.6199 9.63079 22.5624 9.75804 22.4475C9.88734 22.3325 9.95199 22.1683 9.95199 21.9549C9.95199 21.7353 9.88734 21.5578 9.75804 21.4223C9.62873 21.2868 9.45017 21.2171 9.22236 21.2129H8.37573V22.6199Z"
        fill="#2B2D36"
      />
      <path
        d="M11.5221 24.9473V20.4648H12.9013C13.2954 20.4648 13.6474 20.5541 13.9573 20.7327C14.2692 20.9092 14.5125 21.1616 14.6869 21.49C14.8614 21.8164 14.9486 22.1878 14.9486 22.6045V22.8108C14.9486 23.2274 14.8624 23.5979 14.69 23.9221C14.5196 24.2464 14.2785 24.4978 13.9665 24.6764C13.6545 24.855 13.3026 24.9453 12.9105 24.9473H11.5221ZM12.4457 21.2129V24.2054H12.8921C13.2533 24.2054 13.5293 24.0874 13.7202 23.8513C13.9111 23.6153 14.0086 23.2777 14.0127 22.8385V22.6014C14.0127 22.1458 13.9183 21.801 13.7295 21.567C13.5406 21.331 13.2646 21.2129 12.9013 21.2129H12.4457Z"
        fill="#2B2D36"
      />
      <path
        d="M18.3197 23.1155H16.5464V24.9473H15.6228V20.4648H18.5413V21.2129H16.5464V22.3705H18.3197V23.1155Z"
        fill="#2B2D36"
      />
      <path
        d="M15.9641 12.4073C15.4012 11.9643 14.8661 11.5088 14.5098 11.1525C14.0466 10.6893 13.6338 10.2403 13.2752 9.81276C13.8346 8.08405 14.0799 7.19271 14.0799 6.71759C14.0799 4.6991 13.3506 4.28516 12.2556 4.28516C11.4236 4.28516 10.4313 4.71746 10.4313 6.7758C10.4313 7.68322 10.9283 8.78482 11.9135 10.0651C11.6724 10.8009 11.3891 11.6496 11.0709 12.6062C10.9176 13.0653 10.7514 13.4905 10.5756 13.8836C10.4325 13.9472 10.2935 14.0119 10.1593 14.079C9.67589 14.3207 9.21685 14.538 8.79105 14.7399C6.84912 15.6592 5.56641 16.2673 5.56641 17.4681C5.56641 18.3399 6.51362 18.8796 7.39072 18.8796C8.52139 18.8796 10.2287 17.3695 11.4758 14.8254C12.7704 14.3147 14.3798 13.9364 15.65 13.6995C16.6678 14.4821 17.792 15.231 18.3366 15.231C19.8444 15.231 20.1609 14.3592 20.1609 13.6282C20.1609 12.1905 18.5183 12.1905 17.7285 12.1905C17.4832 12.1905 16.8253 12.263 15.9641 12.4073ZM7.39072 17.6635C7.0433 17.6635 6.80813 17.4995 6.78259 17.4681C6.78259 17.0369 8.06828 16.4277 9.31183 15.8386C9.39079 15.8012 9.471 15.7637 9.55235 15.7251C8.639 17.0495 7.73574 17.6635 7.39072 17.6635ZM11.6475 6.7758C11.6475 5.5014 12.043 5.5014 12.2556 5.5014C12.6855 5.5014 12.8637 5.5014 12.8637 6.71759C12.8637 6.97413 12.6927 7.61549 12.3797 8.61675C11.9022 7.88156 11.6475 7.25149 11.6475 6.7758ZM12.1136 13.3117C12.1517 13.206 12.1885 13.0991 12.2241 12.9911C12.4498 12.3141 12.6529 11.7059 12.834 11.1584C13.0864 11.4363 13.3584 11.7208 13.65 12.0124C13.764 12.1264 14.0467 12.3829 14.4232 12.7042C13.6736 12.8675 12.8761 13.07 12.1136 13.3117ZM18.9447 13.6282C18.9447 13.9014 18.9447 14.0148 18.3805 14.0184C18.2149 13.9828 17.8318 13.7571 17.3591 13.4353C17.5307 13.4163 17.6572 13.4068 17.7285 13.4068C18.627 13.4068 18.8818 13.4946 18.9447 13.6282Z"
        fill="#C410A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.825195 1.41016C0.825195 0.995943 1.16098 0.660156 1.5752 0.660156H19.4902C19.6891 0.660156 19.8799 0.739174 20.0205 0.879826L24.6835 5.54273C24.8241 5.68339 24.9031 5.87415 24.9031 6.07306V28.8965C24.9031 29.3107 24.5673 29.6465 24.1531 29.6465H1.5752C1.16098 29.6465 0.825195 29.3107 0.825195 28.8965V1.41016ZM2.3252 2.16016V28.1465H23.4031V6.38372L19.1796 2.16016H2.3252Z"
        fill="#C410A0"
      />
    </SvgIcon>
  );
};
