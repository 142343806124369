import {
  Chip,
  createStyles,
  Divider,
  FormControl,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { View } from "../../../../components";
import { useAuth } from "../../../../providers";
import OverflowTip from "../../../explorer/components/my-documents/components/OverflowTooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
      marginLeft: "auto",
      padding: "2vw",
    },
    filterContainer: {
      margin: "20px 0",
    },
    filterItem: {
      display: "flex",
      alignItems: "center",
      padding: 15,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    chipsContainer: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    selectsContainer: {
      display: "flex",
      alignItems: "center",
      padding: 15,
      "& > *": {
        marginRight: theme.spacing(2),
      },
    },
    formControl: {
      minWidth: 180,
    },
    liveAnnounce: {
      "& [class*=liveAnnounce]": {
        display: "none",
      },
      "& [aria-live=polite]": {
        display: "none",
      },
    },
  })
);

const Analytics = () => {
  const { axiosWithAuth } = useAuth();
  const classes = useStyles();

  const { data = [], isLoading } = useQuery("analyticsData", () => {
    if (axiosWithAuth === undefined) return;
    return axiosWithAuth({
      url: "/store/analytics",
      method: "GET",
    }).then((data) => {
      return data;
    });
  });

  const [selectedDataSources, setSelectedDataSources] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");

  const columns: any = useMemo(
    () => [
      {
        name: "dataSource",
        label: "Data Source",
        options: {
          filter: true,
        },
      },
      {
        name: "fieldName",
        label: "Field Name",
        options: {
          filter: true,
          customBodyRender: (value: string) => {
            return (
              <OverflowTip title={value}>
                <Typography
                  style={{
                    wordBreak: "break-word",
                    textOverflow: "ellipsis",
                    fontSize: "1vw",
                    minWidth: 250,
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {value}
                </Typography>
              </OverflowTip>
            );
          },
        },
      },
      {
        name: "description",
        label: "Description",
        options: {
          filter: true,
          customBodyRender: (value: string) => {
            return (
              <OverflowTip title={value}>
                <Typography
                  style={{
                    wordBreak: "break-word",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    fontSize: "1vw",
                  }}
                >
                  {value}
                </Typography>
              </OverflowTip>
            );
          },
        },
      },
      {
        name: "dataType",
        label: "Data Type",
        options: {
          filter: true,
        },
      },
      {
        name: "frequency",
        label: "Frequency",
        options: {
          filter: true,
        },
      },
      {
        name: "serviceProvider",
        label: "Service Provider",
        options: {
          filter: true,
          customBodyRender: (value: string) => {
            return <img alt="logo" src={value} width="120px" />;
          },
        },
      },

      {
        name: "owner",
        label: "Owner",
        options: {
          filter: true,
        },
      },
    ],
    []
  );

  const options: any = useMemo(
    () => ({
      download: false,
      filter: false,
      print: false,
      search: false,
      viewColumns: false,
      responsive: "standard",
      selectToolbarPlacement: "none",
      elevation: 0,
      pagination: false,
      selectableRows: "none",
      rowsPerPage: 10,
    }),

    []
  );

  const filteredData = data.filter((row: any) =>
    selectedDataSources?.length !== 0
      ? selectedDataSources?.includes(row.dataSource)
      : row
  );

  const searchFilteredData = useMemo(
    () =>
      filteredData.filter(
        (row: any) =>
          row.dataSource.toLowerCase().includes(search) ||
          row.fieldName.toLowerCase().includes(search)
      ),
    [filteredData, search]
  );

  if (isLoading) return <View isLoading={isLoading} />;

  const uniqueDataSources: string[] = data && [
    ...new Set(data.map((row: any) => row.dataSource)),
  ];

  return (
    <div data-tour="store-analytics">
      <Paper
        className={classes.filterContainer}
        data-tour="analytics-filtering"
      >
        <div className={classes.filterItem}>
          <Search />
          <InputBase
            className={classes.input}
            placeholder="SEARCH HERE"
            value={search}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setSearch(event.target.value)
            }
            inputProps={{ "aria-label": "SEARCH HERE" }}
          />
        </div>

        <Divider />
        <div className={classes.selectsContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel id="data-source-select-label">Data Source</InputLabel>
            <Select
              labelId="data-source-select-label"
              multiple
              value={selectedDataSources}
              id="data-source-select"
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setSelectedDataSources(event.target.value as string[])
              }
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as string[]).map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {uniqueDataSources.map((dataSource: string) => {
                return (
                  <MenuItem key={dataSource} value={dataSource}>
                    <em>{dataSource}</em>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </Paper>

      <Paper className={classes.liveAnnounce} data-tour="analytics-table">
        <MUIDataTable
          title={<Typography variant="h5">Analytics</Typography>}
          data={searchFilteredData}
          columns={columns}
          options={options}
        />
      </Paper>
    </div>
  );
};

export default Analytics;
