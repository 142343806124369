import React, { useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Popover,
  Select,
  Typography,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { WidgetDict } from "../Dashboard";
import { useAuth } from "../../../providers";
import { useQueryClient } from "react-query";
import { WidgetWrapperProps } from "../../../common/types";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: `${theme.spacing(2)}px 0 0`,
    padding: theme.spacing(2),
  },
  formControl: {
    margin: `${theme.spacing(1)}px 0`,
    width: "100%",
  },
  title: {
    fontSize: "1.4vw",
    margin: 0,
    flex: 1,
  },
  widgetMenu: {
    minWidth: "200px",
    padding: theme.spacing(2),
  },
}));

export const WidgetWrapper = (props: WidgetWrapperProps) => {
  const { children, data, title } = props;
  const { axiosWithAuth } = useAuth();
  const queryClient = useQueryClient();
  const classes = useStyles();
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [updatingPreferences, setUpdatingPreferences] = useState<boolean>(
    false
  );

  const handleWidgetChange = async (updateData: any) => {
    setUpdatingPreferences(true);
    await axiosWithAuth!({
      url: `/preferences/dashboard/${data.dashboardId}/${data._id}`,
      method: "PUT",
      data: updateData,
    });
    setUpdatingPreferences(false);
    queryClient.invalidateQueries("dashboards");
  };

  return (
    <Paper elevation={0} square className={classes.container}>
      <Box display="flex" alignItems="center">
        <Typography variant="h5" gutterBottom className={classes.title}>
          {title}
        </Typography>
        <IconButton
          aria-label="Configure Widget"
          title="Configure Widget"
          onClick={(event: any) => setPopoverAnchor(event.currentTarget)}
        >
          <MoreVert />
        </IconButton>
        <Popover
          open={popoverAnchor !== null}
          anchorEl={popoverAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setPopoverAnchor(null)}
          disableRestoreFocus
        >
          <Paper className={classes.widgetMenu}>
            {updatingPreferences ? <LinearProgress /> : null}
            <FormControl className={classes.formControl}>
              <InputLabel id={`${data._id}-widget-label`}>Widget</InputLabel>
              <Select
                disabled={updatingPreferences}
                labelId={`${data._id}-widget-label`}
                id={`${data._id}-widget-select`}
                value={data.name}
                onChange={(event: any) =>
                  handleWidgetChange({ name: event?.target.value })
                }
                fullWidth={true}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Object.keys(WidgetDict).map((name: string) => (
                  <MenuItem key={`${data._id}-${name}`} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id={`${data._id}-size-label`}>Size</InputLabel>
              <Select
                disabled={updatingPreferences}
                labelId={`${data._id}-size-label`}
                id={`${data._id}-size-select`}
                value={data.size}
                onChange={(event: any) =>
                  handleWidgetChange({ size: event?.target.value })
                }
                fullWidth={true}
              >
                {new Array(12).fill(undefined).map((nothing: any, i) => (
                  <MenuItem key={`${data._id}-size-${i}`} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Popover>
      </Box>
      {children}
    </Paper>
  );
};
