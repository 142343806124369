import React from "react";
import { Typography, Paper, Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { View } from "../../../../components";
import { euroFormatDateTime } from "../../../../helpers";
import { useAuth } from "../../../../providers";
import { useQuery } from "react-query";
import MUIDataTable from "mui-datatables";

interface ParamTypes {
  reportId: string;
}

const Report = () => {
  const { axiosWithAuth } = useAuth();
  const { reportId } = useParams<ParamTypes>();

  const { data, isLoading } = useQuery(["reports", reportId], async () => {
    if (axiosWithAuth === undefined) return;
    const allReports = await axiosWithAuth({
      url: `/my-reports`,
    });

    const selectedReport = allReports.data.find(
      (report: any) => report._id === reportId
    );

    const data = await axiosWithAuth({
      url: "/dashboard/inspector/detail/",
      params:
        selectedReport.selectedDataSource === "Fundamental Data"
          ? {
              options: [{ type: "Country", match: "United States" }],
              filters: { country: [], sector: [], currency: ["USD"] },
            }
          : selectedReport.selectedDataSource === "ESG Data" && {
              filters: { country: [], sector: [], currency: [] },
            },
    }).then((data) =>
      selectedReport.selectedDataSource === "Fundamental Data"
        ? data.holdings
        : selectedReport.selectedDataSource === "ESG Data" &&
          data.exposures.map((item) => ({
            ...item,
            environmentalData: item.esg.EnvironmentalData[0].Fundvalue,
            socialData: item.esg.SocialData[0].Fundvalue,
          }))
    );

    const filteredData = data.filter((row: any) =>
      selectedReport.selectedDataItems?.includes(
        row[selectedReport.dataItemsKey]
      )
    );

    const columns = [
      ...selectedReport.selectedDataPoints.map(
        (selectedDataPoint: { name: string; id: string; label: string }) => ({
          name: selectedDataPoint.name || selectedDataPoint.id,
          label: selectedDataPoint.label,
        })
      ),
    ];

    return { selectedReport, filteredData, columns };
  });

  const options: any = {
    download: true,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    responsive: "simple",
    selectToolbarPlacement: "none",
    elevation: 0,
    pagination: false,
    selectableRows: "none",
    rowsPerPage: 10,
  };

  return (
    <View isLoading={isLoading}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        mb={2}
      >
        <Typography variant="h4">{data?.selectedReport.reportName}</Typography>
      </Box>
      <Paper
        style={{
          padding: "50px",
        }}
      >
        <Box display="flex" flexDirection="row-reverse" mb={2}>
          <Typography variant="h6">
            Report Created on {euroFormatDateTime(Date())}
          </Typography>
        </Box>
        <MUIDataTable
          data={data?.filteredData}
          title=""
          columns={data?.columns}
          options={options}
        />
      </Paper>
    </View>
  );
};

export default Report;
