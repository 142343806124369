import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  InputBase,
  makeStyles,
  Typography,
  Box,
  IconButton,
  Button,
  Paper,
  Slide,
  Avatar,
  createMuiTheme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import DateRangeIcon from "@material-ui/icons/DateRange";

import {
  DocumentMetadataType,
  UserBehaviour,
} from "../../../../../common/types";
import {
  isWithinInterval,
  subMonths,
  startOfMonth,
  isSameQuarter,
  startOfYear,
  isSameYear,
} from "date-fns";
import { useDocuments } from "../Documents";
import { DocumentsTable } from "./DocumentsTable";
import { IconTabs } from "./IconTabs";
import { DocumentTypeFilter } from "./DocumentTypeFilters";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";
import DocumentTile from "./DocumentTile";
import { useAuth } from "../../../../../providers";
import { downloadFile } from "../../../../../helpers";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}
const materialTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        fontSize: "16px",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    backgroundColor: "white",
    marginBottom: "25px",
    width: "100%",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: "15px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export const DocumentsMain = ({
  investorId,
  userType,
  userBehaviour,
}: {
  investorId: number | string | undefined;
  userType: number | undefined;
  userBehaviour: UserBehaviour | undefined;
}) => {
  const { axiosWithAuth } = useAuth();
  const { selectedDocType, docs } = useDocuments();
  const [filterHide, setFilterHide] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedTab, setSelectedTab] = React.useState(0);
  // TODO:  put this back to 3 months
  const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(
    subMonths(new Date(), 6)
  );
  const [selectedToDate, setSelectedToDate] = useState<Date | null>(new Date());
  const [selectedMonths, setSelectedMonths] = useState<any>([]);
  const [selectedQuarters, setSelectedQuarters] = useState<any>([]);
  const [selectedYears, setSelectedYears] = useState<any>([]);
  const [portfolioManagers, setPortfolioManagers] = useState<any>({});
  const [investors, setInvestors] = useState<any>({
    options: [],
    selection: "",
  });
  const [filteredDocuments, setFilteredDocuments] = useState<
    DocumentMetadataType[]
  >([]);

  const [isDownloading, setIsDownloading] = useState<string | null>(null);

  const resetFilter = useCallback(() => {
    setSelectedMonths([]);
    setSelectedQuarters([]);
    setSelectedYears([]);

    const investorsArray = docs.reduce(
      (newInvestors: string[], doc: DocumentMetadataType) => {
        return doc.investorName.includes(",")
          ? [...newInvestors, ...doc.investorName.split(",")]
          : [...newInvestors, doc.investorName];
      },
      []
    );
    const uniqueInvestors: any = [...new Set(investorsArray)];

    setInvestors({
      options: uniqueInvestors,
      selection: "",
    });

    const uniquePortfolioManagers: any = [
      ...new Set(
        docs.map((doc: DocumentMetadataType) => doc.portfolioManagerShortName)
      ),
    ];
    setPortfolioManagers({
      options: uniquePortfolioManagers,
      selection: "",
    });
    // TODO:  put this back to 3 months
    const defaultDate =
      selectedDocType === "Board Meeting Manager"
        ? new Date("June 30 2020")
        : subMonths(new Date(), 12);
    setSelectedFromDate(defaultDate);
    setSelectedToDate(new Date());
  }, [docs]);

  useEffect(() => {
    resetFilter();
  }, [selectedDocType, resetFilter]);

  useEffect(() => {
    // used to ignore the default date and time
    // filtering for certain document types
    const ignoreDateTimeFilters = ["Fund Supplements"];
    let filteredDocs = docs;
    if (selectedMonths.length !== 0) {
      filteredDocs = filteredDocs.filter((doc: DocumentMetadataType) => {
        return selectedMonths.includes(
          startOfMonth(new Date(doc.documentActiveFromDate)).toString()
        );
      });
    } else if (selectedQuarters.length !== 0) {
      // Checks if doc.documentActiveFromDate is in same quarter as
      // any of the dates in the selectedQuarters array
      filteredDocs = filteredDocs.filter((doc: DocumentMetadataType) => {
        const isInSameQuarter = selectedQuarters.reduce(
          (sameQuarter: boolean, quarter: Date | string) => {
            return isSameQuarter(
              new Date(quarter),
              new Date(doc.documentActiveFromDate)
            )
              ? true
              : sameQuarter;
          },
          false
        );

        return isInSameQuarter;
      });
    } else if (selectedYears.length !== 0) {
      filteredDocs = filteredDocs.filter((doc: DocumentMetadataType) => {
        const isInSameYear = selectedYears.reduce(
          (sameYear: boolean, year: Date | string) => {
            return isSameYear(
              new Date(year),
              new Date(doc.documentActiveFromDate)
            )
              ? true
              : sameYear;
          },
          false
        );

        return isInSameYear;
      });
    } else {
      if (!ignoreDateTimeFilters.includes(selectedDocType))
        filteredDocs = filteredDocs.filter((doc: DocumentMetadataType) => {
          return isWithinInterval(new Date(doc.documentActiveFromDate), {
            start:
              // TODO:  re-work this to a better solution
              selectedDocType === "Financial Statement"
                ? startOfYear(selectedFromDate!)
                : selectedFromDate!,
            end: selectedToDate!,
          });
        });
    }
    if (portfolioManagers.selection !== "") {
      filteredDocs = filteredDocs.filter(
        (doc: DocumentMetadataType) =>
          doc.portfolioManagerShortName === portfolioManagers.selection
      );
    }
    if (investors.selection !== "") {
      filteredDocs = filteredDocs.filter((doc: DocumentMetadataType) =>
        doc.investorName.includes(investors.selection)
      );
    }
    setFilteredDocuments(filteredDocs);
  }, [
    portfolioManagers.selection,
    investors.selection,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    selectedFromDate,
    selectedToDate,
    selectedDocType,
    docs,
  ]);

  const classes = useStyles();

  const initials = selectedDocType
    .replace(/[^a-zA-Z- ]/g, "")
    .match(/\b\w/g)
    .join("");

  const filteredDocs = filteredDocuments;
  const searchFilteredDocs = filteredDocs
    .filter(
      (doc: DocumentMetadataType) =>
        doc.documentName.toLowerCase().includes(search) ||
        doc.documentActiveFromDate.toString().toLowerCase().includes(search)
    )
    .sort((a: any, b: any) => {
      let rv;
      rv =
        new Date(b.documentActiveFromDate).valueOf() -
        new Date(a.documentActiveFromDate).valueOf();

      if (rv === 0) {
        rv =
          a.documentName.localeCompare(b.documentName) +
          a.documentSk -
          b.documentSk;
      }
      return rv;
    });

  const handleOnClick = async (
    documentName: string,
    documentSk: number,
    documentId: string
  ) => {
    if (axiosWithAuth === undefined) return;
    setIsDownloading(documentName);
    let data;
    try {
      data = await axiosWithAuth({
        method: "GET",
        url: `/documents/download/${documentName}`,
        responseType: "blob",
      });
    } catch (err) {
      if (err.response!.status === 404) {
        // download blank pdf for demo if nothing exists
        documentName = "blank.pdf";
        data = await axiosWithAuth({
          url: `/documents/download/blank.pdf`,
          method: "GET",
          responseType: "blob",
        });
      }
    } finally {
      setIsDownloading(null);
      downloadFile(data, documentName);
    }
  };

  return (
    <Box ml={"250px"} width="100%" overflow="hidden">
      <Box p={2} display="flex" justifyContent="space-between">
        <Box>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              style={{
                width: "25px",
                height: "25px",
                fontSize: "0.8rem",
                marginRight: "10px",
              }}
            >
              {selectedDocType === "All Documents" ? (
                <SelectAllIcon fontSize="small" />
              ) : selectedDocType === "Recent Documents" ? (
                <DateRangeIcon fontSize="small" />
              ) : (
                initials
              )}
            </Avatar>
            <Typography variant="h6">{selectedDocType}</Typography>
          </div>
          <IconTabs
            value={selectedTab}
            setValue={(value: number) => setSelectedTab(value)}
          />
        </Box>

        <Box width={600}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(event) => {
                const { value } = event.target;
                setSearch(value.toString().toLowerCase());
              }}
            />
          </div>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: "10px" }}
              startIcon={<FilterListIcon />}
              onClick={() => setFilterHide((prevState) => !prevState)}
            >
              Filter
            </Button>
          </Box>
        </Box>
      </Box>
      <Grid container data-tour="documents">
        {searchFilteredDocs.length === 0 ? (
          <Grid item xs={9}>
            <Typography>
              There are currently no documents to show based on the current
              filters or search criteria.
            </Typography>
          </Grid>
        ) : selectedTab === 0 ? (
          <Grid
            item
            xs
            container
            spacing={2}
            style={{
              marginLeft: "8px",
              marginRight: "8px",
              height: "fit-content",
            }}
          >
            {searchFilteredDocs.map((doc: DocumentMetadataType) => {
              return (
                <React.Fragment key={doc.documentSk}>
                  <DocumentTile
                    handleOnClick={handleOnClick}
                    doc={doc}
                    filterHide={filterHide}
                    isDownloading={isDownloading}
                  />
                </React.Fragment>
              );
            })}
          </Grid>
        ) : selectedTab === 1 ? (
          <Grid item xs container>
            <DocumentsTable
              data={searchFilteredDocs}
              handleOnClick={handleOnClick}
              isDownloading={isDownloading}
            />
          </Grid>
        ) : null}
        <Slide direction="left" in={filterHide} mountOnEnter unmountOnExit>
          <Grid item xs={3} data-tour="documents-filter">
            <Paper
              elevation={0}
              style={{ minWidth: "100%", height: "fit-content" }}
            >
              <Box
                display="flex"
                px={3}
                py={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" color="primary">
                  Filters
                </Typography>
                <IconButton
                  onClick={() => setFilterHide((prevState) => !prevState)}
                  aria-label="delete"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box
                display="flex"
                px={3}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  color="primary"
                  onClick={() => {
                    resetFilter();
                  }}
                >
                  Reset
                </Button>
              </Box>
              <ThemeProvider theme={materialTheme}>
                <DocumentTypeFilter
                  userBehaviour={userBehaviour}
                  selectedDocType={selectedDocType}
                  setSelectedFromDate={setSelectedFromDate}
                  setSelectedToDate={setSelectedToDate}
                  selectedFromDate={selectedFromDate}
                  selectedToDate={selectedToDate}
                  selectedMonths={selectedMonths}
                  setSelectedMonths={setSelectedMonths}
                  selectedQuarters={selectedQuarters}
                  setSelectedQuarters={setSelectedQuarters}
                  selectedYears={selectedYears}
                  setSelectedYears={setSelectedYears}
                  portfolioManagers={portfolioManagers}
                  setPortfolioManagers={setPortfolioManagers}
                  investors={investors}
                  setInvestors={setInvestors}
                />
              </ThemeProvider>
            </Paper>
          </Grid>
        </Slide>
      </Grid>
    </Box>
  );
};
