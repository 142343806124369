import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";

import { DocumentMetadataType } from "../../../../common/types";

import { DocumentsSidebar } from "./components/DocumentsSideBar";
import { DocumentsMain } from "./components/DocumentsMain";

interface DocumentsContextType {
  value?: any;
  docs: DocumentMetadataType[];
  updateSelectedDocType: Function;
  selectedDocType: string;
  userGroups: string[];
  updateDocument: Function;
  updateDocuments: Function;
}

const DocumentsContext = createContext<DocumentsContextType | undefined>(
  undefined
);
DocumentsContext.displayName = "DocumentsContext";

const Documents = (props: {
  docs: DocumentMetadataType[];
  children: React.ReactNode;
  userGroups: string[];
  setDocs: Function;
}) => {
  const { userGroups } = props;
  const [docs, setDocs] = useState<any>({
    "Recent Documents": [],
    "All Documents": [],
    "Actuarial Valuation": [],
    "Board Meeting Manager": [],
  });
  const location = useLocation();
  const defaultDocTypeMatch = location.search.match(/defaultDocType=([^&]+)/);
  const [selectedDocType, setSelectedDocType] = React.useState<string>(
    defaultDocTypeMatch
      ? decodeURI(defaultDocTypeMatch[1])
      : "Actuarial Valuation"
  );

  const updateSelectedDocType = (docType: string) => {
    setSelectedDocType(docType);
  };

  const updateDocument = (doc: DocumentMetadataType, docType: string) => {
    const { documentSk } = doc;
    const filteredDocs = [
      ...docs[docType].filter(
        (doc: DocumentMetadataType) => doc.documentSk !== documentSk
      ),
      doc,
    ];

    setDocs({ ...docs, [docType]: filteredDocs });
  };
  const updateDocuments = (
    documents: DocumentMetadataType[],
    docType: string
  ) => {
    const documentSks = documents.map((doc) => doc.documentSk);
    const filteredDocs = [
      ...docs[docType].filter((doc: DocumentMetadataType) => {
        return !documentSks.includes(doc.documentSk);
      }),
      ...documents,
    ];

    setDocs({ ...docs, [docType]: filteredDocs });
  };

  useEffect(() => {
    // Get unique document types from array of docs
    const uniqueDocTypes = [
      ...new Set(
        props.docs.map((doc: DocumentMetadataType) => doc.documentTypeName)
      ),
    ];

    const recentDocsFilterTypes: string[] = [
      "Factsheet",
      "Contract Notes",
      "QIR",
      "Investment Statement",
    ];

    const recentDocuments = recentDocsFilterTypes.reduce(
      (recentDocs: DocumentMetadataType[], docType: string) => {
        const filteredRecentDocs = props.docs
          .filter((doc) => doc.documentTypeName === docType)
          .sort((a, b) => {
            // TODO: Check which date should be used for this
            const dateA: Date = new Date(a.documentCreatedDatetime);
            const dateB: Date = new Date(b.documentCreatedDatetime);
            return dateB.valueOf() - dateA.valueOf();
          })
          .slice(0, 2);

        return [...recentDocs, ...filteredRecentDocs];
      },
      []
    );

    // create new object with keys as document types
    // where the values are documents filtered on that document type
    const newDocuments = {
      ...uniqueDocTypes.reduce((newDocs, currDocType) => {
        return {
          ...newDocs,
          [currDocType]: props.docs.filter(
            (doc) => doc.documentTypeName === currDocType
          ),
        };
      }, {}),
      "Recent Documents": recentDocuments,
      "All Documents": props.docs,
    };

    setDocs(newDocuments);
  }, [props.docs]);

  const value: DocumentsContextType = {
    docs: docs[selectedDocType],
    updateDocument,
    updateDocuments,
    updateSelectedDocType,
    selectedDocType,
    userGroups,
  };
  return (
    <DocumentsContext.Provider value={value} {...props}>
      <Box
        display="flex"
        style={{ backgroundColor: "transparent" }}
        data-tour="documents-container"
      >
        {props.children}
      </Box>
    </DocumentsContext.Provider>
  );
};

const useDocuments = () => useContext<any>(DocumentsContext);

export { Documents, DocumentsSidebar, DocumentsMain, useDocuments };
