import React, { Fragment, ReactNode, useEffect, useState } from "react";
import {
  AppInsightsContext,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { Disclaimer, setupAppInsights } from "@amx/web-components";
import "./App.css";

import {
  generateSideBarConfig,
  Header,
  MainPanel,
  SideBar,
} from "./components";

import { Apps } from "./pages";

import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { Error404 } from "./errors";
import { useAuth } from "./providers";
import { Explorer } from "./pages/explorer/Explorer";
import { Connect } from "./pages/connect/Connect";
import CreateReport from "./pages/explorer/components/my-reports/create-report/CreateReport";
import Report from "./pages/explorer/components/my-reports/Report";
import Store from "./pages/store/Store";
import { Dashboards } from "./pages/dashboards/Dashboards";
import DataOperations from "./pages/store/DataOperations";
import FundDetail from "./pages/store/components/funds/detail/FundDetail";
import { Settings } from "./pages/Settings/Settings";

const AuthenticatedRoute = ({
  allowedRoles,
  children,
  path,
  exact,
  userPermissions,
}: {
  children: ReactNode;
  allowedRoles: string[];
  path: string | string[];
  exact?: boolean;
  userPermissions: string[];
}) => {
  const isAllowed = allowedRoles.some(
    (role) => userPermissions.indexOf(role) >= 0
  );

  return (
    <Route path={path} exact={exact}>
      {isAllowed ? children : <Redirect to="/error" />}
    </Route>
  );
};

const App = () => {
  const { authState, logout } = useAuth();
  const [appInsightsReactPlugin, setAppInsightsReactPlugin] = useState<
    ReactPlugin
  >();

  useLocation(); // NOTE:  If this line is removed then the app does not re-render and the selected sidebar item does not highlight properly.  Possible  Strange BuG

  const navConfig = generateSideBarConfig({
    permissions: (authState.permissions as [string]) || [],
    currentPath: window.location.pathname,
    currentApp: "AMXConnect",
  });

  // Get user initials
  const { name } = authState.user;
  var initials = name !== undefined ? name.match(/\b\w/g) : [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();

  useEffect(() => {
    if (authState.appInsightsKey) {
      const { reactPlugin } = setupAppInsights(authState.appInsightsKey, {
        application: "Phoenix",
      });
      setAppInsightsReactPlugin(reactPlugin);
    }
  }, [authState.appInsightsKey]);

  const renderedApp = (
    <Fragment>
      <Header onUserMenuSelect={logout} initials={initials} />
      <MainPanel>
        <React.Fragment>
          <SideBar navConfig={navConfig} />
          <Switch>
            <Route path="/error">
              <Error404 />
            </Route>
            <AuthenticatedRoute
              exact
              path="/store/funds/detail/:fundId/:detailTab?"
              allowedRoles={["demo-global-reader"]}
              userPermissions={authState.permissions}
            >
              <FundDetail />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path="/store"
              allowedRoles={["demo-global-reader"]}
              userPermissions={authState.permissions}
            >
              <Store />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              path="/connect"
              allowedRoles={["demo-global-reader"]}
              userPermissions={authState.permissions}
            >
              <Connect />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path="/explorer"
              allowedRoles={["demo-global-reader"]}
              userPermissions={authState.permissions}
            >
              <Explorer />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path="/settings"
              allowedRoles={["demo-global-reader"]}
              userPermissions={authState.permissions}
            >
              <Settings />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              exact
              path="/my-reports/create-report"
              allowedRoles={["demo-global-reader"]}
              userPermissions={authState.permissions}
            >
              <CreateReport />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path="/reports/:reportId"
              allowedRoles={["demo-global-reader"]}
              userPermissions={authState.permissions}
            >
              <Report />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path="/apps/data-operations"
              allowedRoles={["demo-global-reader"]}
              userPermissions={authState.permissions}
            >
              <DataOperations />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path="/apps"
              allowedRoles={["demo-global-reader"]}
              userPermissions={authState.permissions}
            >
              <Apps />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path="/dashboards"
              allowedRoles={["demo-global-reader"]}
              userPermissions={authState.permissions}
            >
              <Dashboards />
            </AuthenticatedRoute>

            <Route path="/"></Route>
          </Switch>
        </React.Fragment>
      </MainPanel>
      <Disclaimer disabled={false} />
    </Fragment>
  );

  return appInsightsReactPlugin ? (
    <AppInsightsContext.Provider value={appInsightsReactPlugin}>
      {renderedApp}
    </AppInsightsContext.Provider>
  ) : (
    renderedApp
  );
};

export default App;
