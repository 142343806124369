import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { GenericTableColumn } from "../../common/types";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

type Props = {
  columns: Array<GenericTableColumn>;
  tableData: any;
  rowClickHandler?: Function;
  options?: {
    shadedHeader?: boolean;
  };
};

export default function GenericTable({
  columns,
  rowClickHandler,
  tableData,
  options = { shadedHeader: true },
}: Props) {
  const classes = useStyles();
  const { shadedHeader } = options;

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column: GenericTableColumn) => {
              return (
                <TableCell
                  key={column.id}
                  align={column.align || "left"}
                  style={
                    shadedHeader
                      ? {
                          backgroundColor: "#ececec",
                          color: "black",
                        }
                      : {}
                  }
                >
                  {column.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row: any, index: number) => (
            <TableRow
              key={index}
              onClick={() => {
                if (rowClickHandler) {
                  rowClickHandler(row);
                }
              }}
            >
              {columns.map((column: GenericTableColumn) => {
                const value = row[column.id];
                const formattedValue = column.format
                  ? column.format(value)
                  : value;

                return (
                  <TableCell
                    key={column.id}
                    align={column.align || "left"}
                    scope="row"
                    style={{
                      color: formattedValue === "ALERT" ? "red" : "",
                      cursor: rowClickHandler ? "pointer" : "default",
                    }}
                  >
                    {column.link ? (
                      <Typography component={Link} to={`/my-reports/${row.id}`}>
                        {formattedValue}
                      </Typography>
                    ) : (
                      formattedValue
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
