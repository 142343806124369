import React from "react";
import { createContext, useState, useContext, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

interface Service {
  id: string;
  serviceName: string;
  description: string;
  type: string;
  serviceProvider: string;
  logo: string;
  validation: string;
  purchased: boolean;
}

type ServiceContextType = {
  serviceData: Service[];
  setServiceData: (value: Service[]) => void;
};

const ServiceContext = createContext<ServiceContextType>(
  {} as ServiceContextType
);

export const ServiceContextProvider = ({ children }: Props) => {
  const [serviceData, setServiceData] = useState<Service[]>([
    {
      id: "1",
      serviceName: "ESG",
      description: "ESG scores for equity holdings",
      type: "Data",
      serviceProvider: "Owl analytics",
      logo: "/images/owl-analytics.png",
      validation: "No",
      purchased: false,
    },
    {
      id: "2",
      serviceName: "Fundamental Data",
      description: "Data for equity holdings",
      type: "Data",
      serviceProvider: "Refinitive",
      logo: "/images/refinitiv.png",
      validation: "No",
      purchased: false,
    },
    {
      id: "3",
      serviceName: "Fintech",
      description: "Portfolio management systems",
      type: "App",
      serviceProvider: "Alpima",
      logo: "/images/alpima-logo.png",
      validation: "No",
      purchased: false,
    },
    {
      id: "4",
      serviceName: "Fintech",
      description: "Macro and equity research",
      type: "App",
      serviceProvider: "Butterwire",
      logo: "/images/butterwire.png",
      validation: "No",
      purchased: false,
    },
  ]);

  return (
    <ServiceContext.Provider value={{ serviceData, setServiceData }}>
      {children}
    </ServiceContext.Provider>
  );
};

export const useService = () => useContext<ServiceContextType>(ServiceContext);
