import React, { useEffect } from "react";
import { Box, Grid, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  gridWrap: {
    marginTop: "30px",
    marginBottom: "30px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  bodyText: {
    marginBottom: "2em",
    textAlign: "center",
  },

  formControl: {
    marginRight: "2em",
    "& label": {
      fontSize: "1.1em",
      fontWeight: "bold",
    },
    "& .Mui-disabled": {
      color: "rgba(0, 0, 0, 0.7)",
    },
  },

  multiSelect: {
    width: "200px",
  },

  checbox: {
    padding: "0 10px 0 0",
    "&.Mui-checked": {
      color: "#7B059F",
    },
  },
}));

const dataList1 = ["FUND A, 30M", "FUND B, 28M", "FUND C, 23M"];

const dataList2 = ["FUND A, 30M", "FUND B, 28M", "FUND C, 23M"];

const Step5 = () => {
  const [list1, setList1] = React.useState([]);
  const [list2, setList2] = React.useState([]);
  const classes = useStyles();

  const handleChange = (event, list) => {
    if (list === "list1") {
      setList1(event.target.value as string[]);
    } else {
      setList2(event.target.value as string[]);
    }
  };

  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }

        return oldProgress + 10;
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Grid className={classes.gridWrap}>
      <Grid item xs={12}>
        <Box
          justifyContent="center"
          display="flex"
          className={classes.bodyText}
        >
          <Typography component="p" variant="inherit">
            AMX Connect allows you to build your own investment managment
            capabilities, with all the simplicity of Lego, brick by brick
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box justifyContent="center" display="flex" mb={2}>
          <Typography component="h4" variant="h6">
            Hold tight, just assembling your stack
          </Typography>
        </Box>
      </Grid>

      <Grid container>
        <Grid item xs={5}></Grid>
        <Grid item xs={2}>
          <LinearProgress variant="determinate" value={progress} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Step5;
