import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const NotificationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Path_524"
        data-name="Path 524"
        d="M14.123,3.192A2.4,2.4,0,0,0,12,.814,2.193,2.193,0,0,0,10.006,3.1,8.161,8.161,0,0,1,14.123,3.192Z"
        transform="translate(-0.93 -0.814)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
      <path
        id="Path_525"
        data-name="Path 525"
        d="M8.874,20.584a2.358,2.358,0,0,0,.719,1.668,3.392,3.392,0,0,0,2.4.935,2.907,2.907,0,0,0,3.126-2.6A26.4,26.4,0,0,1,8.874,20.584Z"
        transform="translate(-0.948 2.509)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
      <path
        id="Path_526"
        data-name="Path 526"
        d="M24.649,21.082a6.175,6.175,0,0,0-.392-3.328l-.1-.106a6.67,6.67,0,0,0-.577-.6c-.823-.794-1.951-1.882-2.193-5.08-.328-4.249-3.093-8.55-7.74-8.55s-7.411,4.3-7.74,8.55c-.243,3.2-1.37,4.285-2.193,5.079a7.01,7.01,0,0,0-.577.6l-.069.106a6.194,6.194,0,0,0-.419,3.328,5.228,5.228,0,0,1-.014.6v.373L3,22.24c2.589,1.506,5.983,2.207,10.618,2.207s8.069-.7,10.659-2.207l.381-.182v-.373A5.236,5.236,0,0,1,24.649,21.082Z"
        transform="translate(-2.6 -1.334)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
    </SvgIcon>
  );
};
