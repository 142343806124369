import React, { createContext, useState, ReactNode, useContext } from "react";

type Props = {
  children: ReactNode;
};

type NotificationsContextType = {
  notificationsCount: number;
  setNotificationsCount: (value: number) => void;
  incrementNotificationsCount: () => void;
};

// Todo fix this so it's not any
const NotificationsContext = createContext<NotificationsContextType | any>(
  undefined
);

const { Provider } = NotificationsContext;

const initialCount = 0;
const NotificationsProvider = ({ children }: Props) => {
  const [notificationsCount, setNotificationsCount] = useState(initialCount);

  const incrementNotificationsCount = () => {
    setNotificationsCount((prev) => prev + 1);
  };

  return (
    <Provider
      value={{
        notificationsCount,
        incrementNotificationsCount,
      }}
    >
      {children}
    </Provider>
  );
};

const useNotifications = () => useContext(NotificationsContext);

export { useNotifications, NotificationsProvider };
