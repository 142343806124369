import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useState } from "react";
import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { CreateReportModal } from "./CreateReportModal";

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: "100%",
      maxWidth: "100%",
    },
  })
);

const BarChart = (props: any) => {
  const {
    tab,
    tabularData,
    selectedReport,
    tabularData: {
      selected,
      data: { dataTable },
    },
    tab: {
      reportType,
      data: { xAxis },
    },
  } = props;

  const classes = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(selected);

  let numberOnlyKeys: string[] = [];

  if (dataTable.data.length > 0) {
    numberOnlyKeys = Object.entries(dataTable.data[0]).reduce(
      (newArray: string[], [key, value]) => {
        if (typeof value === "number") {
          newArray.push(key);
        }
        return newArray;
      },
      []
    );
  }

  const yAxisSelectOptions =
    numberOnlyKeys
      .filter((key) => {
        return (
          key.slice(-2) !== "Sk" &&
          key.slice(-2) !== "Bk" &&
          key.slice(-4) !== "Flag"
        );
      })
      .sort() || [];

  const [selectedYaxis, setSelectedYaxis] = useState<any>(
    yAxisSelectOptions[0]
  );

  const filteredSeriesData = tabularData.data.dataTable.data
    .filter((item: any) => selectedValues.indexOf(item[item.key]) > -1)
    .sort();

  const filteredBarChartOptions = {
    ...tab.data,
    series: [
      {
        ...tab.data.series[0],
        data: filteredSeriesData.map((item: any) => item[selectedYaxis]),
      },
    ],
    xAxis: {
      ...tab.data.xAxis,
      categories: filteredSeriesData.map((item: any) => item[item.key]),
    },
    yAxis: {
      title: {
        text: selectedYaxis,
      },
    },
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="x-axis-select-label">{xAxis.title.text}</InputLabel>
            <Select
              labelId="x-axis-select-label"
              id="x-axis-select-multiple-checkbox"
              multiple
              label={xAxis.title.text}
              value={selectedValues}
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown;
                }>
              ) => setSelectedValues(event.target.value as string[])}
              renderValue={(selected: any) => (selected as string[]).join(", ")}
            >
              {tabularData.selector.map((fundName: string) => (
                <MenuItem key={fundName} value={fundName}>
                  <Checkbox checked={selectedValues.indexOf(fundName) > -1} />
                  <ListItemText primary={fundName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="y-axis-select-label">Category</InputLabel>
            <Select
              labelId="y-axis-select-label"
              id="y-axis-select"
              value={selectedYaxis}
              label="Category"
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown;
                }>
              ) => {
                setSelectedYaxis(event.target.value);
              }}
            >
              {yAxisSelectOptions.map((option: string) => (
                <MenuItem key={option} value={option}>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={4} justify="flex-end" alignItems="flex-end">
          <Button
            onClick={() => setOpenModal(true)}
            variant="contained"
            size="small"
            disabled={selectedValues.length === 0 || !selectedYaxis}
          >
            Add to My Reports
          </Button>
        </Grid>
        <Grid item xs={12}>
          {selectedValues.length === 0 || !selectedYaxis ? null : (
            <HighchartsReact
              highcharts={Highcharts}
              options={filteredBarChartOptions}
            />
          )}
        </Grid>
      </Grid>
      <CreateReportModal
        selectedReport={selectedReport}
        selectedOptions={{
          selectedFunds: selectedValues,
          selectedDatapoints: selectedYaxis,
        }}
        openModal={openModal}
        setOpenModal={(option: boolean) => setOpenModal(option)}
        reportType={reportType}
      />
    </>
  );
};

export default BarChart;
