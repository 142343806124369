import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import StepConnector from "@material-ui/core/StepConnector";

//Steps
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

import { Box, Button, Grid, Typography } from "@material-ui/core";
import { updateTourConfigStatus } from "../helpers";

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: "center",
    marginBottom: "1.5em",
  },
  subHeader: {
    padding: "0.5em 1em",
  },

  contentWrap: {
    backgroundColor: "#f7f9fc",
    padding: "1em 2em 4em",
  },
  outerWrap: {
    padding: "1em",
  },

  bodyText: {
    margin: "1em 1em 4em",
    textAlign: "center",
  },

  stepButton: {
    position: "absolute",
    backgroundColor: "#7B059F",
    padding: "0.5em 1.5em",
    fontSize: "0.75em",
    color: "#fff",
    "&:hover": {
      background: "#555",
      color: "#fff",
    },
  },
  backButton: {
    backgroundColor: "#fff",
    color: "#7B059F",
    border: "1px solid #7B059F",
    minWidth: "0",
    width: "30px",
    height: "30px",
    borderRadius: "30px",
    padding: 0,
    position: "absolute",
    left: "30px",

    "&:disabled": {
      display: "none",
    },
  },

  stepper: {
    backgroundColor: "transparent",
  },

  stepIcon: {
    color: "#fff",
    border: "1px solid #dcdcdc",
    borderRadius: "12px",
    height: "12px",
    width: "12px",

    "& text": {
      fill: "#000",
      fillOpacity: "0",
    },
  },

  stepIconActive: {
    color: "#7B059F !important",
  },

  stepIconCompleted: {
    backgroundColor: "#7B059F !important",
    color: "#7B059F !important",
    "& text": {
      fillOpacity: "0",
    },

    "& > *": {
      display: "none",
    },
  },

  connectorLine: {
    borderColor: "#7B059F",
  },

  connectorLabel: {
    borderColor: "#7B059F",
    top: "7px !important",
    left: "calc(-50% + 6px)",
    right: "calc(50% + 6px)",
  },
}));

export const Welcome = (props: any) => {
  const { handleComplete } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepper, setActiveStepper] = useState(0);
  const classes = useStyles();

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      return handleComplete();
    }

    updateTourConfigStatus("welcome", activeStep);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setActiveStepper(activeStep - 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setActiveStepper((prevActiveStepper) => prevActiveStepper - 1);
  };

  const steps = [1, 2, 3, 4, 5];

  const stepper = [1, 2, 3];

  const connector = (
    <StepConnector
      classes={{
        line: classes.connectorLine,
        alternativeLabel: classes.connectorLabel,
      }}
    />
  );

  const [startDisabled, setStartDisabled] = useState(false);
  useEffect(() => {
    if (activeStep === steps.length - 1) {
      setStartDisabled(true);

      const timer = setTimeout(() => {
        setStartDisabled(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setStartDisabled(false);
    }
  }, [activeStep, steps.length]);

  return (
    <Fragment>
      <Typography component="h3" variant="h5" className={classes.subHeader}>
        Welcome
      </Typography>
      <Box
        className={classes.contentWrap}
        justifyContent="center"
        display="flex"
      >
        <Grid container justify="center">
          <Fragment>
            <Grid item xs={12}>
              {activeStep >= 2 ? (
                <Stepper
                  activeStep={activeStepper}
                  alternativeLabel
                  className={classes.stepper}
                  connector={connector}
                >
                  {stepper.map((label, i) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconProps={{
                          classes: {
                            root: classes.stepIcon,
                            active: classes.stepIconActive,
                            completed: classes.stepIconCompleted,
                          },
                        }}
                      ></StepLabel>
                    </Step>
                  ))}
                </Stepper>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {activeStep === 0 && <Step1 />}
              {activeStep === 1 && <Step2 />}
              {activeStep === 2 && <Step3 />}
              {activeStep === 3 && <Step4 />}
              {activeStep === 4 && <Step5 />}
            </Grid>

            <Grid item xs={12}>
              <Box justifyContent="center" display="flex" position="relative">
                <Button
                  className={`${classes.stepButton} ${classes.backButton}`}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  <ChevronLeftIcon />
                </Button>
                <Button
                  variant="contained"
                  className={classes.stepButton}
                  onClick={handleNext}
                  disabled={startDisabled}
                >
                  {activeStep === 0 ? "start" : ""}
                  {activeStep >= 1 && activeStep < steps.length - 1
                    ? "Next"
                    : null}
                  {activeStep === steps.length - 1 ? "Start" : null}
                </Button>
              </Box>
            </Grid>
          </Fragment>
        </Grid>
      </Box>
    </Fragment>
  );
};
