import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useMutation, useQueryClient } from "react-query";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Close, Loop } from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";
import { green, grey, orange, red } from "@material-ui/core/colors";

import { useAuth } from "../../../../providers";
import { useMyTasks } from "../MyTasks";
import TaskDialogDetails from "./TaskDialogDetails";
import { TaskDialogDocuments } from "./TaskDialogDocuments";

const useStyles = makeStyles({
  "@keyframes spin": {
    "0%": { transform: "rotate(360deg)" },
    "100%": { transform: "rotate(0deg)" },
  },
  spinner: {
    animation: "$spin 1s linear infinite",
  },
  dialog: {
    maxHeight: "90vh",
    height: "100%",
  },
  dialogTitle: {
    backgroundColor: "#f7f9fc",
  },
  dialogContent: {
    backgroundColor: "#f7f9fc",
    padding: "0 24px 0 24px",
    overflowY: "hidden",
  },
  dialogActions: {
    padding: "10px",
    backgroundColor: "#f7f9fc",
  },
  dialogTab: {
    flex: 1,
  },
  tabPanel: {
    padding: "16px",
  },
  formControl: {
    margin: "5px 0",
    width: "100%",
  },
  inactive: {
    backgroundColor: grey[500],
  },
  active: {
    backgroundColor: orange[500],
  },
  completed: {
    backgroundColor: green[500],
  },
  approved: {
    backgroundColor: green[500],
  },
  rejected: {
    backgroundColor: red[500],
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TabPanel = (props: {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: string;
}) => {
  const { children, className, value, index, ...other } = props;

  return (
    <div
      className={className}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index ? children : null}
    </div>
  );
};

const a11yProps = (index: Number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TaskDialog = () => {
  const {
    selectedTaskIsLoading,
    selectedTask,
    taskId,
    setTaskId,
    internalTaskState,
    setInternalTaskState,
  } = useMyTasks();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState<Number>(0);
  const { axiosWithAuth } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const queryClient = useQueryClient();

  const handleUpdateTask = useMutation(
    (internalTaskState: any) => {
      return axiosWithAuth!({
        method: "PUT",
        url: `pricing-workflow/${selectedTask.parentId}/${selectedTask._id}`,
        data: {
          assignee: internalTaskState?.assignee?.id,
          assignedTeam: internalTaskState?.assignedTeam?.id,
          status: internalTaskState?.status,
        },
      });
    },
    {
      onSuccess: () => {
        setInternalTaskState({});
        queryClient.invalidateQueries("tasks");
        queryClient.invalidateQueries("task");
        setIsModalOpen(false);
      },
    }
  );

  useEffect(() => {
    setIsModalOpen(!!taskId);
  }, [taskId]);

  const initials = selectedTask?.assignee.name
    ? selectedTask.assignee.name
        .replace(/[^a-zA-Z- ]/g, "")
        .match(/\b\w/g)
        .join("")
    : "";

  return (
    <Dialog
      disableBackdropClick
      classes={{ paper: classes.dialog }}
      maxWidth="xl"
      fullWidth={true}
      open={isModalOpen}
      onClose={() => {
        setTaskId(undefined);
      }}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {selectedTaskIsLoading ? (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogTitle className={classes.dialogTitle}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  className={clsx({
                    [classes.inactive]: selectedTask?.status === "" || "Draft",
                    [classes.active]: selectedTask?.status === "In Progress",
                    [classes.completed]: selectedTask?.status === "Completed",
                    [classes.approved]: selectedTask?.status === "Approved",
                    [classes.rejected]: selectedTask?.status === "Rejected",
                  })}
                  style={{
                    width: "25px",
                    height: "25px",
                    fontSize: "0.8rem",
                    marginRight: "10px",
                  }}
                >
                  {initials}
                </Avatar>
                <Typography
                  variant="h5"
                  id="alert-dialog-title"
                  style={{ padding: "5px" }}
                >
                  {selectedTask?.taskName}
                </Typography>
              </Box>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setIsModalOpen(false);
                }}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Paper style={{ minHeight: "100%" }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="fullWidth"
              >
                <Tab
                  className={classes.dialogTab}
                  label="Details"
                  {...a11yProps(0)}
                />

                <Tab
                  className={classes.dialogTab}
                  label="Documents"
                  {...a11yProps(1)}
                />
              </Tabs>
              <TabPanel
                className={classes.tabPanel}
                value={selectedTab}
                index={0}
              >
                <TaskDialogDetails />
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={selectedTab}
                index={1}
              >
                <TaskDialogDocuments />
              </TabPanel>
            </Paper>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="contained"
              disableElevation
              disabled={handleUpdateTask.isLoading}
              onClick={() => {
                handleUpdateTask.mutate(internalTaskState);
              }}
              style={{ width: "150px" }}
            >
              {handleUpdateTask.isLoading ? (
                <Loop className={classes.spinner} />
              ) : (
                "Save and Close"
              )}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default TaskDialog;
