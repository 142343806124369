import React from "react";
import PropTypes from "prop-types";
import { createMuiTheme } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";

type OverridesNameToClassKey = {
  [P in keyof Required<MuiPickersOverrides>]: keyof MuiPickersOverrides[P];
};
declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends OverridesNameToClassKey {}
}

export const theme = createMuiTheme({
  palette: {
    secondary: {
      light: "#cf3fb3",
      main: "#c410a0",
      dark: "#890b70",
      contrastText: "#000",
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#3FA0CD",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#3FA0CD",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.729vw",
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          color: "rgba(196, 16, 160, 1)",
          backgroundColor: "none",
        },
      },
    },
    MuiPaper: {
      root: {
        boxSizing: "border-box",
      },
      elevation1: {
        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
        borderRadius: "5px",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: "#3FA0CD",
        "&$checked": {
          color: "#3FA0CD",
        },
      },
    },
    MuiIconButton: {
      colorSecondary: {
        color: "rgba(196, 16, 160, 1)",
      },
    },
  },

  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    body1: {
      fontSize: "1.1vw",
      fontWeight: 300,
    },
    body2: {
      fontSize: "1vw",
      fontWeight: 300,
    },
    h2: {
      fontSize: "3.125vw",
    },
    h3: {
      fontSize: "2.500vw",
    },
    h4: {
      fontSize: "2.4vw",
    },
    h5: {
      fontSize: "1.7vw",
    },
    h6: {
      fontWeight: 400,
      fontSize: "1.042vw",
    },
  },
});

export const ThemeProvider = (props: any) => {
  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>{props.children}</StylesProvider>
    </MuiThemeProvider>
  );
};

ThemeProvider.propTypes = {
  /**
   * The content to wrap the ThemeProvider around.
   */
  children: PropTypes.element,
};
