import React from "react";
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { DashboardWidget } from "../../../common/types";
import { useSharedDashboardData } from "../../../providers";
import { WidgetWrapper } from "./WidgetWrapper";

type FundInspectorDetailProps = DashboardWidget & {};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const HoldingRow = (props: any) => {
  const { row, ...otherProps } = props;
  const classes = useRowStyles();

  return (
    <TableRow {...otherProps} className={classes.root}>
      <TableCell component="th" scope="row">
        {row.InstrumentName}
      </TableCell>
      <TableCell align="right">{row.CurrencyCode}</TableCell>
      <TableCell align="right">
        {row.InvestorAccountValue.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })}
      </TableCell>
      {/* <TableCell align="right">{row.PERatio}</TableCell>
      <TableCell align="right">{row.Trail12MEps}</TableCell> */}
      <TableCell align="right">{row.CountryName}</TableCell>
      <TableCell align="right">{row.SectorName}</TableCell>
      <TableCell align="right">{row.ISIN}</TableCell>
    </TableRow>
  );
};

export const FundInspectorDetail = (props: FundInspectorDetailProps) => {
  const { inspectionData } = useSharedDashboardData();

  const { holdings } = inspectionData;

  if (holdings === undefined)
    return <WidgetWrapper data={props} title="Breakdown"></WidgetWrapper>;

  return (
    <WidgetWrapper data={props} title="Breakdown">
      <Box m={5} />
      {holdings.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Instrument Name</TableCell>
                <TableCell align="right">Currency</TableCell>
                <TableCell align="right">Value (USD)</TableCell>
                {/* <TableCell align="right">P/E Ratio</TableCell>
                <TableCell align="right">Trailing 12 Month EPS</TableCell> */}
                <TableCell align="right">Country</TableCell>
                <TableCell align="right">Sector</TableCell>
                <TableCell align="right">ISIN</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holdings.map((holding: any) => (
                <HoldingRow
                  key={`${holding.ISIN}-${holding.InvestorAccountValue}`}
                  row={holding}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Paper>
          <Grid container>
            <Box m={2}>
              You currently have no holdings showing. Try entering a search term
              above (e.g. "United States") to inspect some of your holdings,
            </Box>
          </Grid>
        </Paper>
      )}
    </WidgetWrapper>
  );
};
