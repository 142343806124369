import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Switch, Route, Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Slide,
  TextField,
} from "@material-ui/core";
import { View } from "../../components";
import { useAuth } from "../../providers";
import { AddCircle, Delete, Edit } from "@material-ui/icons";
import { Dashboard } from "./Dashboard";
import { TransitionProps } from "@material-ui/core/transitions";
import { red } from "@material-ui/core/colors";
import {
  exampleDashboard,
  exampleDashboardEmpty,
} from "../../components/GettingStartedTour/example-data";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    "&:hover $tabNameEdit": {
      display: "block",
    },
  },
  tabNameEdit: {
    display: "none",
    position: "absolute",
    right: "5px",
    width: "18px",
  },
}));

const defaultDashboardConfig = [
  {
    name: "Investor Default Page",
    widgets: [
      {
        name: "MyFunds",
        size: 8,
      },
      {
        name: "TotalMarketValue",
        size: 4,
      },
      {
        name: "FundsByStrategy",
        size: 4,
      },
      {
        name: "FundsByCurrency",
        size: 4,
      },
      {
        name: "FundsBymanager",
        size: 4,
      },
    ],
  },
  {
    name: "Widgets Example 2",
    widgets: [
      {
        name: "FundDrillDownFilters",
        size: 12,
      },
      {
        name: "FundDrillDown",
        size: 12,
      },
      {
        name: "FundsDrillDownPie",
        size: 6,
      },
    ],
  },
  {
    name: "Widgets Example 3",
    widgets: [
      {
        name: "FundInspectorSearchFilter",
        size: 12,
      },
      {
        name: "FundInspectorExposure",
        size: 12,
      },
      {
        name: "FundInspectorDetail",
        size: 12,
      },
    ],
  },
];

const addDashboardConfig = {
  widgets: [
    {
      name: "",
      size: 8,
    },
    {
      name: "",
      size: 4,
    },
    {
      name: "",
      size: 4,
    },
    {
      name: "",
      size: 4,
    },
    {
      name: "",
      size: 4,
    },
  ],
};

export const Dashboards = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { axiosWithAuth } = useAuth();
  const [addingDashboard, setAddingDashboard] = useState(false);
  const [editingModalIndex, setEditingModalIndex] = useState<number | null>(
    null
  );
  const [editingModalName, setEditingModalName] = useState<string>("");
  const [updatingDashboard, setUpdatingDashboard] = useState(false);
  const [showExampleDashboard, setShowExampleDashboard] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setShowExampleDashboard(location.pathname.indexOf("/example") !== -1);
  }, [location]);

  const { data: dashboardsData, isLoading: dashboardsLoading } = useQuery(
    "dashboards",
    async () => {
      if (axiosWithAuth === undefined) return;
      const prefs = await axiosWithAuth({
        url: "/preferences",
      });

      if (!prefs?.dashboards || prefs.dashboards.length === 0) {
        const { dashboards } = await axiosWithAuth!({
          url: `/preferences`,
          method: "PUT",
          data: {
            dashboards: defaultDashboardConfig,
          },
        });

        return dashboards;
      }

      return prefs?.dashboards;
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000 * 60,
    }
  );

  const deleteDashboard = useMutation(
    (id) => {
      return axiosWithAuth!({
        url: `preferences/dashboard/${id}`,
        method: "DELETE",
      });
    },
    {
      onSuccess: () => {
        setEditingModalIndex(null);
        queryClient.invalidateQueries("dashboards");
        history.push(`/dashboards`);
      },
    }
  );

  const updateDashboardPreferences = async (data: any) => {
    await axiosWithAuth!({
      url: `/preferences`,
      method: "PUT",
      data: {
        dashboards: data,
      },
    });
    queryClient.invalidateQueries("dashboards");
  };

  const saveModalEdits = async (i: number) => {
    const dashboards = [...dashboardsData];

    setUpdatingDashboard(true);

    dashboards[i] = {
      ...dashboards[i],
      name: editingModalName,
    };

    await updateDashboardPreferences(dashboards);

    setUpdatingDashboard(false);
    setEditingModalIndex(null);
  };

  return (
    <View>
      <AppBar position="static" color="transparent" elevation={0}>
        {dashboardsLoading ? <LinearProgress /> : null}
        {dashboardsData ? (
          <Paper>
            <Tabs
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              value={window.location.pathname}
              data-tour="dashboard-tabs"
            >
              {dashboardsData?.map((dashboard: any, i: number) => {
                const urlWithIndex = i > 0 ? `/${i + 1}` : "";

                return (
                  <Tab
                    className={classes.tab}
                    label={
                      <>
                        {dashboard.name}
                        <Edit
                          onClick={(event: any) => {
                            setEditingModalIndex(i);
                            event.preventDefault();
                          }}
                          className={classes.tabNameEdit}
                        />
                      </>
                    }
                    component={Link}
                    key={`Dashboard Tab Button ${i}`}
                    to={`/dashboards${urlWithIndex}`}
                    value={`/dashboards${urlWithIndex}`}
                  />
                );
              })}
              {showExampleDashboard ? (
                <Tab
                  className={classes.tab}
                  label="Example Dashboard"
                  component={Link}
                  to={`/dashboards/example`}
                  value={`/dashboards/example`}
                />
              ) : null}
              <Tab
                data-tour="dashboard-add"
                label={
                  addingDashboard ? (
                    <CircularProgress size={24} />
                  ) : (
                    <AddCircle />
                  )
                }
                onClick={async () => {
                  if (addingDashboard) return;
                  setAddingDashboard(true);
                  await updateDashboardPreferences([
                    ...dashboardsData,
                    // Add another addDashboardConfig
                    {
                      ...addDashboardConfig,
                      name: `Dashboard ${dashboardsData.length + 1}`,
                    },
                  ]);
                  setAddingDashboard(false);
                }}
              />
            </Tabs>
          </Paper>
        ) : null}
      </AppBar>
      <Switch>
        {/* Note: We need to do the first one last as it would otherwise catch all `/dashboards` routes so we `slice` it out. */}
        {dashboardsData?.slice(1).map((dashboard: any, i: number) => (
          <Route
            key={`dashboard-tab-${i + 1}`}
            path={`/dashboards/${i + 2}`}
            render={() => <Dashboard data={dashboard} />}
          />
        ))}
        {showExampleDashboard ? (
          <Route
            path="/dashboards/example"
            render={() => (
              <Dashboard
                data={
                  location.search.indexOf("?withData") === -1
                    ? exampleDashboardEmpty
                    : exampleDashboard
                }
              />
            )}
          />
        ) : null}
        {dashboardsData ? (
          <Route
            key="/dashboards"
            path="/dashboards"
            render={() => <Dashboard data={dashboardsData[0]} />}
          />
        ) : null}
      </Switch>
      <Dialog
        open={editingModalIndex !== null}
        onClose={() => setEditingModalIndex(null)}
        TransitionComponent={Transition}
        aria-labelledby="edit-modal-title"
      >
        <DialogTitle id="edit-modal-title">Rename Dashboard</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="outlined"
            value={
              editingModalIndex !== null
                ? editingModalName || dashboardsData[editingModalIndex].name
                : editingModalName
            }
            onChange={(event: any) => {
              setEditingModalName(event?.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ marginRight: "auto", color: red[700] }}
            aria-label="delete"
            disabled={deleteDashboard.isLoading}
            onClick={() => {
              const dashboardId =
                editingModalIndex !== null &&
                dashboardsData[editingModalIndex]._id;
              deleteDashboard.mutate(dashboardId);
            }}
          >
            {deleteDashboard.isLoading ? <CircularProgress /> : <Delete />}
          </IconButton>
          <Button onClick={() => setEditingModalIndex(null)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={updatingDashboard}
            onClick={() => {
              if (editingModalIndex !== null) saveModalEdits(editingModalIndex);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </View>
  );
};
