import { Typography } from "@material-ui/core";
import React from "react";

import { TourConfigStepsType, TourConfigType } from "./types";

// To see the options for styles: https://github.com/gilbarbara/react-joyride/blob/3e08384415a831b20ce21c8423b6c271ad419fbf/src/styles.js

export const generateTourConfig = (
  history: any,
  location: any
): Array<TourConfigType> => {
  return decorateTourConfigWithStatus([
    {
      id: "welcome",
      title: "Welcome",
      steps: [
        {
          target: ".foo-bar-baz",
          content: "",
          complete: true,
          title: "Your organisation",
        },
        {
          target: ".foo-bar-baz",
          content: "",
          complete: true,
          title: "Let's get you connected",
        },
        {
          target: ".foo-bar-baz",
          content: "",
          complete: true,
          title: "Add some bricks",
        },
      ],
    },
    {
      id: "amxconnect-overview",
      title: "AMXConnect Overview",
      description: "Welcome to AMXConnect. Lets kick-off the demo walkthrough.",
      steps: [
        {
          target: "[data-tour='header-settings']",
          content: "See your team and organisation in Settings",
          title: "Your Team",
        },
        {
          target: "[data-tour='sidebar-dashboards']",
          content: "Build and customize dashboards",
          title: "Dashboards",
        },
        {
          target: "[data-tour='sidebar-explorer']",
          content: "Explore your data and documents",
          title: "Reports & Documents",
        },
        {
          target: "[data-tour='sidebar-connect']",
          content: "Your digital communication hub",
          title: "Online Communication",
        },
        {
          target: "[data-tour='sidebar-store']",
          content: "Grow your capabilities by adding items in our store",
          title: "Market Place",
        },
        {
          target: "[data-tour='sidebar-apps']",
          content: "Plug and play with powerful apps",
          title: "Application HUB",
        },
      ],
    },
    {
      id: "organize-at-scale",
      title: "Organize At Scale",
      description:
        "AMXConnect enables you to manage all your relationships in one place.",
      steps: [
        {
          action: async () => {
            if (location.pathname !== "/store") await history.push("/store");
          },

          target: "[data-tour='store-my-amx']",
          content: "Your investment operations in one place",
          position: "top",
          title: "Your Stack",
        },
        {
          action: async () => {
            if (location.pathname !== "/store") await history.push("/store");
          },
          target: "[data-tour='store-tab-apps-and-services']",
          content: (
            <React.Fragment>
              <p>
                Grow your investment operating capabilities by connecting you to
                powerful services and innovative technology companies
              </p>
            </React.Fragment>
          ),
          title: "Apps & Services Store",
        },
        {
          action: async () => {
            if (location.pathname !== "/store") await history.push("/store");
          },
          target: "[data-tour='store-tab-analytics']",
          content: (
            <React.Fragment>
              <p>
                Browse AMXConnect's extensive data library to create insights
                and enhance your reporting
              </p>
            </React.Fragment>
          ),
          title: "Analytics Store",
        },
        {
          action: async () => {
            if (location.pathname !== "/store") await history.push("/store");
          },
          target: "[data-tour='store-tab-funds']",
          content: (
            <React.Fragment>
              <p>
                Browse funds and strategies from our ecosystem of Investment
                managers
              </p>
            </React.Fragment>
          ),
          title: "Fund Store",
        },
        {
          action: async () => {
            if (location.pathname !== "/store/funds")
              await history.push("/store/funds");
          },
          disableScrolling: true,
          target: "[data-tour='fund-detail-link']",
          content: "Drill through on the fund link to gain fund insights",
          title: "Fund Drill Down",
        },
        {
          action: async () => {
            if (location.pathname !== "/store/funds/detail/1")
              await history.push("/store/funds/detail/1");
          },
          disableScrolling: true,
          target: "[data-tour='fund-detail-tab-information']",
          content: "View digital factsheet",
          title: "Digital Factsheets",
        },
        {
          action: async () => {
            if (location.pathname !== "/store/funds/detail/1/performance")
              await history.push("/store/funds/detail/1/performance");
          },
          disableScrolling: true,
          target: "[data-tour='fund-detail-tab-performance']",
          content: "View funds performance data",
          title: "Fund Performance Charts",
        },
        {
          action: async () => {
            if (location.pathname !== "/store/funds/detail/1/holdings")
              await history.push("/store/funds/detail/1/holdings");
          },
          disableScrolling: true,
          target: "[data-tour='fund-detail-tab-holdings']",
          content: "View Top 10 Total Holdings",
          title: "Fund Top 10 Holdings",
        },
        {
          action: async () => {
            if (location.pathname !== "/store/funds/detail/1/exposure")
              await history.push("/store/funds/detail/1/exposure");
          },
          disableScrolling: true,
          target: "[data-tour='fund-detail-tab-exposure']",
          content: "View Top 10 Exposures",
          title: "Fund Exposures",
        },
        {
          action: async () => {
            if (location.pathname !== "/connect/service-connections")
              await history.push("/connect/service-connections");
          },
          target: "[data-tour='service-connections-table']",
          content: (
            <React.Fragment>
              <Typography variant="subtitle1">
                As you add new services you are always in control
              </Typography>
              <p>
                View your live service connections, service level agreements and
                all data imported and exported through the system.
              </p>
            </React.Fragment>
          ),
          title: "Your Service Connections",
        },
      ],
    },
    {
      id: "data-standardization",
      title: "Data Standardization",
      description:
        "Access to reliable data sources is critical for modern investment operations",
      steps: [
        {
          action: async () => {
            if (location.pathname !== "/apps") await history.push("/apps");
          },
          target: "[data-tour='app-data-operations']",
          content:
            "AMXConnect hosts a powerful data collection, validation and cleaning technology allowing us to easily handle multiple data feeds whilst performing 1000's of validations. Exceptions are handled by our team of data experts who use workflow to investigate and resolve exceptions",
          title: "Systematic Data Monitoring",
        },
        // {
        //   target: "[data-tour='']",
        //   content: "",
        //   title: "Exception Processing",
        // },
        // {
        //   target: "[data-tour='']",
        //   content: "",
        //   title: "Exception Processing 2",
        // },
        {
          action: async () => {
            if (location.pathname !== "/store/analytics")
              await history.push("/store/analytics");
          },
          target: "[data-tour='store-analytics']",
          content:
            "Browse AMXConnect's extensive data library that can be used to build reports and create insights",
          title: "Browse Analytics Library",
        },
      ],
    },
    {
      id: "data-insights",
      title: "Data Insights",
      description:
        "AMXConnect enables you to turn your data into powerful insights",
      steps: [
        {
          action: async () => {
            if (location.pathname !== "/dashboards")
              await history.push("/dashboards");
          },
          target: "[data-tour='dashboard-container']",
          content:
            "AMXConnect users can create and customize their own dashboards to create insights",
          title: "Dashboards",
          placement: "top-end",
          styles: {
            tooltip: { top: "315px" },
            floaterStyles: { arrow: { display: "none" } },
          },
        },
        {
          action: async () => {
            if (location.pathname !== "/dashboards/2")
              await history.push("/dashboards/2");
          },
          target: "[data-tour='dashboard-widget-0']",
          content:
            "AMXConnect  users can drilldown by Scheme, Asset Class, Strategy and Funds to quickly see fund details and there underlying holdings",
          title: "Dashboard Drill Down Filter",
        },
        {
          action: async () => {
            if (location.pathname !== "/dashboards/2")
              await history.push("/dashboards/2");
          },
          target: "[data-tour='dashboard-widget-2']",
          content:
            "Filter to see further breakdown of your funds by country, currency, sector and top 10 holdings",
          title: "Dashboard Fund Breakdown",
          placement: "right",
        },
        {
          action: async () => {
            if (location.pathname !== "/dashboards/3?exampleData")
              await history.push("/dashboards/3?exampleData");
          },
          target: "[data-tour='dashboard-inspector-search']",
          content:
            "Use AMXConnect Search to quickly get answers to pressing questions",
          title: "Dashboard Inspector View",
        },
        {
          action: async () => {
            if (location.pathname !== "/dashboards/3?exampleData")
              await history.push("/dashboards/3?exampleData");
          },
          target: "[data-tour='dashboard-inspector-filter']",
          content:
            "Refine your search further by filtering  by country, sector and currency. This allows users see there exposure across one account",
          title: "Dashboard Filters",
        },
        {
          action: async () => {
            if (location.pathname !== "/dashboards/3?exampleData")
              await history.push("/dashboards/3?exampleData");
          },
          target: "[data-tour='dashboard-widget-2']",
          content:
            "AMXConnect takes it one step further and shows users breakdown at instrument level",
          title: "Instrument Level Breakdown",
        },
      ],
    },
    {
      id: "communication-online",
      title: "Communication Online",
      description:
        "Modern investment operations conduct activities within system instead of email",
      steps: [
        {
          target: "[data-tour='header-notifications']",
          content: "View all new Notifications here",
          title: "Activity alerts",
        },
        {
          action: async () => {
            if (location.pathname !== "/connect/messages")
              await history.push("/connect/messages");
          },
          target: "[data-tour='tab-connect-tasks']",
          content:
            "Enable workflow apps to review all your tasks in AMXConnect",
          title: "Workflow queue",
        },
        {
          action: async () => {
            if (location.pathname !== "/connect/messages")
              await history.push("/connect/messages");
          },
          target: "[data-tour='messages-table']",
          content: "View all messages and click on link to review reports",
          title: "Message queue",
        },
        {
          action: async () => {
            if (location.pathname !== "/explorer/documents")
              await history.push("/explorer/documents");
          },
          target: "[data-tour='documents-container']",
          content: (
            <React.Fragment>
              <Typography variant="subtitle1">
                Your reports are here to view.
              </Typography>
              <p>AMXConnect ensures all your documents are in one place</p>
            </React.Fragment>
          ),
          title: "Document repository",
        },
        {
          action: async () => {
            if (location.pathname !== "/explorer/documents")
              await history.push("/explorer/documents");
          },
          target: "[data-tour='documents-filter']",
          content:
            "Use filters to access documents quickly. Select investor, Portfolio Manger and specific date range.",
          title: "Document search",
        },
        {
          action: async () => {
            if (location.pathname !== "/apps") await history.push("/apps");
          },
          target: "[data-tour='app-board meeting manager']",
          content:
            "Board Meeting Manager delivers functionality for INEDs including easy access to board meeting documentation and communications all in one place.",
          title: "Board Meeting apps",
        },
      ],
    },
    {
      id: "easy-adoption",
      title: "Easy Adoption",
      description:
        "A key principle of our technology offering is creating a simple and seamless experience. Customising the technology to suit your operation can be done at a click of a button",
      steps: [
        {
          action: async () => {
            if (location.pathname !== "/store") await history.push("/store");
          },
          target: "[data-tour='things-you-might-like']",
          content:
            "AMXConnect makes it easy to increase your capabilities simply and without fuss",
          title: "Matching you to new capabilities",
        },
        {
          action: async () => {
            if (location.pathname !== "/store?storePanelOpen=true")
              await history.push("/store?storePanelOpen=true");
          },
          target: "[data-tour='new-services-table']",
          content:
            "Add new data to your system. Fund administrators won’t provide ESG and Fundamental data. AMX’s data teams connect the data dots together for you by plugging in 3rd party services into our data model.",
          title: "Buying new analytics Capabilities",
        },
        {
          action: async () => {
            if (location.pathname !== "/store/analytics")
              await history.push("/store/analytics");
          },
          target: "[data-tour='analytics-filtering']",
          content:
            "Browse your new data. AMXConnect ensures all your data is available in one place",
          title: "View your new capabilities",
        },
        {
          target: "[data-tour='sidebar-dashboards']",
          content: "Build custom dashboards with your new data",
          title: "Dashboard build journey",
        },
        {
          action: async () => {
            if (location.pathname !== "/dashboards")
              await history.push("/dashboards");
          },
          target: "[data-tour='dashboard-add']",
          content: "Create a custom dashboard",
          title: "Create new Dashboard",
        },
        {
          action: async () => {
            if (location.pathname !== "/dashboards/example")
              await history.push("/dashboards/example");
          },
          target: "[data-tour='dashboard-widget-0']",
          content: (
            <React.Fragment>
              <Typography variant="subtitle1">
                Design your dashboards your way.
              </Typography>
              <p>
                Add saved reports to widgets or Launch AMXConnect report wizard
                to build a custom report with your new data
              </p>
            </React.Fragment>
          ),
          title: "Create new widget",
        },
        {
          action: async () => {
            if (location.pathname !== "/dashboards/example?withData")
              await history.push("/dashboards/example?withData");
          },
          target: "[data-tour='dashboard-widget-0']",
          content: (
            <React.Fragment>
              <Typography variant="subtitle1">
                Your new report is ready.
              </Typography>
              <p>
                Report data is dynamic. You can keep it private or share with
                your team
              </p>
            </React.Fragment>
          ),
          title: "View new report",
        },
        {
          action: async () => {
            if (location.pathname !== "/explorer?exampleReport")
              await history.push("/explorer?exampleReport");
          },
          target: "[data-tour='view-report']",
          content: "View and manage all your available reports",
          title: "View all reports",
        },
      ],
    },
    {
      id: "market-place",
      title: "Market Place",
      description:
        "AMXConnect makes it easy for you to grow your investment operating capabilities by connecting you to powerful services and innovative technology companies",
      steps: [
        {
          action: async () => {
            if (location.pathname !== "/store") await history.push("/store");
          },
          target: "[data-tour='things-you-might-like']",
          content:
            "AMXConnect enables you to connect and integrate your data with 3rd party applications",
          title: "Buy new apps",
        },
        {
          action: async () => {
            if (location.pathname !== "/store?storePanelOpen=true")
              await history.push("/store?storePanelOpen=true");
          },
          target: "[data-tour='new-services-table']",
          content:
            "Integration with applications can provide powerful new capabilities. Press buy on Alpima and Butterwire and access them in the Apps tab ",
          title: "Launch Portfolio Management App",
        },
      ],
    },
    {
      id: "wrap-up",
      title: "Wrap Up",
      steps: [
        {
          action: async () => {
            if (location.pathname !== "/connect/service-connections")
              await history.push("/connect/service-connections");
          },
          target: "[data-tour='service-connections-table']",
          content: (
            <React.Fragment>
              <Typography variant="subtitle1">
                In this demo you added a number of 3rd party services
              </Typography>
              <p>If you wish we can arrange focused follow ups</p>
              <ul style={{ textAlign: "left" }}>
                <li>1 new fundamental data service</li>
                <li>1 new ESG data service</li>
                <li>1 new portfolio management application</li>
                <li>1 new equity research application</li>
                <li>Can I integrate something else?</li>
              </ul>
            </React.Fragment>
          ),
          title: "Request follow ups",
        },
        {
          action: async () => {
            if (location.pathname !== "/store/apps-and-services")
              await history.push("/store/apps-and-services");
          },
          target: "[data-tour='store-apps-and-services']",
          content: (
            <React.Fragment>
              <Typography variant="subtitle1">
                We are constantly adding new services, analytics and apps to the
                store
              </Typography>
            </React.Fragment>
          ),
          title: "Apps and Services store",
        },
        {
          target: "body",
          content: "Thank you for your time, that’s the end of the demo",
          title: "End",
          placement: "center",
        },
      ],
    },
  ]);
};

export const decorateTourConfigWithStatus = (tourConfig: any) => {
  try {
    const tourConfigStatus: any = JSON.parse(
      localStorage.getItem("tourConfigStatus") || "{}"
    );

    return tourConfig.map((configItem: TourConfigType) => ({
      ...configItem,
      steps: configItem.steps.map((step: TourConfigStepsType, i: number) => ({
        ...step,
        complete: tourConfigStatus[`${configItem.id}-${i}`],
      })),
    }));
  } catch (error) {
    console.error("Error decorating `tourConfig`");
    console.error(error);
  }
};

export const updateTourConfigStatus = (section: string, stepIndex: number) => {
  try {
    const tourConfigStatus: any = JSON.parse(
      localStorage.getItem("tourConfigStatus") || "{}"
    );
    const updatedTourConfig = {
      ...tourConfigStatus,
      [`${section}-${stepIndex}`]: true,
    };

    localStorage.setItem("tourConfigStatus", JSON.stringify(updatedTourConfig));
  } catch (error) {
    console.error("Error updating `tourConfigStatus` in local storage");
    console.error(error);
  }
};
