import React from "react";
import { Link } from "react-router-dom";
import { Typography, Card, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * AMX Connect StorePanel Component
 * @component
 */

export const StorePanel = (props: any) => {
  const {
    type,
    status,
    amount,
    available,
    themeColor,
    actionLabel,
    action = () => {
      return;
    },
    url,
  } = props;

  const useStyles = makeStyles((theme) => ({
    card: {
      boxShadow: "0 2px 2px -2px #555",

      padding: "1em 1em 1.5em",
      display: "flex",
      flexDirection: "column",

      "&:hover": {
        background: "#f5f5f5",
        cursor: "pointer",
      },
      maxWidth: "400px",

      textDecoration: "none",
    },

    type: {
      fontWeight: "bold",
      marginBottom: "0.2em",
      textTransform: "uppercase",
    },
    typeStatus: {
      fontWeight: "bold",
      color: "#919191",
      textTransform: "uppercase",
    },
    typeAmount: {
      fontWeight: "bold",
      color: (props) => themeColor,
    },
    available: {
      fontWeight: "bold",
      marginBottom: "0.1em",
      paddingTop: "1.2em",
      alignSelf: "center",
      color: (props) => themeColor,
    },
    moreColumn: {
      marginTop: "auto",
      "& > div": {
        width: "fit-content",
        marginLeft: "auto",
      },
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card
        className={classes.card}
        variant="outlined"
        onClick={() => action()}
        component={Link}
        to={"/store/" + url}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography
              component="h4"
              variant="subtitle2"
              className={classes.type}
            >
              {type}
            </Typography>

            <Typography
              component="div"
              variant="h5"
              className={classes.typeAmount}
            >
              {amount}
            </Typography>
            <Typography
              component="div"
              variant="caption"
              className={classes.typeStatus}
            >
              {status}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.moreColumn}>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography
                component="div"
                variant="h5"
                className={classes.available}
              >
                {available}
              </Typography>
              <Typography component="div" variant="caption">
                <strong>{actionLabel}</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};
