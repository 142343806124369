import React from "react";
import MUIDataTable from "mui-datatables";
import { green } from "@material-ui/core/colors";
import { DeleteIcon, TabView } from "../../components";
import { useService } from "../../providers";

export const ServiceConnections = () => {
  const { serviceData } = useService();

  const data = [
    {
      service: "Nav calculation",
      provider: "Northern trust",
      logo: "/images/northern-trust.webp",
      serviceType: "Data",
      description: "Calculation of the funds net asset value",
      frequency: "Daily",
      serviceCosts: "Standard package",
      deliverable: "NAV data file",
      dataManagement: "Import",
      status: green[500],
    },
    {
      service: "Custody",
      provider: "Northern trust",
      logo: "/images/northern-trust.webp",
      serviceType: "Service",
      description: "Safe custody of assets",
      frequency: "Daily",
      serviceCosts: "Standard package",
      deliverable: "Safe custody of assets as per regulation",
      dataManagement: "N/A",
      status: green[500],
    },
    {
      service: "Fund administration",
      provider: "Northern trust",
      logo: "/images/northern-trust.webp",
      serviceType: "Data",
      description: "Positions and prices of assets with the fund",
      frequency: "Daily",
      serviceCosts: "Standard package",
      deliverable: "Positions and prices",
      dataManagement: "Import",
      status: green[500],
    },
    {
      service: "Transfer agency",
      provider: "Northern trust",
      logo: "/images/northern-trust.webp",
      serviceType: "Service",
      description: "Processing of subscription and redemptions",
      frequency: "Daily",
      serviceCosts: "Standard package",
      deliverable: "Processing or orders",
      dataManagement: "N/A",
      status: green[500],
    },
    {
      service: "Data operations",
      provider: "AMX",
      logo: "/images/amx-connect-logo.png",
      serviceType: "Application",
      description: "Monitoring and checking of data feeds",
      frequency: "Daily",
      serviceCosts: "Standard package",
      deliverable: "Identification and resolution of exceptions",
      dataManagement: "N/A",
      status: green[500],
    },
    {
      service: "Manager due diligence",
      provider: "AMX",
      logo: "/images/amx-connect-logo.png",
      serviceType: "Service",
      description: "Investment management due diligence",
      frequency: "Quarterly",
      serviceCosts: "Standard package",
      deliverable:
        "Corporate, operational, investment and cyber due diligence of managers",
      dataManagement: "N/A",
      status: green[500],
    },
    {
      service: "Administrator oversight",
      provider: "AMX",
      logo: "/images/amx-connect-logo.png",
      serviceType: "Service",
      description:
        "Independent monitoring of positions, prices and daily processes",
      frequency: "Daily",
      serviceCosts: "Standard package",
      deliverable: "Daily Incident processes and quarterly board reports",
      dataManagement: "N/A",
      status: green[500],
    },
    {
      service: "Investment and portfolio risk oversight",
      provider: "AMX",
      logo: "/images/amx-connect-logo.png",
      serviceType: "Service",
      description:
        "Independent monitoring of investment manager risk processes ",
      frequency: "Daily",
      serviceCosts: "Standard package",
      deliverable: "Daily Incident processes and quarterly board reports",
      dataManagement: "N/A",
      status: green[500],
    },
  ];

  let newDataList = [...data];

  const columns = [
    {
      name: "service",
      label: "Service",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "logo",
      label: "Provider",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: logo) => (
          <img alt="logo" src={value} width="120px" />
        ),
      },
    },
    {
      name: "serviceType",
      label: "Service type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "frequency",
      label: "Frequency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "serviceCosts",
      label: "Service Costs",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "deliverable",
      label: "Deliverable",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "dataManagement",
      label: "AMX Data management",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <div
            style={{
              background: value,
              borderRadius: "8px",
              height: "16px",
              margin: "0 auto",
              width: "16px",
            }}
          ></div>
        ),
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <DeleteIcon />
        ),
      },
    },
  ];

  const updateDataList = () => {
    serviceData.map((item, i) => {
      switch (item.serviceProvider.toLowerCase()) {
        case "alpima":
          if (item.purchased) {
            const newService = {
              service: "Portfolio management application",
              provider: "Alpima",
              logo: "/images/alpima-logo.png",
              serviceType: "Application",
              description: "Portfolio management and calculations",
              frequency: "Daily",
              serviceCosts: "Premium",
              deliverable: "Integration with AMX, PM  screens and calcs",
              dataManagement: "Export",
              status: green[500],
            };

            newDataList.push(newService);
          }
          break;
        case "butterwire":
          if (item.purchased) {
            const newService = {
              service: "Equity research application",
              provider: "butterwire",
              logo: "/images/butterwire.png",
              serviceType: "Application",
              description: "Macro and equity research",
              frequency: "Daily",
              serviceCosts: "Premium",
              deliverable: "Integration with AMX, screens and research",
              dataManagement: "Export",
              status: green[500],
            };

            newDataList.push(newService);
          }
          break;
        case "refinitive":
          if (item.purchased) {
            const newService = {
              service: "Fundamental Data",
              provider: "Refinitiv",
              logo: "/images/refinitiv.png",
              serviceType: "Data",
              description: "Data for equity holdings",
              frequency: "Daily",
              serviceCosts: "Premium",
              deliverable:
                "Equity universe and data refreshed with latest available data",
              dataManagement: "Import",
              status: green[500],
            };

            newDataList.push(newService);
          }
          break;
        case "owl analytics":
          if (item.purchased) {
            const newService = {
              service: "ESG data",
              provider: "Owl analytics ",
              logo: "/images/owl-analytics.png",
              serviceType: "Data",
              description: "ESG scores for equity holdings",
              frequency: "Daily",
              serviceCosts: "Premium",
              deliverable: "Equity universe and scores refreshed quarterly ",
              dataManagement: "Import",
              status: green[500],
            };

            newDataList.push(newService);
          }
          break;
      }
      return null;
    });
  };

  updateDataList();

  const options: any = {
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    responsive: "vertical",
    selectToolbarPlacement: "none",
    elevation: 0,
    pagination: false,
    selectableRows: "none",
    rowsPerPage: 5,
  };

  return (
    <TabView title="Service Connections" data-tour="service-connections-table">
      <MUIDataTable
        title=""
        data={newDataList}
        columns={columns}
        options={options}
      />
    </TabView>
  );
};
