import React from "react";
import { makeStyles } from "@material-ui/core";
import { Box, Grid, Typography } from "@material-ui/core";

import { QuickStartVideo } from "./QuickStartVideo";

/**
 * AMX Connect Header Component
 * @component
 */

const useStyles = makeStyles((theme) => ({
  bodyText: {
    margin: "0 1em 4em",
    textAlign: "center",
  },

  gridWrap: {
    marginTop: "30px",
  },
}));

const Step1 = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridWrap}>
      <Grid item xs={12}>
        <Box justifyContent="center" display="flex">
          <QuickStartVideo url="/videos/why.mp4" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          justifyContent="center"
          display="flex"
          className={classes.bodyText}
        >
          <Typography component="p" variant="inherit">
            Our purpose at AMX is to modernise and transform the long held
            conventions of the investment industry that are holding everyone
            back
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Step1;
