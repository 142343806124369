import { Box, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { DashboardWidget } from "../../../common/types";
import ChipSelector, {
  SelectItem,
} from "../../../components/ChipSelector/ChipSelector";
import { useSharedDashboardData } from "../../../providers";
import { WidgetWrapper } from "./WidgetWrapper";

type FundDrillDownFiltersProps = DashboardWidget & {};

export const FundDrillDownFilters = (props: FundDrillDownFiltersProps) => {
  const {
    dashboardsDrilldownBaselineData,
    dashboardsDrilldownBaselineLoading,
    drilldownFilters,
    setDrilldownFilters,
  } = useSharedDashboardData();

  useEffect(() => {
    if (dashboardsDrilldownBaselineData === undefined) return;

    if (
      drilldownFilters === undefined ||
      Object.keys(drilldownFilters).length > 0
    )
      return;

    setDrilldownFilters(() => ({
      ...dashboardsDrilldownBaselineData.selectors,
    }));
  }, [dashboardsDrilldownBaselineData, setDrilldownFilters, drilldownFilters]);

  const prettySelectorName = (rawSelectorName: string) => {
    var result = rawSelectorName.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  // TODO: Implement Clear Filters
  // const handleClearFilters = () => {
  //   if (drilldownFilters === undefined) return;
  //   console.log(
  //     Object.keys(drilldownFilters).reduce(
  //       (
  //         filters: { [filterName: string]: SelectItem[] },
  //         filterName: string
  //       ) => {
  //         return {
  //           ...filters,
  //           [filterName]: { ...drilldownFilters[filterName], selected: false },
  //         };
  //       },
  //       {}
  //     )
  //   );
  // };

  return (
    <WidgetWrapper data={props} title="Fund DrillDown Filters">
      <Grid container>
        {Object.keys(drilldownFilters).map((selectorName: string) => (
          <Grid item xs={3} key={selectorName}>
            <Box p={2} mt={2}>
              <ChipSelector
                label={prettySelectorName(selectorName)}
                items={drilldownFilters[selectorName]}
                setSelectedCheckboxes={(items: SelectItem[]) =>
                  setDrilldownFilters(() => ({
                    ...drilldownFilters,
                    [selectorName]: items,
                  }))
                }
              />
            </Box>
          </Grid>
        ))}

        {dashboardsDrilldownBaselineLoading ? "LOADING..." : null}
      </Grid>
    </WidgetWrapper>
  );
};
