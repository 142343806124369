import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ExploreIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.428,11.344,30.387,2.788a.619.619,0,0,0-.768-.359L26.608,3.446A2.71,2.71,0,0,0,24.765,5.66l-13.61,4.577a1.8,1.8,0,0,0-1.229,1.675L7.5,12.751a.594.594,0,0,0-.369.748l1.229,3.47a.6.6,0,0,0,.307.329l.277.06h.215l2.427-.808a1.843,1.843,0,0,0,2.028.568L20.156,14.9v6.342L14.688,29.5a.6.6,0,0,0,.184.838.632.632,0,0,0,.86-.18l4.393-6.7v6.821a.615.615,0,0,0,1.229,0V23.461l4.424,6.672a.621.621,0,0,0,1.139.03.587.587,0,0,0-.126-.658l-5.438-8.257V14.486l5.868-1.975a2.819,2.819,0,0,0,2.826.628l3.011-1.017a.609.609,0,0,0,.353-.313A.584.584,0,0,0,33.428,11.344ZM9.311,16.012l-.83-2.334,1.751-.6.83,2.334Zm3.871-.03a.619.619,0,0,1-.768-.359l-1.229-3.47a.594.594,0,0,1,.369-.748L13,10.926l1.628,4.607Zm2.611-.868-1.628-4.607,10.753-3.62,1.628,4.607ZM29.649,12a1.574,1.574,0,0,1-1.182-.064,1.51,1.51,0,0,1-.784-.863l-1.6-4.607a1.46,1.46,0,0,1,.066-1.151,1.524,1.524,0,0,1,.887-.764l2.427-.808,2.642,7.419Z"
        transform="translate(-7.099 -2.399)"
        fill="#272730"
      />
    </SvgIcon>
  );
};
