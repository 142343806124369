import React from "react";
import { makeStyles, Tab, Tabs, Tooltip } from "@material-ui/core";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import TableChartIcon from "@material-ui/icons/TableChart";

export const IconTabs = ({
  value,
  setValue,
}: {
  value: number;
  setValue: any;
}) => {
  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      minWidth: 300,
      marginTop: 40,
    },
    tab: {
      minWidth: 0,
    },
  });

  const classes = useStyles();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon tabs example"
      >
        <Tooltip title="Tile View" enterDelay={1000}>
          <Tab
            icon={<InsertDriveFileIcon />}
            aria-label="document"
            className={classes.tab}
          />
        </Tooltip>
        <Tooltip title="Table View" enterDelay={1000}>
          <Tab
            icon={<TableChartIcon />}
            aria-label="table"
            className={classes.tab}
          />
        </Tooltip>
      </Tabs>
    </div>
  );
};
