import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const AccountsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="32"
      viewBox="0 0 34 32"
      {...props}
    >
      <g id="noun_organization_3778294" transform="translate(-7 -8)">
        <path
          id="Path_1029"
          data-name="Path 1029"
          d="M17,18H31a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1H17a1,1,0,0,0-1,1v8A1,1,0,0,0,17,18Zm1-8H30v6H18Z"
        />
        <path
          id="Path_1030"
          data-name="Path 1030"
          d="M16,32H8a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V33A1,1,0,0,0,16,32Zm-1,6H9V34h6Z"
        />
        <path
          id="Path_1031"
          data-name="Path 1031"
          d="M28,32H20a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V33A1,1,0,0,0,28,32Zm-1,6H21V34h6Z"
        />
        <path
          id="Path_1032"
          data-name="Path 1032"
          d="M40,32H32a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V33A1,1,0,0,0,40,32Zm-1,6H33V34h6Z"
        />
        <path
          id="Path_1033"
          data-name="Path 1033"
          d="M12,30a1,1,0,0,0,1-1V27H23v2a1,1,0,0,0,2,0V27H35v2a1,1,0,0,0,2,0V26a1,1,0,0,0-1-1H25V21a1,1,0,0,0-2,0v4H12a1,1,0,0,0-1,1v3A1,1,0,0,0,12,30Z"
        />
      </g>
    </SvgIcon>
  );
};
