import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  StorePanel,
  StorePanelBasic,
  StorePanelSector,
  StorePanelFeature,
} from "../../../../components/StorePanel";

import { StorePanelType } from "./types";

import { NewServicesTable } from "./NewServicesTable";
import { useService } from "../../../../providers";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import { Grid, Box, Dialog, DialogContent } from "@material-ui/core";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  base: {
    backgroundColor: "#fff",
    width: "500px",
    maxWidth: "500px",
  },

  baseContainer: {
    alignContent: "flex-start",
    justifyContent: "center",
    padding: "0 1em",
    [theme.breakpoints.up("md")]: {
      padding: "0 2em",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 3em",
    },
  },

  topContainer: {
    padding: "2em 2vw 0",
  },

  panelWrap: {
    padding: "2em 2vw 0",
  },
  outerWrap: {
    padding: "1em 1em 1em",
    backgroundColor: "#fff",
  },
  tabPanelHeading: {
    marginBottom: "1em",
  },
  infoBox: {
    marginLeft: "-1.5em",

    "& em": {
      color: "#1B84CF",
      fontStyle: "normal",
    },
  },
  footnote: {
    fontSize: "0.8rem",
  },
  dialog: {
    position: "absolute",
    bottom: "0",
    margin: "0",
  },

  panelGroup: {
    backgroundColor: "#dadada",
    margin: "0 0 1em 0",
    padding: "0.5em",

    "&:last-child": {
      margin: 0,
    },
  },

  panelGroupCol: {
    "&:last-child": {
      margin: 0,
    },
  },

  panelSectorContainer: {
    display: "flex",
  },
  panelGroupRight: {
    height: "100%",
  },

  panelSectorOuter: {
    height: "100%",
  },

  panelSectorInner: { alignItems: "stretch" },

  panelSectors: {
    alignSelf: "flex-end",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} timeout={200} />;
});

const investments = [
  {
    type: "Investment Operations",
    company: "See All",
  },
  {
    type: "Investment",
    company: "See All",
  },
];

const pensions = [
  {
    type: "Retirement Program",
    company: "WTW",
  },
  {
    type: "Global Services & Solutions",
    company: "WTW",
  },
  {
    type: "Health & Benefits",
    company: "WTW",
  },
  {
    type: "Talent & Reward",
    company: "WTW",
  },
  {
    type: "Investment Solutions",
    company: "WTW",
  },
  {
    type: "Fronting Insurance",
    company: "See All",
  },
];

const accounts = [
  {
    type: "US Defined Benefits Plan",
    company: "Pension Company",
  },
  {
    type: "Latam Plan",
    company: "Pension Company",
  },
  {
    type: "Global Plan",
    company: "Pension Company",
  },
];

const MyAMX = (props: {
  categories: any[];
  onServiceClick: Function;
  summary: { totalAUM: string; investments: string; ecosystemUsage: string };
  setTab: Function;
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);

  const { serviceData } = useService();

  const analyticsPurchased = serviceData.filter(
    (item) => item.purchased === true && item.type.toLowerCase() === "data"
  ).length;

  const analyticsTotal = serviceData.filter(
    (item) => item.type.toLowerCase() === "data"
  ).length;

  const appsPurchased = serviceData.filter(
    (item) => item.purchased === true && item.type.toLowerCase() === "app"
  ).length;

  const appsTotal = serviceData.filter(
    (item) => item.type.toLowerCase() === "app"
  ).length;

  const panels = [
    {
      type: "analytics",
      title: "analytics",
      status: "ACTIVE",
      amount: analyticsPurchased + 3, // data hacked for demo
      available: analyticsTotal - analyticsPurchased,
      themeColor: "#AA2B93",
      actionLabel: "AVAILABLE",
      url: "analytics",
    },
    {
      type: "services",
      title: "services",
      status: "ACTIVE",
      amount: "27",
      available: "34",
      themeColor: "#6590B3",
      actionLabel: "AVAILABLE",
      url: "apps-and-services",
    },
    {
      type: "funds",
      title: "funds",
      status: "INVESTED",
      amount: "4",
      available: "25",
      themeColor: "#CD9057",
      actionLabel: "AVAILABLE",
      url: "funds",
    },
    {
      type: "apps",
      title: "apps",
      status: "ACTIVE",
      amount: appsPurchased + 5, // data hacked for demo
      available: appsTotal - appsPurchased,
      themeColor: "#CFBD30",
      actionLabel: "AVAILABLE",
      url: "apps-and-services",
    },
    {
      type: "new",
      title: "ALPIMA. A Portfolio solution",
      themeColor: "#fff",
      bkgImage: "/images/alpima-mountains.webp",
    },
  ];

  useEffect(() => {
    if (!openDialog && location.search.indexOf("storePanelOpen=true") !== -1) {
      setOpenDialog(true);
    }
  }, [location]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const createStorePanel = (panel: string) => {
    const item: StorePanelType = panels.find((item) => item.type === panel);
    return (
      <StorePanel
        type={item.title}
        status={item.status}
        amount={item.amount}
        available={item.available}
        themeColor={item.themeColor}
        actionLabel={item.actionLabel}
        url={item.url}
      />
    );
  };

  let analyticsPanel = createStorePanel("analytics");
  let servicePanel = createStorePanel("services");
  let fundsPanel = createStorePanel("funds");
  let appsPanel = createStorePanel("apps");

  let buyPanel = panels
    .filter((x) => x.type === "new")
    .map((item) => (
      <StorePanelFeature
        key={item.title}
        title={item.title}
        themeColor={item.themeColor}
        action={handleClickOpen}
      />
    ));

  return (
    <Box data-tour="store-my-amx" className={classes.outerWrap}>
      <Grid container spacing={2} className={classes.panelGroupCol}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={1} className={classes.panelGroup}>
            <Grid item xs={3} className={classes.panelSectorContainer}>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.panelSectorInner}>
                  <StorePanelSector
                    type="Investment & Assets"
                    logo="/images/investment-assets-icon.svg"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9} className={classes.panelSectors}>
              <Grid container spacing={1} alignItems="flex-end">
                {investments.map((item, i) => (
                  <Grid item xs={4} key={item.type}>
                    <StorePanelBasic type={item.type} company={item.company} />
                  </Grid>
                ))}
                <Grid item xs={4}>
                  {buyPanel}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.panelGroup}>
            <Grid item xs={3} className={classes.panelSectorContainer}>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.panelSectorInner}>
                  <StorePanelSector
                    type="Pension"
                    logo="/images/pension-icon.svg"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9} className={classes.panelSectors}>
              <Grid container spacing={1} alignItems="flex-end">
                {pensions.map((item, i) => (
                  <Grid item xs={4} key={item.type}>
                    <StorePanelBasic type={item.type} company={item.company} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.panelGroup}>
            <Grid item xs={3} className={classes.panelSectorContainer}>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.panelSectorInner}>
                  <StorePanelSector
                    type="Accounts"
                    logo="/images/accounts-icon.svg"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9} className={classes.panelSectors}>
              <Grid container spacing={1} alignItems="flex-end">
                {accounts.map((item) => (
                  <Grid item xs={4} key={item.type}>
                    <StorePanelBasic type={item.type} company={item.company} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}>
          <Grid
            container
            spacing={1}
            alignContent="space-around"
            className={classes.panelGroup + " " + classes.panelGroupRight}
          >
            <Grid item xs={12}>
              {fundsPanel}
            </Grid>
            <Grid item xs={12}>
              {analyticsPanel}
            </Grid>
            <Grid item xs={12}>
              {appsPanel}
            </Grid>
            <Grid item xs={12}>
              {servicePanel}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        maxWidth="lg"
        TransitionComponent={Transition}
        fullWidth
        open={openDialog}
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
        classes={{
          paper: classes.dialog,
        }}
        onClose={handleClose}
      >
        <DialogContent>
          <Box display="flex" alignItems="flex-end" flexDirection="column">
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <NewServicesTable />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MyAMX;
