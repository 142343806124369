import React, { useEffect, useMemo, useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  Divider,
  makeStyles,
} from "@material-ui/core";

import { Box, Typography, Paper } from "@material-ui/core";
import { Welcome } from "./Welcome";
import { CheckCircle, ChevronRight, ExpandMore } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { TourConfigStepsType } from "./types";
import {
  decorateTourConfigWithStatus,
  generateTourConfig,
  updateTourConfigStatus,
} from "./helpers";
import { useInterval } from "../../helpers/useInterval";

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "absolute",
    top: "80px",
    margin: "0",
    width: "70vw",
    maxWidth: "850px",
  },
  header: {
    textAlign: "center",
    marginBottom: "1.5em",
  },
  subHeader: {
    padding: "0.5em 1em",
  },

  contentWrap: {
    backgroundColor: "#f7f9fc",
    padding: "1em 2em 4em",
  },
  outerWrap: {
    padding: "1em",
  },

  bodyText: {
    margin: "1em 1em 4em",
    textAlign: "center",
  },
  description: {
    fontStyle: "italic",
    margin: `${theme.spacing(1)}px 0`,
  },
  descriptionDivider: {
    marginBottom: theme.spacing(1),
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    flex: 1,
  },
  accordionSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
  },
  stepsContainer: {
    background: "#f7f9fc",
    flexDirection: "column",
  },
  step: {
    cursor: "pointer",
    padding: `${theme.spacing(1)}px 0`,
  },
  stepIcon: {
    color: "#c410a0",
  },
}));

type TourProps = {
  openDialog: boolean;
  handleClose: Function;
  showWelcome?: boolean;
};

const MAX_RETRY_TIME = 15000;
const RETRY_INTERVAL = 750;
const MAX_RETRIES = MAX_RETRY_TIME / RETRY_INTERVAL;

export const GettingStartedTour = (props: TourProps) => {
  const { openDialog, handleClose, showWelcome } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [showWelcomeTour, setShowWelcomeTour] = useState<boolean>(
    showWelcome || false
  );
  const [currentSteps, setCurrentSteps] = useState<Array<TourConfigStepsType>>(
    []
  );
  const [expanded, setExpanded] = useState<string>("");
  const [tourOpen, setTourOpen] = useState<boolean>(false);
  const [tourCurrentIndex, setTourCurrentIndex] = useState<any>(0);
  const [tourNextStep, setTourNextStep] = useState<any>();
  const [tourConfig, setTourConfig] = useState<any>([]);

  useEffect(() => {
    setTourConfig(generateTourConfig(history, location));
  }, [showWelcomeTour, history, location]);

  const endTour = () => {
    // Need to set our running state to false, so we can restart if we click start again.
    setTourOpen(false);
    // Reset the tour index.
    setTourCurrentIndex(0);
  };

  // This is the interval that runs while looking for a target that's not yet visible on the page
  useInterval(
    () => {
      // Retry a maximum number of times to avoid a memory leak.
      if (tourNextStep?.intervalCount >= MAX_RETRIES) {
        setTourNextStep(undefined);
        endTour();
      } else if (document.querySelector(tourNextStep.target)) {
        setTourCurrentIndex(tourNextStep.index);
        setTourNextStep(undefined);
        setTimeout(() => setTourOpen(true), 0);
      } else {
        setTourNextStep({
          ...tourNextStep,
          intervalCount: tourNextStep.intervalCount
            ? tourNextStep.intervalCount + 1
            : 1,
        });
      }
    },
    tourNextStep !== undefined ? RETRY_INTERVAL : null
  );

  const handleStepClick = (step: TourConfigStepsType, stepNumber: number) => {
    setTourCurrentIndex(stepNumber);
    setTourOpen(true);
  };

  const checkNextTarget = (nextStep: TourConfigStepsType) => {
    // We need to manually check for this as the start action doesn't appear to
    // trigger EVENTS.TARGET_NOT_FOUND
    if (!document.querySelector(nextStep.target)) {
      setTourNextStep({
        index: 0,
        target: nextStep.target,
      });
    }
  };

  const handleTourCallback = async (data: any) => {
    const { action, index, status, type } = data;

    if (type === EVENTS.TOUR_START || action === ACTIONS.START) {
      const nextStep = currentSteps[index];

      if (nextStep && nextStep.action) {
        await nextStep.action();

        checkNextTarget(nextStep);
      }

      setTourCurrentIndex(index);
    } else if (
      type === EVENTS.TOUR_END ||
      action === ACTIONS.STOP ||
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status)
    ) {
      endTour();
    } else if (type === EVENTS.STEP_AFTER) {
      updateTourConfigStatus(expanded, index);
      const newTourConfig = decorateTourConfigWithStatus(tourConfig);
      setTourConfig(newTourConfig);

      const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      const nextStep = currentSteps[nextIndex];

      if (nextStep?.action) {
        await nextStep.action();

        checkNextTarget(nextStep);
      }

      // Update state to advance the tour
      setTourCurrentIndex(nextIndex);
    } else if (EVENTS.TARGET_NOT_FOUND) {
      const currentStep = currentSteps[index];

      if (currentStep.action) {
        // We can assume that we're still waiting for the current page/tab/whatever to load,
        //  so set the next step to kick off the interval above..
        setTourNextStep({
          index,
          target: currentStep.target,
        });
      }
    }
  };

  const handleWelcomeComplete = () => {
    localStorage.setItem("seenWelcome", "true");
    setShowWelcomeTour(false);
  };

  return (
    <React.Fragment>
      <Dialog
        disableScrollLock
        fullWidth
        open={openDialog}
        onClose={() => handleClose()}
        classes={{
          paper: classes.dialog,
        }}
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
        style={{
          visibility:
            { ...currentSteps[tourCurrentIndex || 0] }.keepTourVisible ===
              true || !tourOpen
              ? "visible"
              : "hidden",
        }}
      >
        <DialogContent>
          <Box className={classes.outerWrap}>
            <Typography component="h3" variant="h5" className={classes.header}>
              Get Started with AMXConnect{" "}
            </Typography>

            <Paper elevation={2}>
              {showWelcomeTour ? (
                <Welcome handleComplete={handleWelcomeComplete} />
              ) : (
                <React.Fragment>
                  {tourConfig.map((tourItem: any) => (
                    <Accordion
                      data-tour={`tour-accordion-${tourItem.id}`}
                      expanded={expanded === tourItem.id}
                      key={tourItem.id}
                      onChange={() => {
                        if (expanded === tourItem.id) {
                          setCurrentSteps([]);
                          setExpanded("");
                        } else {
                          setCurrentSteps(tourItem.steps);
                          setExpanded(tourItem.id);
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={`${tourItem.id}-bh-content`}
                        id={`${tourItem.id}-bh-header`}
                      >
                        <Typography className={classes.accordionHeading}>
                          {tourItem.title}
                        </Typography>
                        <Typography
                          className={classes.accordionSecondaryHeading}
                        >
                          {tourItem.steps.reduce(
                            (prev: number, curr: TourConfigStepsType) => {
                              if (curr.complete) prev++;

                              return prev;
                            },
                            0
                          )}{" "}
                          of {tourItem.steps.length} Completed
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.stepsContainer}>
                        {tourItem.description ? (
                          <React.Fragment>
                            <Typography
                              variant="body2"
                              className={classes.description}
                            >
                              {tourItem.description}
                            </Typography>
                            <Divider className={classes.descriptionDivider} />
                          </React.Fragment>
                        ) : null}
                        {tourItem.steps.map(
                          (step: TourConfigStepsType, i: number) => (
                            <Box
                              className={classes.step}
                              key={`${tourItem.id}-step-${i}`}
                              display="flex"
                              onClick={() => handleStepClick(step, i)}
                            >
                              <Typography className={classes.accordionHeading}>
                                {step.title}
                              </Typography>
                              <div className={classes.stepIcon}>
                                {step.complete ? (
                                  <CheckCircle />
                                ) : (
                                  <ChevronRight />
                                )}
                              </div>
                            </Box>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </React.Fragment>
              )}
            </Paper>
          </Box>
        </DialogContent>
      </Dialog>
      <Joyride
        callback={handleTourCallback}
        steps={currentSteps.map((step: TourConfigStepsType): any => ({
          content: step.content,
          placement: step.placement,
          target: step.target,
          continuous: false,
          disableBeacon: true,
          showProgress: true,
          spotlightClicks: false,
          styles: {
            options: {
              textAlign: "left",
              primaryColor: "#c410a0",
              zIndex: 10000,
            },
            ...step.styles,
          },
        }))}
        disableScrolling={
          currentSteps && currentSteps[tourCurrentIndex || 0]
            ? currentSteps[tourCurrentIndex || 0].disableScrolling
            : false
        }
        run={tourOpen}
        stepIndex={tourCurrentIndex}
        showProgress={true}
        showSkipButton={true}
        continuous
      />
    </React.Fragment>
  );
};
