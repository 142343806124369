import { AxiosRequestConfig } from "axios";
import { UserAgentApplication } from "msal";
import { SelectItem } from "../components/ChipSelector/ChipSelector";

export interface PropsInterface {
  config?: any;
  children: React.ReactNode;
}

export enum UserBehaviour {
  manager,
  subManager,
  readOnly,
  opsOversight,
  superUser,
  anonymous,
}

export type AxiosWithAuth = (config: AxiosRequestConfig) => Promise<any>;

export type UserType = {
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  userPrincipalName: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  officeLocation: string;
  preferredLanguage: string;
  country: string;
  identities: Array<{
    signInType: string;
    issuer: string;
    issuerAssignedId: string;
  }>;
};

export type AuthState = {
  experimentalFeatures: string[];
  status: string;
  error: any;
  user: any;
  accessToken: string;
  permissions: string[];
  appInsightsKey: string;
};

export type AdminUserContextType = {
  users: Array<UserType>;
  loadingUsers: boolean;
};

export type AuthContextType = {
  authState: AuthState;
  logout?: any;
  login?: any;
  getAccessToken?: any;
  getUserAccount?: any;
  axiosWithAuth: AxiosWithAuth | undefined;
};

export type AppContextType = {
  msalInstance?: UserAgentApplication;
  loginRequest?: AuthRequestType;
  passwordRequest?: AuthRequestType;
  buildNumber?: string;
  authState: AuthState;
  siteConfig?: ConfigDataServiceType;
  funds?: Array<Fund>;
  fundsByShortName?: Array<FundByShortName>;
  products?: Set<any>;
};

export type AuthRequestType = {
  scopes: Array<string>;
  authority: string;
};

export type ConfigDataServiceType = {
  AMX_PHOENIX_CLIENT_APP_ID: string;
  AMX_PHOENIX_CLIENT_APP_AUTHORITY: string;
  AMX_PHOENIX_CLIENT_APP_USER_FLOW: string;
  AMX_PHOENIX_CLIENT_APP_URL: string;
  AMX_PHOENIX_CLIENT_APP_REDIRECT: string;
  AMX_CONNECT_CLIENT_APP_URL: string;
  AMX_PHOENIX_API_URL: string;
  EXPERIMENTAL_FEATURES: string;
  BUILD_NO: string;
};

export type ErrorState = {
  title?: string;
  detail?: any;
};

export type Fund = {
  FUND_SK: string;
  FUND_AMX_ID: string;
  FUND_SHORT_NAME: string;
  FUND_NAME: string;
  FULL_FUND_NAME: string;
  PRODUCT_ID: string;
  EARLIEST_DATE: string;
  LATEST_DATE: string;
};

export type FundByShortName = {
  name?: string;
  value?: string;
};

export type FundValidationDataSummaryType = {
  FUND_ID: string;
  PRODUCT_ID: string;
  VALUATION_DATE_KEY: number;
  CLASS_SLA: number;
  TOP_SLA: number;
  INVESTOR_SLA: number;
  PORTFOLIO_SLA: number;
  DEAL_TECH: number;
  DEAL_EXCEPTIONS: string;
  TOP_TECH: number;
  TOP_EXCEPTIONS: string;
  CLASS_TECH: number;
  CLASS_EXCEPTIONS: string;
  INVESTOR_TECH: number;
  INVESTOR_EXCEPTIONS: string;
  PORTFOLIO_TECH: number;
  PORTFOLIO_EXCEPTIONS: string;
  Status_0: number;
};

export type FundValidationDataType = {
  FUND_ID: string;
  PRODUCT_ID: string;
  VALUATION_DATE_KEY: number;
  BUSINESS_RULE_CATEGORY: string;
  BUSINESS_RULE_SUB_CATEGORY: string;
  Status_0: number;
  Status_1: number;
  Status_Total: number;
};

export type NavItemConfig = {
  icon?: any;
  name: string;
  url: string;
  selected: boolean;
  allowedRoles: Array<string>;
};

export type StatusPermissionsKeyType =
  | "amxone-manager"
  | "amxone-sub-manager"
  | "amxone-read-only"
  | "amxone-ops-oversight"
  | "amxone-super-user";

export type StatusPermissionsType = {
  [permission in StatusPermissionsKeyType]: boolean;
};

export type TabPanelProps = {
  children?: React.ReactNode;
  index: any;
  value: any;
};

export type UserProfileType = {
  userType?: UserBehaviour;
  isSuperUser?: boolean;
  accounts?: AccountsType;
  selectedAccountId?: number | string;
  selectedTpaIds?: string;
  selectedFundIds?: string;
};

export type AccountType = {
  id: number;
  description: string;
  tpaIds?: string;
};

export type AccountsType = {
  investors: Array<AccountType>;
  advisors: Array<AccountType>;
  managers: Array<AccountType>;
  thirdParties: Array<AccountType>;
};

export type GenericTableColumn = {
  align?: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  format?: Function;
  id: string;
  label: string;
  link?: boolean;
};

export type NewFundItem = {
  _id?: string;
  launchTrackingNumber: string;
  launchStatus: "Underway" | "Ops ready" | "Funded" | "On hold" | "Cancelled";
  fundName: string;
  structure: string;
  activePassiveSms: string;
  productOwnerId?: string;
  productOwner: string;
  startDate: Date;
  documents?: Array<NewFundDocument>;
  tasks?: Array<NewFundTask>;
};

export type NewFundDocument = {
  _id?: string;
  date: Date;
  fileName: string;
  uploaderName: string;
  uploaderEmail: string;
  taskId: string;
};

export type NewFundComment = {
  _id?: string;
  date: Date;
  name: string;
  email: string;
  text: string;
};

export type NewFundTask = {
  _id?: string;
  completed: boolean;
  notApplicable: boolean;
  taskName: string;
  taskDescription: string;
  assignee: string;
  startDate: Date;
  targetCompletionDate: Date;
  actualCompletionDate: Date;
  completionWeight: Number;
  comments: Array<NewFundComment>;
  ragColor?: string;
};

export type InvestorOnboardingDocument = NewFundDocument;
export type InvestorOnboardingComment = NewFundComment;
export type InvestorOnboardingTask = NewFundTask & {
  comments: Array<InvestorOnboardingComment>;
};

export type InvestorOnboardingItem = {
  _id?: string;
  clientId: string;
  investorName: string;
  teamOwnerId?: string;
  teamOwner: string;
  startDate: Date;
  clientType: string;
  umbrellaType: string;
  complexity: string;
  strategy: string;
  fund: string;
  shareClass: string;
  launchStatus: "Underway" | "Ops ready" | "Funded" | "On hold" | "Cancelled";
  documents?: Array<InvestorOnboardingDocument>;
  tasks?: Array<InvestorOnboardingTask>;
};

export type PricingWorkflowColumnData = {
  fundValue?: number;
  portfolioManagementFee?: number;
  operationsFee?: number;
  amxFee?: number;
  taxTransparentSavings?: number;
  marketCptySavings?: number;
  totalCost?: number;
  totalSavings?: number;
  est12MonthSavings?: number;
};
export type PricingWorkflowColumn = {
  data: PricingWorkflowColumnData;
  updateFunction: Function;
};

export type PricingWorkflowComment = {
  _id?: string;
  active: boolean;
  date: Date;
  name: string;
  email: string;
  text: string;
  type: "user-input" | "audit-trail";
};

export type WorkflowUser = {
  id: string;
  name: string;
};
export type WorkflowTeam = {
  id: string;
  name: string;
};

export type PricingWorkflowDocument = {
  active: boolean;
  date: Date;
  fileName: string;
  uploaderName: string;
  uploaderEmail: string;
  taskId: string;
};

export type PricingWorkflowTask = {
  _id: string;
  status: string;
  taskName: string;
  assignee: WorkflowUser;
  assignedTeam: WorkflowTeam;
  age: number;
  parentId?: string;
  parentRef?: string;
  parentDisplayName?: string;
  assignedDate: Date;
  team?: string;
  startDate?: Date;
  targetCompletionDate?: Date;
  actualCompletionDate?: Date;
  value?: Number;
  valuesDictionary: any; // { 250: Number, 500: Number, 750: Number, 1000: Number } or { 600ab9c004fe503e5b22eade: Number, 600ab9c004fe503e5b22eadf: Number }
  comments?: Array<PricingWorkflowComment>;
};

export type PricingWorkflowItem = {
  _id?: string;
  active?: boolean;
  trackingId?: string;
  createdDate?: Date;
  creator?: WorkflowUser;
  fundID?: string;
  fundName?: string;
  fundAUM?: number;
  clientProposedAUM?: number;
  requestedDataPoints?: Array<any>; // [500, 750, 1000] (in millions)
  approved?: boolean;
  approvedDate?: Date;
  status?: "Draft" | "Ready for Approval" | "Pending Approval" | "Approved";
  tasks?: Array<PricingWorkflowTask>;
};

export type SearchResponse = {
  type: string;
  match: string;
};

export type DocumentMetadataType = {
  documentSk: number;
  documentTypeName: string;
  documentName: string;
  documentVersion: number;
  documentCreatedDatetime: Date | string;
  documentActiveFromDate: Date | string;
  documentNameUi: string;
  portfolioManagerShortName: string;
  investorName: string;
  customDocumentName: string;
  visibleFlag: string;
  _id: string;
};

export type DashboardContextType = {
  sharedDashboardState?: any;
  dashboardsDrilldownData?: any;
  dashboardsDrilldownLoading?: boolean;
  dashboardsDrilldownBaselineData?: any;
  dashboardsDrilldownBaselineLoading?: boolean;
  inspectionData?: any;
  setInspectionData?: any;
  drilldownFilters?: {
    [filterName: string]: SelectItem[];
  };
  setDrilldownFilters?: any;
  metricSelector?: any;
  setMetricSelector?: any;
};

export type DashboardData = {
  _id: string;
  name: string;
  widgets: Array<DashboardWidget>;
};

export type DashboardWidget = {
  _id: string;
  dashboardId: string;
  name: string;
  props?: any;
  size:
    | boolean
    | 1
    | "auto"
    | 2
    | 5
    | 3
    | 4
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | undefined;
};

export type WidgetWrapperProps = {
  children: any;
  data: any;
  title: string;
};
