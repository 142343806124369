import React, { useState } from "react";

import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * AMX Connect AppPanel Component
 * @component
 */

const useStyles = makeStyles((theme) => ({
  launchButton: {
    backgroundColor: "#000",
    color: "#fff",
    "&:hover": {
      background: "#757575",
      color: "#fff",
      boxShadow: "inset 0 0 0 1px #000",
    },
  },

  learnMoreButton: {
    color: "#757575",
  },

  card: {
    boxShadow: "0 2px 2px -2px #555",
    marginBottom: "5px",
    padding: "1em",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardHeader: {
    fontSize: "1em",
    fontWeight: "bold",
    color: "#757575",
  },

  cardContent: {
    padding: "0em",
  },

  cardLogo: {
    marginBottom: "1.2em",
    height: "33px",
    maxWidth: "95%",
  },

  cardDivider: {
    backgroundColor: "#ececec",
    height: "2px",
    marginTop: "1.2em",
  },

  cardActions: {
    padding: "1.8em 0 0",
    display: "flex",
    alignContent: "flex-end",
    justifyContent: "flex-end",
    marginTop: "auto",
  },
}));

export const AppPanel = (props: any) => {
  const {
    launchUrl,
    cardTitle,
    logoUrl,
    logoAlt,
    dialogContent,
    hideLearnMore,
  } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <Card className={classes.card} variant="outlined">
        <CardContent className={classes.cardContent}>
          <img alt={logoAlt} src={logoUrl} className={classes.cardLogo}></img>

          <Typography
            gutterBottom
            component="h2"
            className={classes.cardHeader}
          >
            {cardTitle}
          </Typography>
          <Divider className={classes.cardDivider}></Divider>
        </CardContent>

        <CardActions className={classes.cardActions}>
          {hideLearnMore ? null : (
            <Button
              className={classes.learnMoreButton}
              onClick={handleClickOpen}
            >
              Learn More
            </Button>
          )}
          <Button
            variant="contained"
            className={classes.launchButton}
            onClick={(e) => {
              launchUrl ? window.open(launchUrl, "_blank") : e.preventDefault();
            }}
          >
            Launch
          </Button>
        </CardActions>
      </Card>

      <Dialog maxWidth="md" fullWidth open={openDialog} onClose={handleClose}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img alt={logoAlt} src={dialogContent} width="100%" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleClose();
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
