import React from "react";
import { Grid, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

const data = [
  {
    globalPensionAssets: "US DB Plan",
  },
  {
    globalPensionAssets: "US DC Plan",
  },
  {
    globalPensionAssets: "EU DC Plan",
    accountType: "EUDC Ireland",
    userCount: 4,
    investmentCount: 3,
    activeServices: 26,
    activeApps: 3,
    activeData: 4,
  },
  {
    accountType: "EUDC France",
    userCount: 2,
    investmentCount: 1,
    activeServices: 27,
    activeApps: 3,
    activeData: 4,
  },
  {
    accountType: "EUDC Spain",
    userCount: 2,
    investmentCount: 1,
    activeServices: 26,
    activeApps: 3,
    activeData: 4,
  },
  {
    globalPensionAssets: "UK DC Plan",
    accountType: "UKDC England",
    userCount: 6,
    investmentCount: 2,
    activeServices: 29,
    activeApps: 2,
    activeData: 5,
  },
  {
    accountType: "UKDC Scotland",
    userCount: 4,
    investmentCount: 2,
    activeServices: 26,
    activeApps: 2,
    activeData: 5,
  },
  {
    accountType: "UKDC Wales",
    userCount: 1,
    investmentCount: 1,
    activeServices: 26,
    activeApps: 2,
    activeData: 5,
  },
];

const columns = [
  {
    name: "globalPensionAssets",
    label: "Global Pension Assets",
  },
  {
    name: "accountType",
    label: "Account Type",
  },
  {
    name: "userCount",
    label: "No of Users",
  },
  {
    name: "investmentCount",
    label: "No of Investments",
  },
  {
    name: "activeServices",
    label: "Active Services",
  },
  {
    name: "activeApps",
    label: "Active Apps",
  },
  {
    name: "activeData",
    label: "Active Data",
  },
];

const options: any = {
  elevation: 0,
  viewColumns: false,
  print: false,
  download: false,
  filter: false,
  search: false,
  fixedHeader: true,
  fixedSelectColumn: true,
  selectableRows: false,
};

const Accounts = () => {
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableCell: {
          root: {
            border: "1px solid #E0E0E0",
          },
        },
        MuiPaper: {
          root: {
            marginTop: 15,
          },
        },
        MuiTable: {},
      },
    });

  return (
    <Grid container>
      <Grid item xs={12}>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title=""
            columns={columns}
            data={data}
            options={options}
          />
        </MuiThemeProvider>
      </Grid>
    </Grid>
  );
};

export default Accounts;
