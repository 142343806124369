import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";
import ReactPlayer from "react-player";

/**
 * AMX Connect Header Component
 * @component
 */

const useStyles = makeStyles((theme) => ({
  videoWrapper: {
    display: "none",
    minHeight: "300px",
    minWidth: "300px",
    maxHeight: "20vh",
    marginBottom: "2em",
  },
}));

export const QuickStartVideo = (props: any) => {
  const { url } = props;
  const videoRef = useRef();
  const classes = useStyles();

  const videoReady = () => {
    videoRef.current.style.display = "block";
  };

  return (
    <div ref={videoRef} className={classes.videoWrapper}>
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        controls={false}
        volume={0}
        muted={true}
        playing
        onReady={() => videoReady()}
      />
    </div>
  );
};

export default QuickStartVideo;
