import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/AuthContextProvider";
import { queryStringToDictionary } from "../../helpers/QueryString";
import MyAMX from "./components/MyAMX/MyAMX";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Funds from "./components/funds/Funds";
import { View } from "../../components";
import Analytics from "./components/analytics/Analytics";
import AppsAndServices from "./AppsAndServices";

const titleTextInfo = "Service Information";
const titleTextEnquire = "Service Coming Soon";
const titleTextActivate = "Would You Like to Activate This Service";
const titleTextDeactivate = "Would You Like to Deactivate This Service";

const bodyTextInfo = `
This is a service that AMX provides to you as part of your package.`;
const bodyTextEnquire = `
AMX is working to provide this service to our clients.
Please contact Client Services for more information.`;
const bodyTextActivate = `
You do not currently have this service activated. If you would like to start using
it, please click the "Approve" button. You will be charged for this service.`;
const bodyTextDeactivate = `
This service is currently activated. If you would like to stop using
it, please click the "Approve" button. You will no longer be charged for this service.`;

export type PopUpConfig = {
  show: boolean;
  type: "info" | "enquire" | "activate";
  titleText: string;
  bodyText: string | ReactNode;
  serviceDomain: string;
  subServiceDomain: string;
  provider: string;
  selected: boolean;
  proposedState: any[];
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const Store = () => {
  const classes = useStyles();
  const [, setTab] = useState(0);

  const {
    axiosWithAuth,
    authState: { permissions },
  } = useAuth();
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();
  const [preferences, setPreferences] = useState({
    allOwlSelected: false,
    fundLevelOwlSelected: false,
  });
  const [summary, setSummary] = useState({
    ecosystemUsage: "0",
    investments: "0/0",
    totalAUM: "0",
  });

  const showMyAMX =
    permissions.includes("investor") ||
    permissions.includes("demo-global-reader");

  const { section, fund } = queryStringToDictionary(location.search);
  const title =
    section !== undefined
      ? section
          .split("-")
          .map(
            (word: string) => word.substr(0, 1).toUpperCase() + word.substr(1)
          )
          .join(" ")
      : "";

  const {
    data: serviceDomains,
    isLoading: serviceDomainsIsLoading,
    // isFetching: serviceDomainsIsFetching,
  } = useQuery(
    "serviceDomains",
    () => {
      return axiosWithAuth!({
        url: "/store",
      }).then((data) => {
        setPreferences(data.owlStatus);

        setSummary({
          ecosystemUsage: data.ecosystemUsage,
          investments: data.investments,
          totalAUM: data.totalAUM,
        });
        return data.domains.map((domain: any) => ({
          ...domain,
          isExpanded:
            domain.serviceDomainName === title ? true : domain.isExpanded,
        }));
      });
    },
    {
      onSuccess: (data: any) => {
        if (fund !== undefined) {
          updatePopup(data);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const updatePopup = useCallback(
    (data) => {
      const owlAnalytics = data.reduce((foundItem: any, domain: any) => {
        if (foundItem !== undefined) return foundItem;
        let found: any;

        domain.subServiceDomains.forEach((subServiceDomain: any) =>
          subServiceDomain.providers.forEach((provider: any) => {
            if (provider.interactionType === "activate") {
              found = provider;
            }
          })
        );

        return found;
      }, undefined);

      if (owlAnalytics === undefined) return;

      setPopUp((prev) => ({
        ...prev,
        show: true,
        type: owlAnalytics.interactionType,
        bodyText: (
          <MarkdownPreview
            source={`${owlAnalytics.markdown}\n### Fund\n${decodeURI(fund)}`}
          />
        ),
      }));
    },
    [fund]
  );

  const { returnUrl, fundId } = queryStringToDictionary(location.search);
  const fundSelected = fundId !== undefined;
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);

  useEffect(() => {
    if (fundSelected && serviceDomains !== undefined) {
      updatePopup(serviceDomains);
    }
  }, [updatePopup, fundSelected, serviceDomains]);

  const [popUp, setPopUp] = useState<PopUpConfig>({
    show: fundSelected,
    type: "activate",
    titleText: titleTextActivate,
    bodyText: bodyTextActivate,
    serviceDomain: "Sustainable Finance",
    subServiceDomain: "ESG Data",
    provider: "OWL Analytics",
    selected: false,
    proposedState: [],
  });

  const handleDialogApprove = useMutation(
    (action: string) => {
      return axiosWithAuth!({
        url: "/preferences/services",
        method: "POST",
        data: {
          name:
            action === "addAll" || action === "removeAll"
              ? "owl-all"
              : `owl-${fundId}`,
          enabled: action === "addSingle" ? true : action === "addAll",
        },
      });
    },
    {
      onMutate: (action: string) => {
        queryClient.setQueryData("serviceDomains", popUp.proposedState);
        setPreferences({
          allOwlSelected:
            action === "addAll"
              ? true
              : action === "removeAll"
              ? false
              : preferences.allOwlSelected,
          fundLevelOwlSelected:
            action === "addSingle"
              ? true
              : action === "removeAll"
              ? false
              : preferences.fundLevelOwlSelected,
        });
        setShowConfirmPopUp(false);
        setPopUp({ ...popUp, show: false, proposedState: [] });
        if (returnUrl !== undefined) {
          history.push(returnUrl);
        }
      },
      onSuccess: (data: any, action: string) => {
        queryClient.invalidateQueries("serviceDomains");
      },
    }
  );

  if (serviceDomainsIsLoading) {
    return <View data-testid="contact-us" isLoading={true} />;
  }

  const handleServiceClick = (
    selectedProvider: number,
    selectedSubServiceDomain: number,
    selectedServiceDomain: number,
    item: any
  ) => {
    setPopUp({
      show: true,
      type: item.interactionType,
      titleText:
        item.interactionType === "info"
          ? titleTextInfo
          : item.interactionType === "enquire"
          ? titleTextEnquire
          : item.selected
          ? titleTextDeactivate
          : titleTextActivate,
      bodyText: item?.markdown ? (
        <MarkdownPreview source={item.markdown} />
      ) : item.interactionType === "info" ? (
        bodyTextInfo
      ) : item.interactionType === "enquire" ? (
        bodyTextEnquire
      ) : item.selected ? (
        bodyTextDeactivate
      ) : (
        bodyTextActivate
      ),
      serviceDomain: serviceDomains[selectedServiceDomain].serviceDomainName,
      subServiceDomain:
        serviceDomains[selectedServiceDomain].subServiceDomains[
          selectedSubServiceDomain
        ].subServiceDomainName,
      provider: item.itemName,
      selected: !item.selected,
      proposedState: serviceDomains.map(
        (serviceDomain: any, serviceDomainIndex: number) => ({
          ...serviceDomain,
          using:
            serviceDomains[selectedServiceDomain].subServiceDomains[
              selectedSubServiceDomain
            ].providers[selectedProvider].itemName === "OWL Analytics" &&
            serviceDomain.serviceDomainName === "Sustainable Finance"
              ? preferences.allOwlSelected
                ? item.selected
                  ? 1
                  : 2
                : 1
              : serviceDomain.using,
          subServiceDomains: serviceDomain.subServiceDomains.map(
            (subService: any, subServiceDomainIndex: number) => ({
              ...subService,
              providers: subService.providers.map(
                (provider: any, providerIndex: number) => ({
                  ...provider,
                  selected:
                    serviceDomainIndex === selectedServiceDomain &&
                    subServiceDomainIndex === selectedSubServiceDomain &&
                    providerIndex === selectedProvider
                      ? !provider.selected
                      : provider.selected,
                })
              ),
            })
          ),
        })
      ),
    });
  };

  const handleDialogClose = () => {
    setPopUp((prev) => ({ ...prev, show: false, proposedState: [] }));
  };

  return (
    <View data-testid="contact-us" isLoading={serviceDomainsIsLoading}>
      {serviceDomains.length > 0 && (
        <Fragment>
          <Dialog open={popUp.show} onClose={handleDialogClose} maxWidth="xl">
            <DialogTitle>{popUp.titleText}</DialogTitle>
            <DialogContent>
              <DialogContentText>{`${popUp.serviceDomain} > ${popUp.subServiceDomain} > ${popUp.provider}`}</DialogContentText>
              <DialogContentText>{popUp.bodyText}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                {popUp.type === "activate" ? "Cancel" : "Close"}
              </Button>

              {fund === undefined &&
              popUp.type === "activate" &&
              !preferences.allOwlSelected ? (
                <Button
                  onClick={() => handleDialogApprove.mutate("addAll")}
                  color="primary"
                  autoFocus
                >
                  Add for all Funds
                </Button>
              ) : null}

              {fund === undefined &&
              popUp.type === "activate" &&
              (preferences.allOwlSelected ||
                preferences.fundLevelOwlSelected) ? (
                <Button
                  onClick={() => setShowConfirmPopUp(true)}
                  color="primary"
                  autoFocus
                >
                  Remove for all Funds
                </Button>
              ) : null}

              {(fund !== undefined && popUp.type) === "activate" ? (
                <Button
                  onClick={() => handleDialogApprove.mutate("addSingle")}
                  color="primary"
                  autoFocus
                >
                  Add for selected fund
                </Button>
              ) : null}
            </DialogActions>
          </Dialog>
          <Dialog open={showConfirmPopUp}>
            <DialogTitle>Delete OWL Analytics for All Funds</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove OWL Analytics reporting for all
                of your funds?
              </DialogContentText>
              <DialogContentText>
                You can add OWL reporting again at a later date, if you change
                your mind.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDialogApprove.mutate("removeAll")}>
                Yes
              </Button>
              <Button
                color="primary"
                autoFocus
                onClick={() => setShowConfirmPopUp(false)}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>

          <div className={classes.root}>
            <AppBar position="static" color="transparent" elevation={0}>
              {showMyAMX && (
                <Tabs
                  value={location.pathname}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab
                    value={
                      location.pathname === "/store"
                        ? "/store"
                        : "/store/my-amx"
                    }
                    label="MY AMX"
                    component={Link}
                    to="/store/my-amx"
                    data-tour="store-tab-my-amx"
                  />
                  <Tab
                    value="/store/funds"
                    label="FUNDS"
                    component={Link}
                    to="/store/funds"
                    data-tour="store-tab-funds"
                  />
                  <Tab
                    value="/store/analytics"
                    label="ANALYTICS"
                    component={Link}
                    to="/store/analytics"
                    data-tour="store-tab-analytics"
                  />
                  <Tab
                    value="/store/apps-and-services"
                    label="APPS & Services"
                    component={Link}
                    to="/store/apps-and-services"
                    data-tour="store-tab-apps-and-services"
                  />
                  {/* {permissions.includes("store-admin") && (
                    <Tab
                      value="/store/admin"
                      label="ADMIN"
                      component={Link}
                      to="/store/admin"
                      data-tour="store-tab-admin"
                    />
                  )} */}
                </Tabs>
              )}
            </AppBar>
          </div>
          <Switch>
            {showMyAMX && (
              <Route
                path={
                  location.pathname === "/store" ? "/store" : "/store/my-amx"
                }
                render={() => (
                  <MyAMX
                    setTab={setTab}
                    categories={serviceDomains}
                    onServiceClick={handleServiceClick}
                    summary={summary}
                  />
                )}
              />
            )}
            <Route
              path="/store/funds"
              render={() => (
                <Funds
                  showEsg={
                    !(
                      permissions.includes("factsheet-qir") ||
                      permissions.includes("third-party")
                    )
                  }
                />
              )}
            />
            <Route path="/store/analytics" render={() => <Analytics />} />
            <Route
              path="/store/apps-and-services"
              render={() => (
                <AppsAndServices
                  categories={serviceDomains.sort(
                    (
                      a: { serviceDomainName: string },
                      b: { serviceDomainName: string }
                    ) =>
                      a.serviceDomainName === title
                        ? -1
                        : b.serviceDomainName === title
                        ? 1
                        : a.serviceDomainName > b.serviceDomainName
                        ? 1
                        : -1
                  )}
                  onServiceClick={handleServiceClick}
                />
              )}
            />
            {/* {permissions.includes("store-admin") && (
                <Route
                  path="/store/admin"
                  render={() => (
                    <Admin
                      serviceDomains={serviceDomains}
                      serviceDomainsIsFetching={serviceDomainsIsFetching}
                    />
                  )}
                />
              )} */}
          </Switch>
        </Fragment>
      )}
    </View>
  );
};

export default Store;
