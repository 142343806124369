import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const DeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="17.659"
      height="17.81"
      viewBox="0 0 17.659 17.81"
    >
      <g id="Group" transform="translate(-13 -12.863)">
        <rect
          id="Path"
          width="8"
          height="2"
          transform="translate(17.803 12.863)"
          fill="#707070"
        />
        <path
          id="Compound_Path"
          d="M13,26.932h2.219l1.193,13.244H27.246l1.193-13.244h2.219V25.5H13ZM25.958,38.744H17.7l-1.05-11.812H27.008Z"
          transform="translate(0 -9.503)"
          fill="#707070"
        />
      </g>
    </SvgIcon>
  );
};
