import React, { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import MUIDataTable from "mui-datatables";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import { Delete, GetApp } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { NewFundDocument } from "../../../../common/types";
import { euroFormat, exportFile } from "../../../../helpers";
import { useAuth } from "../../../../providers";
import { useMyTasks } from "../MyTasks";

const useStyles = makeStyles({
  deleteIcon: {
    color: grey[500],
    opacity: 0.5,
    transition: "all 0.5s ease",

    "&:hover": {
      color: red[500],
      opacity: 1,
    },
  },
  dropzone: {
    textAlign: "center",

    "& .MuiDropzoneArea-root": {
      marginBottom: "10px",
      padding: "20px",
    },
  },
  formControl: {
    marginBottom: "10px",
  },
});

const getOriginalFileName = (fileName: string) => {
  return fileName.substring(fileName.indexOf("_") + 1, fileName.length);
};
export const TaskDialogDocuments = () => {
  const { selectedTask } = useMyTasks();
  const { axiosWithAuth } = useAuth();
  const classes = useStyles();
  const [files, setFiles] = useState<Array<File>>([]);

  const queryClient = useQueryClient();
  const handleDocUpload = useMutation(
    (files: File[]) => {
      const fileUploads: Array<Promise<any>> = files.map((file: File) => {
        let formData = new FormData();
        formData.append("fileData", file);
        return axiosWithAuth!({
          url: `pricing-workflow/${selectedTask.parentId}/${selectedTask._id}/documents`,
          method: "POST",
          headers: {
            "content-type": "multipart/form-data",
            connection: "keep-alive",
          },
          data: formData,
        });
      });
      return Promise.all(fileUploads);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("task");
      },
    }
  );

  const handleDocDownload = useMutation(
    (fileName: string) => {
      return axiosWithAuth!({
        url: `pricing-workflow/${selectedTask.parentId}/${selectedTask._id}/document?fileName=${fileName}`,
        method: "GET",
        responseType: "blob",
      });
    },
    {
      onSuccess: (data, fileName) => {
        exportFile(getOriginalFileName(fileName), data);
      },
    }
  );

  const handleDocDelete = useMutation(
    (fileName: string) => {
      return axiosWithAuth!({
        url: `pricing-workflow/${selectedTask.parentId}/${selectedTask._id}/documents?fileName=${fileName}`,
        method: "DELETE",
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("task");
      },
    }
  );

  const columns = [
    {
      name: "_id",
      label: "id",
      display: "excluded",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fileName",
      label: "File Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return euroFormat(value);
        },
      },
    },
    {
      name: "uploaderName",
      label: "Uploader",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "uploaderEmail",
      label: "Uploader Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "downloadDoc",
      label: " ",
      options: {
        filter: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { fileName } = value;
          return (
            <IconButton
              aria-label="Download this document"
              color="primary"
              onClick={() => {
                handleDocDownload.mutate(fileName);
              }}
            >
              <GetApp />
            </IconButton>
          );
        },
      },
    },
    {
      name: "deleteDoc",
      label: " ",
      options: {
        filter: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const { fileName } = value;
          return (
            <IconButton
              aria-label="Delete this document"
              color="primary"
              className={classes.deleteIcon}
              onClick={() => {
                handleDocDelete.mutate(fileName);
              }}
            >
              <Delete />
            </IconButton>
          );
        },
      },
    },
  ];

  const data = selectedTask.documents.map((document: NewFundDocument) => ({
    ...document,
    downloadDoc: { fileName: document.fileName, taskId: document.taskId },
    deleteDoc: { fileName: document.fileName, taskId: document.taskId },
  }));

  return (
    <React.Fragment>
      {handleDocUpload.isError ? (
        <Alert severity="error">
          <AlertTitle>There was an error uploading that.</AlertTitle>
        </Alert>
      ) : null}
      <Grid container spacing={4}>
        <Grid item sm={3} className={classes.dropzone}>
          <React.Fragment>
            <DropzoneArea
              filesLimit={1}
              onChange={(files: File[]) => setFiles(files)}
              maxFileSize={7000000}
              useChipsForPreview
              acceptedFiles={[
                "text/plain",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessing",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/msword",
                "application/vnd.ms-excel",
                "application/vnd.ms-powerpoint",
                "text/html",
                "image/jpeg",
                "image/tif",
              ]}
            ></DropzoneArea>
            <Button onClick={() => handleDocUpload.mutate(files)}>
              Upload
            </Button>
          </React.Fragment>
          {handleDocUpload.isLoading && <LinearProgress />}
        </Grid>
        <Grid item sm={9}>
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={{
              elevation: 0,
              download: false,
              print: false,
              filter: false,
              viewColumns: false,
              pagination: false,
              selectToolbarPlacement: "none",
              selectableRows: "none",
              rowsPerPage: 5,
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
