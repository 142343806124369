import React from "react";
import { LinearProgress } from "@material-ui/core";
import { DashboardWidget } from "../../../common/types";
import { PieChart } from "../../../components";
import { useSharedDashboardData } from "../../../providers";
import { WidgetWrapper } from "./WidgetWrapper";

type FundsByStrategyProps = DashboardWidget & {};

//! TODO: Consolidate all these PieChart widgets into one and make the `valueProperty` a prop stored against the widget in User Preferences -> Dashboards.
export const FundsByStrategy = (props: FundsByStrategyProps) => {
  const {
    dashboardsDrilldownData,
    dashboardsDrilldownLoading,
  } = useSharedDashboardData();

  return (
    <WidgetWrapper data={props} title="Funds by Strategy">
      {dashboardsDrilldownLoading ? <LinearProgress /> : null}
      {dashboardsDrilldownData ? (
        <PieChart
          data={dashboardsDrilldownData}
          category="strategy"
          valueProperty="aumInFundCcy"
        />
      ) : null}
    </WidgetWrapper>
  );
};
