import React from "react";
import { View } from "../../components/View/View";
import { AppPanel } from "../../components/AppPanel/AppPanel";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddAppPanel } from "../../components/AppPanel/AddAppPanel";
import { useService } from "../../providers";

const panels = [
  {
    cardTitle: "THE FUTURE OF INVESTING NOW",
    logoUrl: "/images/alpima-logo.png",
    logoAlt: "Alpima: smart investing made simple",
    launchUrl: "https://amx.alpima.net/login",
    dialogContent: "/images/alpima-dialog.png",
    name: "alpima",
  },
  // {   // removed for a demo
  //   cardTitle: "PLAN ACCOUNTING",
  //   logoUrl: "/images/wtw-logo.svg",
  //   logoAlt: "Willis Towers Watson",
  //   launchUrl: "https://quantify.towerswatson.com/channel",
  //   dialogContent: "/images/wtw-dialog.png",
  //   name: "wtw",
  // },
  {
    cardTitle: "MACRO & EQUITY RESEARCH",
    logoUrl: "/images/butterwire-logo.svg",
    logoAlt: "Alpima: smart investing made simple",
    launchUrl: "https://app.butterwire.com/market",
    name: "butterwire",
  },

  {
    cardTitle: "INVESTOR ONBOARDING",
    logoUrl: "/images/amx-connect-logo.png",
    logoAlt: "AMX Connect",
    name: "amx",
  },
  {
    cardTitle: "FUND LAUNCHER",
    logoUrl: "/images/amx-connect-logo.png",
    logoAlt: "AMX Connect",
    name: "amx",
  },
  {
    cardTitle: "PRICING WORKFLOW",
    logoUrl: "/images/amx-connect-logo.png",
    logoAlt: "AMX Connect",
    name: "amx",
  },
  {
    cardTitle: "DATA OPERATIONS",
    logoUrl: "/images/amx-connect-logo.png",
    logoAlt: "AMX Connect",
    launchUrl: "/apps/data-operations",
    name: "amx",
  },
  {
    cardTitle: "BOARD MEETING MANAGER",
    logoUrl: "/images/amx-connect-logo.png",
    logoAlt: "AMX Connect",
    launchUrl: "/explorer/documents?defaultDocType=Board Meeting Manager",
    name: "amx",
    hideLearnMore: true,
  },
];

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    borderBottom: "2px solid #ececec",
    paddingBottom: "0.3em",
    marginBottom: "1.2em",
  },

  addMorePanel: {
    backgroundColor: "#333",
    height: "100%",
    padding: "12px",
  },
}));

export const Apps = () => {
  const classes = useStyles();
  const { serviceData } = useService();

  const getAppPanels = panels.map((panel, i) => {
    if (panel.name.toLowerCase() === "alpima") {
      const alpima = serviceData.filter(
        (item) => item.serviceProvider.toLowerCase() === "alpima"
      );
      if (!alpima[0].purchased) {
        return null;
      }
    }
    if (panel.name.toLowerCase() === "butterwire") {
      const butterwire = serviceData.filter(
        (item) => item.serviceProvider.toLowerCase() === "butterwire"
      );
      if (!butterwire[0].purchased) {
        return null;
      }
    }

    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        key={i}
        data-tour={"app-" + panel.cardTitle.toLowerCase().replace(/ /, "-")}
      >
        <AppPanel {...panel} />
      </Grid>
    );
  });

  return (
    <View>
      <Typography
        className={classes.pageHeader}
        variant="h2"
        data-tour="apps-title"
      >
        Included in your subscription
      </Typography>

      <Grid container spacing={3}>
        {getAppPanels}

        <Grid item xs={12} sm={6} md={4}>
          <AddAppPanel />
        </Grid>
      </Grid>
    </View>
  );
};
