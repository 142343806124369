import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Paper } from "@material-ui/core";
import { View } from "../../components";
import { Switch, Route, Link } from "react-router-dom";
import { MyTasks } from "./my-tasks/MyTasks";
import { ServiceConnections } from "./ServiceConnections";
import { Messages } from "./Messages";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

export const Connect = () => {
  const classes = useStyles();

  return (
    <View>
      <div className={classes.root}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            value={window.location.pathname}
          >
            <Tab
              label="Tasks"
              component={Link}
              to="/connect"
              value="/connect"
              data-tour="tab-connect-tasks"
            />
            <Tab
              label="Messages"
              component={Link}
              to="/connect/messages"
              value="/connect/messages"
              data-tour="tab-connect-messages"
            />
            <Tab
              label="Service Connections"
              component={Link}
              to="/connect/service-connections"
              value="/connect/service-connections"
              data-tour="tab-connect-service-connections"
            />
          </Tabs>
        </AppBar>
        <Paper elevation={0} square>
          <Switch>
            <Route path="/connect/messages" render={() => <Messages />} />
            <Route
              path="/connect/service-connections"
              render={() => <ServiceConnections />}
            />
            <Route path="/connect" render={() => <MyTasks />} />
          </Switch>
        </Paper>
      </div>
    </View>
  );
};
