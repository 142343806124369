import { Grid } from "@material-ui/core";
import React from "react";
import { DashboardData, DashboardWidget } from "../../common/types";
import { DashboardContextProvider } from "../../providers";
import { FundsByCurrency } from "./widgets/FundsByCurrency";
import { FundsBymanager } from "./widgets/FundsByManager";
import { FundsByStrategy } from "./widgets/FundsByStrategy";
import { DefaultCreate } from "./widgets/DefaultCreate";
import { Reports } from "./widgets/Reports";
import { MyFunds } from "./widgets/MyFunds";
import { TotalMarketValue } from "./widgets/TotalMarketValue";
import { FundInspectorDetail } from "./widgets/FundInspectorDetail";
import { FundDrillDown } from "./widgets/FundDrillDown";
import { FundDrillDownFilters } from "./widgets/FundDrillDownFilters";
import { FundsDrillDownPie } from "./widgets/FundsDrillDownPie";
import { FundInspectorSearchFilter } from "./widgets/FundInspectorSearchFilter";
import { FundInspectorExposure } from "./widgets/FundInspectorExposure";

type DashboardProps = {
  data: DashboardData;
};

export const WidgetDict: any = {
  FundsByCurrency,
  FundsBymanager,
  FundsByStrategy,
  MyFunds,
  TotalMarketValue,
  FundDrillDown,
  FundDrillDownFilters,
  FundsDrillDownPie,
  Reports,
  FundInspectorDetail,
  FundInspectorSearchFilter,
  FundInspectorExposure,
};

export const Dashboard = (props: DashboardProps) => {
  const { data } = props;

  return (
    <DashboardContextProvider>
      <Grid container spacing={2} data-tour="dashboard-container">
        {data.widgets.map((widget: DashboardWidget, index: number) => (
          <Grid
            key={`${data._id}-${index}`}
            item
            xs={widget.size}
            data-tour={`dashboard-widget-${index}`}
          >
            {React.createElement(
              widget.name ? WidgetDict[widget.name] : DefaultCreate,
              {
                ...widget,
                dashboardId: data._id,
                ...widget.props,
              }
            )}
          </Grid>
        ))}
      </Grid>
    </DashboardContextProvider>
  );
};
