import React from "react";
import { Grid } from "@material-ui/core";
import { DashboardWidget } from "../../../common/types";
import CollapsibleTable from "../../../components/CollapsibleTable/CollapsibleTable";
import { useSharedDashboardData } from "../../../providers";
import { WidgetWrapper } from "./WidgetWrapper";

type FundDrillDownProps = DashboardWidget & {};

export const FundDrillDown = (props: FundDrillDownProps) => {
  const {
    dashboardsDrilldownBaselineData,
    dashboardsDrilldownBaselineLoading,
  } = useSharedDashboardData();

  return (
    <WidgetWrapper data={props} title="Fund DrillDown">
      <Grid container>
        {dashboardsDrilldownBaselineLoading ? (
          "LOADING..."
        ) : (
          <CollapsibleTable
            investments={dashboardsDrilldownBaselineData.tableData}
          />
        )}
      </Grid>
    </WidgetWrapper>
  );
};
