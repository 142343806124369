import React from "react";
import { Grid, LinearProgress, Typography } from "@material-ui/core";

import "./TabView.css";

export const TabView = (props: any) => {
  const { children, className, isLoading, title, buttonGroup, ...rest } = props;
  const combinedClassName =
    className !== undefined ? `TabView ${className}` : "TabView";

  return (
    <>
      {isLoading ? (
        <div
          className={combinedClassName}
          style={{ padding: "unset" }}
          {...rest}
        >
          <LinearProgress />
        </div>
      ) : (
        <div className={combinedClassName} {...rest}>
          {title ? (
            <Grid container item xs={12} className="TabViewTitleContainer">
              <Typography gutterBottom variant="h3" className="TabViewTitle">
                {title}
              </Typography>
              {buttonGroup}
            </Grid>
          ) : null}

          <Grid item xs={12} className="TabViewContent">
            {children}
          </Grid>
        </div>
      )}
    </>
  );
};
