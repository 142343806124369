import { Box, Grid, InputLabel, Select, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { CustomLogo } from "../../CustomLogo";
import { QuickStartVideo } from "./QuickStartVideo";

const useStyles = makeStyles((theme) => ({
  gridWrap: {
    marginTop: "30px",
    marginBottom: "30px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  bodyText: {
    marginBottom: "2em",
    textAlign: "center",
  },

  logoWrap: {
    "& > img": {
      display: "none",
    },
  },

  formControl: {
    minWidth: 300,
    "& .Mui-disabled": {
      color: "rgba(0, 0, 0, 0.7)",
    },
  },
}));

const Step3 = () => {
  const classes = useStyles();
  const [orgName, setOrgName] = useState<string>("");

  return (
    <Grid className={classes.gridWrap}>
      <Grid item xs={12}>
        <Box justifyContent="center" display="flex">
          <QuickStartVideo url="/videos/how.mp4" />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          justifyContent="center"
          display="flex"
          width="100%"
          className={classes.bodyText}
        >
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">
              Name of your organisation
            </InputLabel>
            <Select
              value={orgName}
              onChange={(event: any) => setOrgName(event?.target.value || "")}
            >
              <option value=""></option>
              <option value="Pension Company">Pension Company</option>
              <option value="Asset Manager">Asset Manager</option>
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          justifyContent="center"
          display="flex"
          width="100%"
          className={classes.logoWrap}
        >
          <CustomLogo />
        </Box>
      </Grid>
    </Grid>
  );
};
export default Step3;
