import React, { Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Options } from "highcharts/highstock";

const convertDataToBarOptions = (
  data: any,
  section: string,
  titlePrefix: string
): Options => {
  const seriesData = data[section]
    ? data[section].map((category: any, index: number) => ({
        name: category[section],
        value: parseFloat(category.fundPc),
      }))
    : [];

  return {
    credits: {
      enabled: false,
    },
    chart: {
      height: 400,
      inverted: true,
    },
    title: { text: `${titlePrefix} Breakdown (%)` },
    xAxis: {
      categories: seriesData.map((item: any) => item.name),
    },
    yAxis: { title: { text: "" } },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: "{y}%",
        },
      },
    },
    series: [
      {
        name: section,
        type: "bar",
        data: seriesData.map((item: any) => item.value),
        showInLegend: false,
      },
    ],
  };
};

const FundExposureTabContent = (props: { fundInfo: any }) => {
  const {
    fundInfo,
    fundInfo: { valuationDate },
  } = props;

  const exposureData = {
    country: fundInfo.fundInfo.exposures.country,
    currency: fundInfo.fundInfo.exposures.currency,
    sectorBreakdown: fundInfo.fundInfo.exposures.sectorBreakdown,
  };

  const countryOptions =
    exposureData.country.length > 0
      ? convertDataToBarOptions(exposureData, "country", "Country")
      : null;

  const currencyOptions =
    exposureData.currency.length > 0
      ? convertDataToBarOptions(exposureData, "currency", "Currency")
      : null;

  const sectorOptions =
    exposureData.sectorBreakdown.length > 0
      ? convertDataToBarOptions(exposureData, "sectorBreakdown", "Sector")
      : null;

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Typography style={{ margin: "20px 0 30px 0" }} variant="h5">
            Top 10 Exposures (as at {valuationDate})
          </Typography>
        </Grid>
        {countryOptions === null ? null : (
          <HighchartsReact highcharts={Highcharts} options={countryOptions} />
        )}
        {currencyOptions === null ? null : (
          <HighchartsReact highcharts={Highcharts} options={currencyOptions} />
        )}
        {sectorOptions === null ? null : (
          <HighchartsReact highcharts={Highcharts} options={sectorOptions} />
        )}
      </Grid>
    </Fragment>
  );
};

export default FundExposureTabContent;
