import React, { useRef, useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
const OverflowTip = (props: any) => {
  const textElementRef: any = useRef();
  const [hoverStatus, setHover] = useState<boolean>(false);

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollHeight >
        textElementRef.current.offsetHeight ||
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;

    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  return (
    <Tooltip disableHoverListener={!hoverStatus} {...props}>
      {React.cloneElement(props.children, {
        ref: textElementRef,
      })}
    </Tooltip>
  );
};

export default OverflowTip;
