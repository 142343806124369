import React from "react";
import { FormControl, MenuItem, Select, InputLabel } from "@material-ui/core";

const UserSelect = (props: {
  className?: string;
  disabled?: boolean;
  label?: string;
  onUserChange: Function;
  title?: string;
  value?: string;
  variant?: "filled" | "standard" | "outlined";
  users: any;
}) => {
  const {
    className,
    disabled,
    label,
    onUserChange,
    title,
    value,
    variant,
    users,
  } = props;

  return (
    <FormControl
      fullWidth
      size="small"
      className={className || ""}
      variant={variant}
      title={title}
    >
      <React.Fragment>
        {label ? <InputLabel id="user-select-label">{label}</InputLabel> : null}
        <Select
          disabled={disabled}
          label={label}
          labelId="user-select-label"
          id="user-select"
          value={value}
          variant={variant}
          onChange={(event: any) => {
            const value =
              event.target.value === ""
                ? { displayName: "", id: "" }
                : users.find((user: any) => user.id === event.target.value);
            onUserChange(value);
          }}
          required={true}
        >
          <MenuItem value="">
            <em>none</em>
          </MenuItem>
          {users.map((user: any) => (
            <MenuItem key={user.id} value={user.id}>
              {user.displayName}
            </MenuItem>
          ))}
        </Select>
      </React.Fragment>
    </FormControl>
  );
};

export default UserSelect;
