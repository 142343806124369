import React from "react";
import {
  ConnectIcon,
  DashboardIcon,
  ExploreIcon,
  RocketIcon,
  StoreIcon,
} from "../Icons";
/**
 * Callback function that gets called when the user makes a selection on the user menu.
 * @typedef {Object} generateSideBarConfigParams
 * @property {[string]} permissions - Array of users permissions to pass into this function.
 * @property {string} currentPath - String that represents the current path.
 * @property {string} currentApp - String that represemts the current app. e.g. "DRE", "DODO", "AMXConnect"
 */
/**
 * A function that returns an array of NavItem parameters for the SideBar component.
 * @param {...generateSideBarConfigParams} params - A {@link generateSideBarConfigParams} object
 */
export const generateSideBarConfig = ({
  permissions,
  currentPath,
  currentApp,
}) => {
  const hasPermission = (allowedRoles) => {
    return allowedRoles.some((role) => permissions.indexOf(role) >= 0);
  };

  const isDev = process.env.NODE_ENV === "development";

  const navConfig = [];

  if (hasPermission(["manager", "advisor", "investor", "demo-global-reader"])) {
    navConfig.push({
      icon: <DashboardIcon />,
      name: "Dashboards",
      path: "/dashboards",
      id: "dashboards",
      selected: /^(\/|\/dashboards.*)$/.test(currentPath),
      useHistory: currentApp === "AMXConnect",
      usePort: isDev ? 3000 : null,
    });
  }

  navConfig.push({
    icon: <ExploreIcon />,
    name: "Explorer",
    path: "/explorer",
    id: "explorer",
    selected: /^(\/explorer.*)$/.test(currentPath),
    useHistory: currentApp === "AMXConnect",
    usePort: isDev ? 3000 : null,
  });

  navConfig.push({
    icon: <ConnectIcon />,
    name: "Connect",
    path: "/connect",
    id: "connect",
    selected: /^(\/connect.*)$/.test(currentPath),
    useHistory: currentApp === "AMXConnect",
    usePort: isDev ? 3000 : null,
  });

  if (hasPermission(["investor", "advisor", "manager", "demo-global-reader"])) {
    navConfig.push({
      icon: <StoreIcon />,
      name: "Store",
      path: "/store",
      id: "store",
      selected: /^(\/store.*)$/.test(currentPath),
      useHistory: currentApp === "AMXConnect",
      usePort: isDev ? 3000 : null,
    });
  }

  navConfig.push({
    icon: <RocketIcon />,
    name: "Apps",
    path: "/apps",
    id: "apps",
    selected: /^(\/apps.*)$/.test(currentPath),
    useHistory: currentApp === "AMXConnect",
    usePort: isDev ? 3000 : null,
  });

  return navConfig;
};
