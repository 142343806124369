import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { PersonOutline } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          border: "1px solid #E0E0E0",
        },
      },
      MuiPaper: {
        root: {
          marginTop: 15,
          "& [class*=liveAnnounce]": {
            display: "none",
          },
          "& [aria-live=polite]": {
            display: "none",
          },
        },
      },
      MuiTable: {
        root: {},
      },
    },
  });

const Users = () => {
  const columns: any = [
    {
      name: "",
      display: "false",
      options: {
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return <PersonOutline />;
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "role",
      label: "Role",
    },
  ];

  const data = [
    {
      name: "Josh Cornell",
      email: "Josh.Cornell@demo.com",
      role: "Investor",
    },
    {
      name: "Jeff Ament",
      email: "Jeff.Ament@demo.com",
      role: "INED",
    },
    {
      name: "Aaron Cameron",
      email: "Aaron.Cameron@demo.com",
      role: "INED",
    },
    {
      name: "Silvia Waters",
      email: "Silvia.Waters@demo.com",
      role: "Investor",
    },
    {
      name: "Christina Mahony",
      email: "Christina.Mahony@demo.com",
      role: "Investor",
    },
    {
      name: "Stone Gossard",
      email: "Stone.Gossard@demo.com",
      role: "INED",
    },
    {
      name: "Edward Severson",
      email: "Edward.Severson@demo.com",
      role: "INED",
    },
    {
      name: "Kate Velvet",
      email: "Kate.Velvet@demo.com",
      role: "Investor",
    },
  ];

  const options: any = {
    elevation: 0,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    search: false,
    selectableRows: "none",
    setRowProps: () => {
      return {
        style: {
          cursor: "pointer",
        },
      };
    },
    draggableColumns: {
      enabled: true,
    },
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable title="" columns={columns} data={data} options={options} />
    </MuiThemeProvider>
  );
};

export default Users;
