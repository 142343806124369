import React from "react";
import { Menu } from "@material-ui/core";

export const StyledMenu = (props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
);

StyledMenu.propTypes = { ...Menu.propTypes };
