import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.fundName}
        </TableCell>
        <TableCell align="right">{row.fundCcy}</TableCell>
        <TableCell align="right">
          {row.aumInUsd.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </TableCell>
        <TableCell align="right">
          {row.aumInFundCcy.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </TableCell>
        <TableCell align="right">{row.manager}</TableCell>
        <TableCell align="right">{row.strategy}</TableCell>
        <TableCell align="right">{row.platform}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.investments.map((investment: any) => (
              <TableRow key={investment.investmentName}>
                <TableCell component="th" scope="row" width={80}></TableCell>
                <TableCell component="th" scope="row">
                  {investment.investmentName}
                </TableCell>
              </TableRow>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props: { investments: any[] }) {
  const { investments } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />

            <TableCell>Investment</TableCell>
            <TableCell align="right">Currency</TableCell>
            <TableCell align="right">AUM (USD)</TableCell>
            <TableCell align="right">AUM (Fund Currency)</TableCell>
            <TableCell align="right">Manager</TableCell>
            <TableCell align="right">Strategy</TableCell>
            <TableCell align="right">Platform</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {investments.map((investment: any) => (
            <Row key={investment.fundName} row={investment} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
