import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox as MuiCheckbox } from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export const CheckBox = (props: any) => {
  const classes = useStyles();

  const CustomCheckbox: any = (props: any) => {
    const { label, checked } = props;
    return (
      <Box
        py="10px"
        width="100%"
        style={{
          backgroundColor: checked ? "#2DAADC" : "lightgrey",
          color: checked ? "white" : "black",
          borderRadius: "3px",
        }}
      >
        <Typography>{label}</Typography>
      </Box>
    );
  };

  return (
    <MuiCheckbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <CustomCheckbox checked={props.checked} label={props.label} />
      }
      icon={<CustomCheckbox label={props.label} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
};
