import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const PreviewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      id="Component_62_20"
      xmlns="http://www.w3.org/2000/svg"
      width="27.35"
      height="17.054"
      viewBox="0 0 27.35 17.054"
    >
      <path
        id="Path_939"
        d="M57.393,45.817c-.245-.332-6.075-8.135-13.47-8.135s-13.226,7.8-13.47,8.135l-.145.2a.351.351,0,0,0,0,.392l.145.2c.245.332,6.075,8.135,13.47,8.135s13.226-7.8,13.47-8.135l.145-.2a.351.351,0,0,0,0-.392Zm-1.619.684c-1.578,1.867-6.347,6.911-11.852,6.911S33.649,48.368,32.071,46.5a.47.47,0,0,1,0-.583c1.578-1.867,6.349-6.909,11.852-6.909S54.2,44.05,55.774,45.918A.47.47,0,0,1,55.774,46.5Z"
        transform="translate(-30.248 -37.682)"
        fill="#707070"
      />
      <path
        id="Path_940"
        d="M47.75,42.686a5.063,5.063,0,1,0,5.064,5.063A5.069,5.069,0,0,0,47.75,42.686Zm0,8.8a3.738,3.738,0,1,1,3.739-3.738A3.743,3.743,0,0,1,47.75,51.488Z"
        transform="translate(-34.074 -39.222)"
        fill="#707070"
      />
      <path
        id="Path_941"
        d="M49.33,47.447a1.509,1.509,0,1,0,1.509,1.509A1.511,1.511,0,0,0,49.33,47.447Zm0,1.693a.184.184,0,1,1,.184-.184A.185.185,0,0,1,49.33,49.14Z"
        transform="translate(-35.654 -40.686)"
        fill="#707070"
      />
    </SvgIcon>
  );
};
