import React from "react";
import { Typography, Card, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * AMX Connect AppPanel Component
 * @component
 */

export const StorePanelSector = (props: any) => {
  const { type = "INVESTMENT OPERATIONS", logo } = props;

  const useStyles = makeStyles((theme) => ({
    card: {
      boxShadow: "0 2px 2px -2px #555",
      height: "100%",
      padding: "3em 1em 1em",
      display: "flex",
    },
    logoWrap: {
      marginBottom: "0.1em",
      "& > img": {
        maxHeight: "30px",
      },
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.card} variant="outlined">
        <Box alignSelf="flex-end" display="flex" flexDirection="column">
          <div className={classes.logoWrap}>
            <img alt="logo" src={logo} />
          </div>
          <Typography
            component="h4"
            variant="subtitle1"
            className={classes.type}
          >
            {type}
          </Typography>
        </Box>
      </Card>
    </React.Fragment>
  );
};
