import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { QuickStartVideo } from "./QuickStartVideo";
import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  gridWrap: {
    marginTop: "30px",
    marginBottom: "30px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  bodyText: {
    marginBottom: "2em",
    textAlign: "center",
  },

  root: {
    height: 216,
    flexGrow: 1,
    margin: "0 10px",
    overflow: "auto",
  },

  multiSelect: {
    width: "200px",
  },

  checbox: {
    padding: "0 10px 0 0",
    "&.Mui-checked": {
      color: "#7B059F",
    },
  },
}));

const Step4 = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridWrap}>
      <Grid item xs={12}>
        <Box justifyContent="center" display="flex">
          <QuickStartVideo url="/videos/what.mp4" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          justifyContent="center"
          display="flex"
          className={classes.bodyText}
        >
          <Typography component="p" variant="inherit">
            We built AMXConnect to bring together Investors, Managers, Advisors
            and Service Providers. We help them connect, see new opportunities
            and grow their business
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box justifyContent="center" display="flex" mb={2}>
          <Typography component="h4" variant="h6">
            Lets get you connected
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          multiSelect
        >
          <TreeItem nodeId="1" label="US Defined Benefit Plan">
            <TreeItem nodeId="15" label="Org" />
          </TreeItem>
          <TreeItem nodeId="2" label="Latam Plan">
            <TreeItem nodeId="15" label="Org" />
          </TreeItem>
          <TreeItem nodeId="3" label="Global Plan">
            <TreeItem nodeId="4" label="Japan"></TreeItem>
            <TreeItem nodeId="5" label="United States">
              <TreeItem nodeId="18" label="AMX Equity 1 (16M)" />
              <TreeItem nodeId="18" label="BlueRiver (31M)" />
              <TreeItem nodeId="18" label="AMX Emerging Markets (26M)" />
            </TreeItem>
            <TreeItem nodeId="6" label="United Kingdom">
              <TreeItem nodeId="18" label="AMX CREDIT (20M)" />
            </TreeItem>
            <TreeItem nodeId="7" label="France"></TreeItem>
            <TreeItem nodeId="8" label="Germany"></TreeItem>
            <TreeItem nodeId="9" label="Canadian"></TreeItem>
            <TreeItem nodeId="10" label="Hong Kong"></TreeItem>
            <TreeItem nodeId="11" label="Switzerland"></TreeItem>
            <TreeItem nodeId="12" label="Spain"></TreeItem>
          </TreeItem>
        </TreeView>
      </Grid>
    </Grid>
  );
};
export default Step4;
