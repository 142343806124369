import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ReportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(6 -80)">
        <circle
          cx="16.5"
          cy="16.5"
          r="16.5"
          transform="translate(-6 80)"
          fill="#5555e3"
        />
        <g transform="translate(-16.981 -5.908)">
          <path
            d="M42.3,51.305h3.616a.6.6,0,1,0,0-1.205H42.3a.609.609,0,0,0-.6.6A.593.593,0,0,0,42.3,51.305Z"
            transform="translate(-16.611 51.601)"
            fill="#fff"
          />
          <g>
            <path
              d="M33.882,42.281H38.52A2.874,2.874,0,0,0,41.4,39.4V33.582A2.874,2.874,0,0,0,38.52,30.7H33.882A2.874,2.874,0,0,0,31,33.582v5.79A2.9,2.9,0,0,0,33.882,42.281Zm-1.52-8.7a1.521,1.521,0,0,1,1.52-1.52H38.52a1.521,1.521,0,0,1,1.52,1.52v5.79a1.521,1.521,0,0,1-1.52,1.52H33.882a1.521,1.521,0,0,1-1.52-1.52Z"
              transform="translate(-8.719 65.918)"
              fill="#fff"
            />
            <path
              d="M42.3,42.505h2.437a.6.6,0,0,0,0-1.205H42.3a.609.609,0,0,0-.6.6A.593.593,0,0,0,42.3,42.505Z"
              transform="translate(-16.611 58.095)"
              fill="#fff"
            />
            <path
              d="M42.3,60.105h3.616a.6.6,0,0,0,0-1.205H42.3a.609.609,0,0,0-.6.6A.593.593,0,0,0,42.3,60.105Z"
              transform="translate(-16.611 45.106)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
