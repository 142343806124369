import React, { useEffect, useRef } from "react";

// Taken from here: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: any, delay: any) => {
  const savedCallback: any = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (delay !== null) {
      let id = setInterval(
        () => (savedCallback?.current ? savedCallback.current() : null),
        delay
      );
      return () => clearInterval(id);
    }
  }, [delay]);
};
