import React from "react";

import { Typography, Card, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * AMX Connect AppPanel Component
 * @component
 */

export const StorePanelFeature = (props: any) => {
  const {
    title,
    action = () => {
      return;
    },
  } = props;

  const useStyles = makeStyles((theme) => ({
    card: {
      boxShadow: "0 2px 2px -2px #555",
      padding: "1em 1em 1em",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      color: "#fff",

      "&:hover": {
        cursor: "pointer",
      },
      maxWidth: "400px",
    },

    wrap: {
      postion: "relative",
    },
    cardContent: {
      overflow: "auto",
      position: "relative",
    },

    backDrop: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      filter: "brightness(0.8)",
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
    },

    title: {
      textTransform: "uppercase",
      marginBottom: "1.5em",
    },
    subTitle: {
      marginBottom: "1em",
    },
    scroller: {
      position: "absolute",
      bottom: "10px",
      right: "20px",
      "& div": {
        width: "10px",
        height: "10px",
        backgroundColor: "#8b8e94",
        borderRadius: 15,
        display: "inline-block",
        marginLeft: "8px",
      },
      "& div:nth-child(2)": { backgroundColor: "#fff" },
    },
    buyButton: {
      backgroundColor: "#237db5",
      textTransform: "capitalize",

      borderRadius: 20,
      color: "#fff",
      "&:hover": {
        background: "#30305b",
        color: "#fff",
      },
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card
        className={classes.card}
        variant="outlined"
        onClick={() => action()}
      >
        <div
          className={classes.backDrop}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/alpima-mountains.webp`,
          }}
        />

        <Grid container spacing={3} className={classes.cardContent}>
          <Grid item xs={12} data-tour="things-you-might-like">
            <Typography
              component="h4"
              variant="subtitle2"
              className={classes.title}
            >
              THINGS YOU MIGHT LIKE
            </Typography>

            <Typography
              component="div"
              variant="subtitle2"
              className={classes.subTitle}
            >
              {title}
            </Typography>
            <Button className={classes.buyButton}>Buy</Button>
          </Grid>

          <div className={classes.scroller}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Grid>
      </Card>
    </React.Fragment>
  );
};
