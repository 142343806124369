import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core";
import { DocumentMetadataType } from "../../../../../common/types";
import { useDocuments } from "../Documents";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DescriptionIcon from "@material-ui/icons/Description";
import LoopIcon from "@material-ui/icons/Loop";
import MUIDataTable from "mui-datatables";
import CustomMuiToolbarSelect from "./CustomMuiToolbarSelect";

const useStyles = makeStyles((theme) => ({
  "@keyframes spin": {
    "0%": { transform: "rotate(360deg)" },
    "100%": { transform: "rotate(0deg)" },
  },
  spinner: {
    animation: "$spin 1s linear infinite",
  },
  icon: {
    color: "#C410A0",

    "&:hover": {
      color: "#C410A0",
    },
  },
  docTable: {
    "& [class*=liveAnnounce]": {
      display: "none",
    },
    "& [aria-live=polite]": {
      display: "none",
    },
  },
}));

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableRow: {
        root: {
          cursor: "pointer",
        },
      },
    },
  });

export const DocumentsTable = ({
  data,
  handleOnClick,
  isDownloading,
}: {
  data: DocumentMetadataType[];
  handleOnClick: any;
  isDownloading: string;
}) => {
  const { selectedDocType, userGroups } = useDocuments();
  const classes = useStyles();
  const [tableData, setTableData] = useState<DocumentMetadataType[]>(data);

  useEffect(() => {
    const documentNameType =
      selectedDocType === "All Documents" ||
      selectedDocType === "Recent Documents" ||
      selectedDocType === "Contract Notes" ||
      selectedDocType === "Investment Statement"
        ? "documentName"
        : "documentNameUi";

    const docs = data.map((doc) => ({
      ...doc,
      customDocumentName: doc.customDocumentName
        ? doc.customDocumentName
        : doc[documentNameType],
    }));

    setTableData(docs);
  }, [data, selectedDocType]);

  const metaData = [
    {
      name: "",
      options: {
        filter: false,
        draggable: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          const { documentName } = tableData[dataIndex];
          const isPdf =
            documentName.substring(
              documentName.length - 3,
              documentName.length
            ) === "pdf"
              ? true
              : false;

          return isDownloading === documentName ? (
            <LoopIcon className={classes.spinner} />
          ) : isPdf ? (
            <PictureAsPdfIcon className={classes.icon} />
          ) : (
            <DescriptionIcon className={classes.icon} />
          );
        },
      },
    },

    ...Object.keys(tableData[0]).map((column) => {
      // Converts camel case keys to seperated capitilized words.
      // This is Temporary to make column labels look prettier
      const labelSplitCamel = column.replace(/([A-Z])/g, " $1");

      const label = `${labelSplitCamel
        .charAt(0)
        .toUpperCase()}${labelSplitCamel.slice(1)}`;

      return {
        name: column,
        label: column === "customDocumentName" ? "Document Name" : label,
        options: {
          filter:
            column.includes("customDocumentName") ||
            (selectedDocType === "All Documents" &&
              column === "customDocumentName"),
          sort: true,
          display: column.includes("customDocumentName"),
        },
      };
    }),
  ];

  const options: any = {
    selectableRows: userGroups.includes("documents-admin")
      ? "multiple"
      : "none",
    elevation: 0,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    search: false,
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const { documentName, documentSk } = tableData[rowMeta.dataIndex];

      handleOnClick(documentName, documentSk);
    },
    customToolbarSelect: (
      selectedRows: any,
      displayData: any,
      setSelectedRows: any
    ) => (
      <CustomMuiToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        tableData={tableData}
      />
    ),
    draggableColumns: {
      enabled: true,
    },
    fixedHeader: true,
    fixedSelectColumn: true,
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        padding: "0 16px 0 16px",
        transition: "all 0.5s ease-in-out",
      }}
      className={classes.docTable}
    >
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={selectedDocType}
          data={tableData}
          columns={metaData}
          options={options}
        />
      </MuiThemeProvider>
    </Grid>
  );
};
