import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { AmxLogo, CollapseIcon } from "../Icons";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const NavSideBar = styled.aside`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background-color: white;
  height: 100%;
  margin: 0;
  overflow: ${(props) => (props.isexpanded ? "visible" : "hidden")};
  position: relative;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  z-index: 150;

  flex: ${(props) => (props.isexpanded ? "0 0 200px" : "0 0 50px")};
  transition: all 0.3s ease-in-out;

  .list-item-icon {
    min-width: 20px;
    margin-left: ${(props) => (props.isexpanded ? "20px" : "10px")};
    margin-right: 20px;
    transition: all 0.3s ease-in-out;
  }

  @media screen and (min-width: 840px) {
    padding: 50px 0 0;
  }
`;
NavSideBar.propTypes = {
  /**
   * Whether the sidebar is expanded or not.
   */
  isexpanded: PropTypes.number.isRequired,
};

const NavItem = styled(MenuItem)`
  overflow: visible;
  position: relative;
  padding: 0px;
  height: 55px;
  border-left: ${(props) =>
    props.selected ? "3px solid #c410a0" : "3px solid transparent"};

  background-color: ${(props) =>
    props.selected ? "#f7f9fc !important" : undefined};

  box-shadow: ${(props) =>
    props.selected ? "inset 5px 5px 30px 0px rgba(0, 0, 0, 0.05)" : undefined};

  &:hover {
    background-color: #f7f9fc;
    box-shadow: inset 5px 5px 30px 0px rgba(0, 0, 0, 0.05);

    .list-item-submenu {
      display: block;
    }
  }

  .MuiTypography-body1 {
    font-size: 18px;
    font-weight: ${(props) => (props.selected ? "400" : "300")};
  }
`;
NavItem.propTypes = {
  /** Whether the NavItem is currently selected or not. */
  selected: PropTypes.bool,
};

const SideBarLogo = styled(AmxLogo)`
  margin: 0;
  padding: 0;
  width: 100%;
`;

const NavItemContainer = styled.ul`
  margin: 0;
  padding: 0;
`;

const SubNavMenu = styled.ul`
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  display: none;
  left: 100%;
  padding-left: 0;
  position: absolute;
  top: -16px;

  > li {
    background: white;
    padding: 0 15px;
  }
`;

const LogoNavItem = styled(MenuItem)`
  bottom: 55px;
  position: absolute;
  transition: width 0.3s ease-in-out;
  width: 100%;
`;

const CollapseNavItem = styled(MenuItem)`
  position: absolute;
  bottom: 0;
  height: 55px;
  width: ${(props) => (props.isexpanded ? "200px" : "50px")};

  transform: ${(props) =>
    props.isexpanded ? "rotate(0deg)" : "rotate(180deg)"};

  padding-left: ${(props) => (props.isexpanded ? "160px" : undefined)};

  transition: width, padding-left 0.3s ease-in-out;

  :hover {
    background-color: #f7f9fc;
    box-shadow: inset 5px 5px 30px 0px rgba(0, 0, 0, 0.05);
  }
`;
CollapseNavItem.propTypes = {
  /** Whether the sidebar is expanded or not. */
  isexpanded: PropTypes.number.isRequired,
};

export const SideBar = (props) => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(true);

  const { navConfig, onChange } = props;

  const historyChangeOrInit = () => {
    if (window.innerWidth < 840) {
      setExpanded(false);
    }
  };

  const renderNav = (menu, isSubnav) => {
    return menu.map((item, index) => {
      let subNav;

      if (item.subNav) {
        subNav = (
          <SubNavMenu className="list-item-submenu">
            {renderNav(item.subNav, true)}
          </SubNavMenu>
        );
      }

      return (
        <NavItem
          selected={item.selected}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onChange) {
              onChange(index, item);
            }
            if (item.useHistory) {
              history.push(item.path);
            } else {
              item.usePort === null
                ? (window.location.href = item.path)
                : (window.location.href = `http://localhost:${item.usePort}${item.path}`);
            }
          }}
          key={item.path + item.name}
          data-tour={`sidebar-${item.id}`}
        >
          {isSubnav ? null : (
            <ListItemIcon className="list-item-icon">{item.icon}</ListItemIcon>
          )}
          <ListItemText primary={item.name} />
          {subNav}
        </NavItem>
      );
    });
  };

  if (history) {
    history.listen(historyChangeOrInit);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(historyChangeOrInit, [history]);
  }

  return (
    <NavSideBar isexpanded={expanded ? 1 : 0}>
      <NavItemContainer>
        {renderNav(navConfig)}

        <LogoNavItem>
          <SideBarLogo onClick={() => history.push("/")} />
        </LogoNavItem>

        <CollapseNavItem
          isexpanded={expanded ? 1 : 0}
          onClick={() => setExpanded(!expanded)}
        >
          <ListItemIcon>
            <CollapseIcon />
          </ListItemIcon>
        </CollapseNavItem>
      </NavItemContainer>
    </NavSideBar>
  );
};

SideBar.propTypes = {
  /** URL config pointing to the different apps */
  navConfig: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      name: PropTypes.string,
      path: PropTypes.string,
      selected: PropTypes.boolean,
      useHistory: PropTypes.boolean,
    })
  ),
  onChange: PropTypes.func,
};
