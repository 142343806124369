import { LinearProgress } from "@material-ui/core";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { AuthContextType, AuthState, PropsInterface } from "../common/types";
import { useMsal } from "../hooks/useMsal";

const initialState = {
  authState: {
    status: "loading",
    error: null,
    user: {},
    accessToken: "",
    experimentalFeatures: [],
    permissions: JSON.parse(`${sessionStorage.getItem("permissions")}`) || [],
    appInsightsKey: "",
  },
  axiosWithAuth: undefined,
};

export const AuthContext = createContext<AuthContextType>(initialState);

export const AuthContextProvider = (props: PropsInterface) => {
  const { config } = props;
  const [authState, setAuthState] = useReducer(
    (s: AuthState, a: any) => ({ ...s, ...a }),
    {
      ...initialState.authState,
      experimentalFeatures: config.EXPERIMENTAL_FEATURES
        ? config.EXPERIMENTAL_FEATURES.split("|")
        : [],
    }
  );

  const axiosWithAuth: AxiosInstance = axios.create({
    baseURL: config.AMX_PHOENIX_API_URL,
  });

  axiosWithAuth.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const token = await getAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosWithAuth.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { login, logout, getAccessToken, getUserAccount } = useMsal(config);

  // TODO:  check if status is failing and causing unauthorised state which is then causing login()
  const getPermissions = useCallback(async () => {
    try {
      const token = await getAccessToken();

      const { data } = await axios({
        baseURL: config.AMX_PHOENIX_API_URL,
        url: "/status",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const permissions = Object.keys(data.permissions).filter(
        (permission) => data.permissions[permission] === true
      );

      sessionStorage.setItem("permissions", JSON.stringify(permissions));

      setAuthState({
        accessToken: token,
        status: "authorized",
        permissions,
        appInsightsKey: data.appInsightsKey,
      });
    } catch {
      setAuthState({
        accessToken: "",
        status: "unauthorized",
        permissions: [],
        appInsightsKey: "",
      });
    }
  }, [getAccessToken, config.AMX_PHOENIX_API_URL]);

  const getUserDetails = useCallback(() => {
    try {
      const userData = getUserAccount();

      setAuthState({ user: { ...userData } });
    } catch (err) {
      console.error(err);
      setAuthState({ user: {} });
    }
  }, [getUserAccount]);

  useEffect(() => {
    getPermissions();
    getUserDetails();
  }, [getPermissions, getUserDetails]);

  const value = useMemo(
    () => ({
      logout,
      login,
      authState,
      getAccessToken,
      getUserAccount,
      axiosWithAuth,
    }),
    [logout, login, authState, getAccessToken, getUserAccount, axiosWithAuth]
  );

  if (authState.status === "loading") {
    return <LinearProgress />;
  }

  if (authState.status === "authorized") {
    return <AuthContext.Provider value={value} {...props} />;
  }

  if (authState.status === "unauthorized") {
    login();
  }

  return <LinearProgress />;
};

export const useAuth = () => useContext<AuthContextType>(AuthContext);
