import React, { Fragment } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Grid,
  Typography,
  Paper,
  Box,
} from "@material-ui/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useAuth } from "../../../../../providers";
import useHover from "../../../../../helpers/useHover";
import { downloadFile, euroFormat } from "../../../../../helpers";
import { PdfIconActive, PdfIconInactive } from "../../../../../components";

const LeftColumn = (props: any) => {
  const { fundInfo, showChart } = props;

  return (
    <Grid item xs={6}>
      <Box className="tab-detail-box">
        <Typography variant="h6" gutterBottom>
          Fund Objectives
        </Typography>
        <Typography variant="body1" gutterBottom>
          {fundInfo.objectives}
        </Typography>
      </Box>

      <Box className="tab-detail-box">
        <Typography variant="h6" gutterBottom>
          Investment Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          {fundInfo.investmentPolicy}
        </Typography>
      </Box>

      <Box className="tab-detail-box">
        <Typography variant="h6" gutterBottom>
          Investment Strategy
        </Typography>
        <Typography variant="body1" gutterBottom>
          {fundInfo.investmentStrategy}
        </Typography>
      </Box>

      <Box className="tab-detail-box">
        <Typography variant="h6" gutterBottom>
          Key Stats as of {fundInfo.keyStats.date}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="branded-alt-bg">Fund Name</TableCell>
              <TableCell className="branded-alt-bg">Share Class</TableCell>
              <TableCell className="branded-alt-bg">{`${fundInfo.keyStats.frequency} Return %`}</TableCell>
              <TableCell className="branded-alt-bg" style={{ minWidth: 60 }}>
                MTD %
              </TableCell>
              <TableCell className="branded-alt-bg" style={{ minWidth: 60 }}>
                QTD %
              </TableCell>
              <TableCell className="branded-alt-bg" style={{ minWidth: 60 }}>
                YTD %
              </TableCell>
              <TableCell className="branded-alt-bg" style={{ minWidth: 60 }}>
                ITD %
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fundInfo.keyStats.data.map((data: any) => (
              <TableRow key={data.fundName}>
                <TableCell>{data.fundName}</TableCell>
                <TableCell>{data.shareClass}</TableCell>
                <TableCell>{data.navPerfPercent.toFixed(2)}</TableCell>
                <TableCell>{data.classMtdPl.toFixed(2)}</TableCell>
                <TableCell>{data.classQtdPl.toFixed(2)}</TableCell>
                <TableCell>{data.classYtdPl.toFixed(2)}</TableCell>
                <TableCell>{data.classItdPl.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Box className="tab-detail-box">
        {showChart && (
          <Fragment>
            <Typography variant="h6" gutterBottom>
              Price History
            </Typography>
            <HighchartsReact
              highcharts={Highcharts}
              options={fundInfo.growthChartData}
            />
          </Fragment>
        )}
      </Box>
    </Grid>
  );
};

const RightColumn = (props: any) => {
  const { axiosWithAuth } = useAuth();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const { fundInfo } = props;

  return (
    <Grid item xs={6}>
      <Box className="tab-detail-box">
        <Typography variant="h6" gutterBottom>
          Key Fund Information
        </Typography>
        <Grid container>
          {fundInfo.keyInfo.map((box: any) => (
            <Grid item xs={6} key={box.title}>
              <Paper className="key-info-layout">
                <Typography variant="subtitle2" gutterBottom>
                  {box.title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {box.value}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box className="tab-detail-box">
        <Typography variant="h6" gutterBottom>
          Key Share Class Information
        </Typography>
        <Grid container>
          {fundInfo.keyShareClassInfo.map((box: any) => (
            <Grid item xs={6} key={box.title}>
              <Paper className="key-info-layout">
                <Typography variant="subtitle2" gutterBottom>
                  {box.title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {box.value}
                </Typography>
              </Paper>
            </Grid>
          ))}
          <Grid item xs={6}>
            <Paper
              ref={hoverRef}
              className="key-info-layout paper-button"
              style={{ cursor: "pointer" }}
              onClick={async () => {
                if (axiosWithAuth === undefined) return;

                const fileName = `FundsJurisdictionsandAvailabilityMatrix.pdf`;

                const data = await axiosWithAuth({
                  url: `/store/common/documents/`,
                  method: "GET",
                  responseType: "blob",
                  params: {
                    fileName,
                  },
                });

                downloadFile(data, fileName);
              }}
            >
              <Typography variant="subtitle2" gutterBottom>
                Jurisdictions/Funds Matrix
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  style={{ color: isHovered ? "#C410A0" : "inherit" }}
                  variant="body2"
                  gutterBottom
                >
                  Link
                </Typography>
                {isHovered ? <PdfIconActive /> : <PdfIconInactive />}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

const FundInfoTabContent = (props: any) => {
  const { fundInfo, historyCallback, showChart } = props;

  return (
    <Fragment>
      <Table className="detail-info-table">
        <TableHead>
          <TableRow>
            <TableCell className="branded-alt-bg"></TableCell>
            <TableCell className="branded-alt-bg">Strategy</TableCell>
            <TableCell className="branded-alt-bg">Share Class</TableCell>
            <TableCell className="branded-alt-bg">Currency</TableCell>
            <TableCell className="branded-alt-bg">Prev Price</TableCell>
            <TableCell className="branded-alt-bg">Unit Price</TableCell>
            <TableCell className="branded-alt-bg">As At</TableCell>
            <TableCell className="branded-alt-bg">Price History</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fundInfo.fundList.map((fund: any) => (
            <TableRow key={`${fund.fundName}-${fund.strategy}`}>
              <TableCell style={{ fontWeight: 500 }}>{fund.fundName}</TableCell>
              <TableCell>{fund.strategy}</TableCell>
              <TableCell>{fund.shareClass}</TableCell>
              <TableCell>{fund.currency}</TableCell>
              <TableCell>{fund.prevPrice}</TableCell>
              <TableCell>{fund.unitPrice}</TableCell>
              <TableCell>{euroFormat(new Date(fund.asAt))}</TableCell>
              <TableCell>
                <div
                  role="button"
                  tabIndex={0}
                  style={{ cursor: "pointer" }}
                  onClick={historyCallback}
                >
                  Download
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Grid container>
        <LeftColumn fundInfo={fundInfo} showChart={showChart} />
        <RightColumn fundInfo={fundInfo} />
      </Grid>
    </Fragment>
  );
};

export default FundInfoTabContent;
