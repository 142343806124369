import MUIDataTable from "mui-datatables";
import React, { useMemo } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import { PricingWorkflowTask } from "../../../../common/types";
import { euroFormat } from "../../../../helpers";
import { useAuth } from "../../../../providers";
import { useMyTasks } from "../MyTasks";

const useStyles = makeStyles(() => ({
  table: {
    "& [class*=liveAnnounce]": {
      display: "none",
    },
    "& [aria-live=polite]": {
      display: "none",
    },
    padding: "30px",
  },
}));

const TaskTable = ({
  filteredTasks,
}: {
  filteredTasks: PricingWorkflowTask[];
}) => {
  const classes = useStyles();
  const { axiosWithAuth } = useAuth();
  const { setTaskId } = useMyTasks();

  const columns = useMemo(
    () => [
      {
        name: "_id",
        label: "ID",
        options: {
          filter: true,
          display: "excluded",
          sort: true,
        },
      },
      {
        name: "taskName",
        label: "Task Name",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "parentRef",
        label: "Parent Workflow Item",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "assignedDate",
        label: "Assigned Date",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return euroFormat(value);
          },
        },
      },
      {
        name: "assignee",
        label: "Assignee",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return value?.name;
          },
        },
      },
      {
        name: "assignedTeam",
        label: "Assigned Team",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return value?.name;
          },
        },
      },

      {
        name: "parentDisplayName",
        label: "Parent Fund Name",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "age",
        label: "Age",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: false,
        },
      },
    ],
    []
  );

  const options: any = useMemo(
    () => ({
      selectToolbarPlacement: "none",
      elevation: 0,
      pagination: false,
      selectableRows: "none",
      rowsPerPage: 5,
      setRowProps: () => {
        return {
          style: { cursor: "pointer" },
        };
      },
      onRowClick: (
        rowData: any,
        rowMeta: { dataIndex: number; rowIndex: number }
      ) => {
        if (axiosWithAuth === undefined) return;
        const { parentId, _id } = filteredTasks[rowMeta.dataIndex];
        setTaskId({ _id, parentId });
      },
    }),
    [axiosWithAuth, filteredTasks, setTaskId]
  );

  return (
    <Paper className={classes.table}>
      <MUIDataTable
        title=""
        data={filteredTasks}
        columns={columns}
        options={options}
      />
    </Paper>
  );
};

export default TaskTable;
