import { Box, Chip, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DashboardWidget, SearchResponse } from "../../../common/types";
import ChipSelector, {
  SelectItem,
} from "../../../components/ChipSelector/ChipSelector";
import { useAuth, useSharedDashboardData } from "../../../providers";
import { WidgetWrapper } from "./WidgetWrapper";

type FundInspectorSearchFilterProps = DashboardWidget & {};

export const selectedFilters = (filters: any[]) => {
  const [country, sector, currency] = filters;

  const reduceToSelected = (selectorSelectList: string[], selector: any) =>
    selector.selected
      ? selectorSelectList.concat(selector.value)
      : selectorSelectList;

  return {
    country: country.reduce(reduceToSelected, []),
    sector: sector.reduce(reduceToSelected, []),
    currency: currency.reduce(reduceToSelected, []),
  };
};

export const FundInspectorSearchFilter = (
  props: FundInspectorSearchFilterProps
) => {
  const { setInspectionData } = useSharedDashboardData();
  const location = useLocation();
  const [demoMode, setDemoMode] = useState<boolean>(false);

  const [searchOptions, setSearchOptions] = useState<SearchResponse[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [countrySelectors, setCountrySelectors] = useState<SelectItem[]>([]);
  const [sectorSelectors, setSectorSelectors] = useState<SelectItem[]>([]);
  const [currencySelectors, setCurrencySelectors] = useState<SelectItem[]>([]);

  const { axiosWithAuth } = useAuth();

  useEffect(() => {
    setDemoMode(location.search.indexOf("exampleData") !== -1);
  }, [location]);

  useEffect(() => {
    if (axiosWithAuth === undefined) return;
    const executeSearch = async () => {
      const searchOptionResult = await axiosWithAuth({
        url: "/dashboard/inspector/search-options/",
        params: { searchTerm },
      });

      setSearchOptions((searchOptions) =>
        searchOptions.concat(
          searchOptionResult.filter(
            (result: SearchResponse) =>
              searchOptions?.findIndex(
                (option: SearchResponse) =>
                  result.match === option.match && result.type === option.type
              ) === -1
          )
        )
      );
    };
    executeSearch();
  }, [setSearchOptions, axiosWithAuth, searchTerm]);

  useEffect(() => {
    if (axiosWithAuth === undefined) return;

    const executeFilteredSearch = async () => {
      const searchOptionResult = await axiosWithAuth({
        url: "/dashboard/inspector/detail/",
        params: {
          options: searchOptions,
          filters: selectedFilters([
            countrySelectors,
            sectorSelectors,
            currencySelectors,
          ]),
        },
      });

      setInspectionData(searchOptionResult);
    };
    executeFilteredSearch();
  }, [
    axiosWithAuth,
    countrySelectors,
    sectorSelectors,
    currencySelectors,
    setInspectionData,
    searchOptions,
  ]);

  const getInspectionData = async (options: SearchResponse[]) => {
    if (axiosWithAuth === undefined) return;

    const queryResult = await axiosWithAuth({
      url: "/dashboard/inspector/detail/",
      params: { options },
    });

    setCountrySelectors(queryResult.filters.country);
    setSectorSelectors(queryResult.filters.sector);
    setCurrencySelectors(queryResult.filters.currency);

    setInspectionData(queryResult);
  };

  return (
    <WidgetWrapper data={props} title="Fund Inspector Search">
      <Grid container>
        <Grid item xs={12} data-tour="dashboard-inspector-search">
          <Box m={1}>
            <Autocomplete
              multiple
              fullWidth={true}
              options={searchOptions!}
              getOptionLabel={(option: any) =>
                `${option.type} -> ${option.match}`
              }
              onChange={(event: any, value: any, reason: any) => {
                setSearchOptions(value);
                if (reason === "clear") {
                  setSearchTerm("");
                  setCountrySelectors([]);
                  setSectorSelectors([]);
                  setCurrencySelectors([]);
                }

                getInspectionData(value);
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip label={option.match} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(event: any) => {
                    const { value } = event.target;
                    setSearchTerm(value);
                  }}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid container data-tour="dashboard-inspector-filter">
          {demoMode ||
          (countrySelectors.length > 0 &&
            sectorSelectors.length > 0 &&
            currencySelectors.length > 0) ? (
            <>
              <Grid item xs={4}>
                <Box m={1}>
                  <ChipSelector
                    label="Country"
                    items={demoMode ? [] : countrySelectors}
                    setSelectedCheckboxes={(items: SelectItem[]) =>
                      setCountrySelectors(() => items)
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box m={1}>
                  <ChipSelector
                    label="Sector"
                    items={demoMode ? [] : sectorSelectors}
                    setSelectedCheckboxes={(items: SelectItem[]) =>
                      setSectorSelectors(() => items)
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box m={1}>
                  <ChipSelector
                    label="Currency"
                    items={demoMode ? [] : currencySelectors}
                    setSelectedCheckboxes={(items: SelectItem[]) =>
                      setCurrencySelectors(() => items)
                    }
                  />
                </Box>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    </WidgetWrapper>
  );
};
