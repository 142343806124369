import React from "react";

import { Typography, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * AMX Connect AddApp Component
 * @component
 */

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0 2px 2px -2px #555",
    marginBottom: "5px",
    padding: "2em 1em",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#e3e3e3",
    justifyContent: "center",
    alignItems: "center",
  },

  cardContent: {
    padding: "0em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  plusButton: {
    backgroundColor: "#b4b4b4",
    color: "#fff",
    width: "1.2em",
    height: "1.2em",
    lineHeight: "1em",
    marginBottom: "0.2em",
    border: "2px solid #f5f5f5",
    borderRadius: "50%",
    fontSize: "3em",
  },
}));

export const AddAppPanel = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.card} variant="outlined">
        <div className={classes.cardContent}>
          <div className={classes.plusButton}>+</div>
          <Typography variant="subtitle1">
            <strong>Add more Apps</strong>
          </Typography>
        </div>
      </Card>
    </React.Fragment>
  );
};
