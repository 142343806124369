import {
  AdminUserContextType,
  AuthState,
  AxiosWithAuth,
  PropsInterface,
  UserType,
} from "../common/types";
import React, { createContext, useContext, useState } from "react";
import { useAuth } from "./AuthContextProvider";

const getUsers = async (
  authState: AuthState,
  axiosWithAuth?: AxiosWithAuth
) => {
  if (!authState || authState.status !== "authorized") {
    console.error("Unauthenticated.");
    return;
  }
  if (!axiosWithAuth) {
    console.error("axiosWithAuth isn't defined");
    return;
  }

  const response = await axiosWithAuth({
    url: "/amx-users",
  });

  if (response !== undefined) {
    return response;
  } else {
    console.error("getUsers API response was undefined");
    return [];
  }
};

const sortByDisplayName = (a: UserType, b: UserType) => {
  const nameA = a.displayName.toLowerCase();
  const nameB = b.displayName.toLowerCase();

  if (nameA > nameB) {
    return 1;
  } else if (nameA < nameB) {
    return -1;
  }
  return 0;
};

export const AdminUserContext = createContext<AdminUserContextType>({
  users: [],
  loadingUsers: false,
});

export const AdminUserContextProvider = (props: PropsInterface) => {
  const { authState, axiosWithAuth } = useAuth();
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<UserType>>([]);

  if (!loadingUsers && (!users || users.length === 0)) {
    setLoadingUsers(true);
    getUsers(authState, axiosWithAuth).then((data: Array<UserType>) => {
      if (data) {
        setUsers(data.sort(sortByDisplayName));
      }
      setLoadingUsers(false);
    });
  }

  return (
    <AdminUserContext.Provider
      value={{
        users,
        loadingUsers,
      }}
      {...props}
    />
  );
};

export const useAdmin = () =>
  useContext<AdminUserContextType>(AdminUserContext);
